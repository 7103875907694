import FilterButton from "components/Library/FilterButton";
import { useConfig } from "contexts/ConfigContext";
import React, { useState } from "react";
import { ReactComponent as BookmarkIconOutline } from "assets/images/bookmark-outline.svg";
import { ReactComponent as BookmarkIconFilled } from "assets/images/bookmark-filled.svg";

export interface FilterTag {
  id: number;
  name: string;
}

interface FilterTagsProps {
  filters: FilterTag[];
  onSelectFilter: (name: string) => void;
  selectedFilters: string[];
}

const FilterTags: React.FC<FilterTagsProps> = ({
  filters,
  onSelectFilter,
  selectedFilters,
}) => {
  const { config } = useConfig();

  return (
    <div className="flex overflow-x-auto space-x-2 mt-4 pb-4">
      {filters.map((filter) => (
        <FilterButton
          key={filter.name}
          label={
            filter.name === "FAVOURITE" ? (
              selectedFilters?.includes(filter?.name) ? (
                <BookmarkIconFilled />
              ) : (
                <BookmarkIconOutline />
              )
            ) : (
              filter.name
            )
          }
          count={filter.name === "FAVOURITE" ? null : filter.id}
          isSelected={selectedFilters?.includes(filter?.name)}
          onClick={() => {
            onSelectFilter(filter?.name);
          }}
          brandColor={
            filter.name === "FAVOURITE"
              ? "#FF9028"
              : config?.colors?.brand_color
          }
        ></FilterButton>
      ))}
    </div>
  );
};

export default FilterTags;
