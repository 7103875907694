import React from "react";

export default function DamageImageChatPreview({
  imageUrls,
}: {
  imageUrls: string[];
}) {
  const columnClass = imageUrls?.length === 1 ? "grid-cols-1" : "grid-cols-2";
  return (
    <div className={`grid ${columnClass} gap-x-[8px] gap-y-4 flex-1`}>
      {imageUrls?.map((imageUrl, index) => (
        <div key={index} className="image-chat-preview overflow-hidden">
          <img
            src={imageUrl}
            alt={`Preview ${index}`}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
    </div>
  );
}
