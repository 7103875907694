import {
  differenceInMinutes,
  format,
  isThisYear,
  isToday,
  parseISO,
} from "date-fns";
export function generateRandomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function hexToRgbA(hex: any, alpha = 1) {
  var c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${alpha || 1})`
    );
  }
  throw new Error("Bad Hex");
}

export function getFileExtension(fileName: string) {
  // Check if there is at least one dot in the filename
  if (fileName.lastIndexOf(".") > 0) {
    // Get the substring after the last dot
    return fileName.substring(fileName.lastIndexOf(".") + 1);
  } else {
    // No dot found, or it's at the start (hidden files without extension)
    return "";
  }
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function truncateWords(words: string, maxLength: number = 10): string {
  return words.length > maxLength
    ? `${words.slice(0, maxLength - 3)}...`
    : words;
}

export const formatDateTooltipTime = (dateTimeString: string): string => {
  const parsedDate = parseISO(dateTimeString);

  if (isToday(parsedDate)) {
    return format(parsedDate, "hh:mm a");
  } else {
    return format(parsedDate, "dd MMM yy, hh:mm a");
  }
};

export function formatMessageTimestamp(dateTime: string): string {
  const parsedDate = new Date(dateTime);
  const now = new Date();
  const diffInMinutes = differenceInMinutes(now, parsedDate);

  if (diffInMinutes < 2) {
    return "Just now";
  } else if (diffInMinutes < 1440) {
    // Less than 24 hours
    return format(parsedDate, "hh:mm a");
  } else if (isToday(parsedDate)) {
    return format(parsedDate, "hh:mm a");
  } else if (isThisYear(parsedDate)) {
    return format(parsedDate, "dd MMM, hh:mm a");
  } else {
    return format(parsedDate, "dd MMM'yy, hh:mm a");
  }
}

export function getBestFontColor(
  backgroundColor: string,
  contrastThreshold: number = 0.5
): string {
  const whiteShade = "#FFFFFF"; // Light white shade
  const blackShade = "#0E0E0F"; // Dark black shade

  // Function to convert hex to RGB
  function hexToRgb(hex: string): { r: number; g: number; b: number } {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: 0, g: 0, b: 0 };
  }

  // Function to calculate luminance
  function luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  const { r, g, b } = hexToRgb(backgroundColor);
  const bgLuminance = luminance(r, g, b);

  // Use the contrastThreshold to adjust when to use white or black shade
  return bgLuminance > contrastThreshold ? blackShade : whiteShade;
}

// New helper function to check if the background color has low contrast
export function isLowContrastColor(
  backgroundColor: string,
  contrastThreshold: number = 0.5
): boolean {
  // Convert hex color to RGB
  function hexToRgb(hex: string): { r: number; g: number; b: number } {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: 0, g: 0, b: 0 };
  }

  // Function to calculate luminance
  function luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  const { r, g, b } = hexToRgb(backgroundColor);
  const bgLuminance = luminance(r, g, b);

  // Return true if the luminance is below the threshold (indicating low contrast)
  return bgLuminance > contrastThreshold;
}

export const getFontColorForBrand = (brandColor: string) => {
  const isLowContrast = isLowContrastColor(brandColor || "#ffffff");

  const fontColor = isLowContrast ? "#0E0E0F" : brandColor;
  return fontColor;
};
