import Chip from "./Chip";

export default function ActionChips({
  chipActions,
  handleClickChip,
  isDisabled,
  removePadding,
}: {
  chipActions: any;
  handleClickChip: (data: any) => void;
  isDisabled?: boolean;
  removePadding?: boolean;
}) {
  return (
    <div className={`flex flex-col ${removePadding ? "py-0" : "py-4"} gap-2`}>
      {chipActions?.map((chipAction: any, index: number) => (
        <Chip
          key={index}
          chipAction={chipAction}
          handleClickChip={handleClickChip}
          isDisabled={isDisabled}
        />
      ))}
    </div>
  );
}
