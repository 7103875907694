import { isLowContrastColor } from "utils/Misc";
import { useConfig } from "contexts/ConfigContext";
import React from "react";

export interface InputButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode | string;
}

const InputButton = ({ children, disabled, ...props }: InputButtonProps) => {
  const { config } = useConfig();

  // Default background color, change based on your requirement
  const bgColor = config?.colors?.brand_color || "#FFFFFF";

  // Determine if the background color is low contrast
  const isLowContrast = isLowContrastColor(bgColor);

  // If low contrast, use dark color for text and border, otherwise use the brand color
  const fontColor = isLowContrast ? "#0E0E0F" : config?.colors?.brand_color;
  // const borderColor = isLowContrast ? "#0E0E0F" : config?.colors?.brand_color;

  return (
    <button
      className="border rounded-lg text-ssm font-bold py-2 px-4 relative group cursor-pointer disabled:text-inkAlpha-400 disabled:border-inkAlpha-200 disabled:cursor-not-allowed w-[17.5rem]"
      style={{
        color: !disabled ? fontColor : "",
        borderColor: !disabled ? config?.colors?.brand_color : "",
      }}
      disabled={disabled}
      {...props}
    >
      <div
        className="absolute left-0 right-0 top-0 bottom-0 group-hover:block group-active:hidden group-disabled:hidden hidden"
        style={{ backgroundColor: config?.colors?.brand_color, opacity: 0.08 }}
      ></div>
      <div
        className="absolute left-0 right-0 top-0 bottom-0 group-hover:hidden group-active:block group-disabled:hidden hidden"
        style={{ backgroundColor: config?.colors?.brand_color, opacity: 0.16 }}
      ></div>
      {children}
    </button>
  );
};

export default InputButton;
