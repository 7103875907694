import axios from "axios";

interface ExtraConfig {
  viaOauth: boolean;
  token?: string | undefined;
  headers?: { [key: string]: string | number };
}

interface RequestParams {
  [key: string]: any;
}

interface RequestConfig {
  params: RequestParams;
  headers?: {
    Authorization?: string;
  };
}

interface RequestMethodFn {
  (url: string, data: RequestParams, config: ExtraConfig): Promise<any>;
}

type RequestMethods = "post" | "put" | "patch" | "get" | "delete";

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Accept-Language": "en",
  },
});

const getConfig = (params: RequestParams, extraConfig: ExtraConfig) => {
  let config: RequestConfig = {
    params: params,
  };

  const token = null; //getToken();

  if (extraConfig && extraConfig.viaOauth) {
    config = {
      ...config,
      headers: { Authorization: `Bearer ${extraConfig?.token || token}` },
    };
  }

  if (extraConfig && extraConfig.headers) {
    config = {
      ...config,
      headers: { ...config?.headers, ...extraConfig.headers },
    };
  }

  //   if (extraConfig && extraConfig.withCredentials) {
  //     config = {
  //       ...config,
  //       //  withCredentials: true,
  //     };
  //   }
  config = {
    ...config,
    headers: {
      ...config?.headers,
    },
  };
  return config;
};

const axiosRequest = (
  url: string,
  method: RequestMethods,
  dataObj: any,
  extraConfig: ExtraConfig
) => {
  const methods = method.toLowerCase();
  if (methods === "get" || methods === "delete" || methods === "head") {
    let params = {};
    params = dataObj ? dataObj : params;
    const config = getConfig(params, extraConfig);

    return axiosInstance[methods](url, config);
  } else if (methods === "post" || methods === "put" || methods === "patch") {
    let params = {};
    params = dataObj ? dataObj : params;

    let config = getConfig(params, extraConfig);
    console.log("params post", params, config);
    return axiosInstance[methods](
      url,
      { ...params },
      { ...config, params: undefined }
    );
  } else {
    return Promise.reject("Invalid method");
  }
};

export const post: RequestMethodFn = async (url, params, extraConfig) => {
  const response =
    url && (await axiosRequest(url, "post", params, extraConfig));
  return response;
};

export const patch: RequestMethodFn = async (url, params, extraConfig) => {
  const response =
    url && (await axiosRequest(url, "patch", params, extraConfig));
  return response;
};

export const get: RequestMethodFn = async (url, params, extraConfig) => {
  const response = url && (await axiosRequest(url, "get", params, extraConfig));
  return response;
};

export const del: RequestMethodFn = async (url, params, extraConfig) => {
  const response =
    url && (await axiosRequest(url, "delete", params, extraConfig));
  return response;
};

export const put: RequestMethodFn = async (url, params, extraConfig) => {
  const response = url && (await axiosRequest(url, "put", params, extraConfig));
  return response;
};
