import React from "react";
import { format, isToday, isYesterday, parseISO } from "date-fns";

type Props = {
  text: string;
};

const StatusChangeSeparation = ({ text }: Props) => {
  return (
    <div className="flex flex-row gap-4 items-center py-6 justify-center flex-wrap">
      {/* <hr className="grow" />{" "} */}
      <div className="text-xs text-gray-800 text-center md:w-[60%]">
        {text}
      </div>{" "}
      {/* <hr className="grow" /> */}
    </div>
  );
};

export default StatusChangeSeparation;
