import React from "react";

interface SelectBoxProps {
  label: string | React.ReactNode;
  name: string;
  selectedValue?: string | number;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options?: { [key: string]: any }[];
  placeholder?: string;
  keyMapping?: { idKey: string; nameKey: string };
  disabled?: boolean;
  error?: string;
  disablePlaceholder?: boolean;
}

const SelectBox: React.FC<SelectBoxProps> = ({
  label,
  name,
  selectedValue,
  onChange,
  options,
  placeholder = "Select an option...",
  keyMapping = { idKey: "id", nameKey: "name" }, // Default key mapping
  disabled,
  error,
  disablePlaceholder,
}) => {
  const { idKey, nameKey } = keyMapping;

  return (
    <div className={`${disabled ? "opacity-50" : "opacity-100"}`}>
      <label className="block mb-2 text-gray-900 text-sm">{label}</label>
      <select
        className={`text-sm w-full px-3 py-2 border rounded-lg outline-none ${
          !!error ? "border-red-500" : "border-gray-400"
        } ${
          selectedValue === undefined || selectedValue === ""
            ? "text-inkAlpha-400"
            : ""
        }`}
        name={name}
        value={selectedValue ?? ""}
        onChange={onChange}
        disabled={disabled}
      >
        <option
          value=""
          className="!text-inkAlpha-400"
          disabled={disablePlaceholder}
        >
          {placeholder}
        </option>
        {!!options &&
          options.map((option) => (
            <option
              key={option[idKey]}
              value={option[idKey]}
              className="text-ellipsis"
            >
              {option[nameKey]}
            </option>
          ))}
      </select>
      {!!error && (
        <div className="text-robylon-error text-xs px-1 py-1">{error}</div>
      )}
    </div>
  );
};

export default SelectBox;
