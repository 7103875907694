import { useConfig } from "contexts/ConfigContext";
import GetActionIcons from "./GetActionIcons";

export default function Chip({
  chipAction,
  handleClickChip,
  isDisabled,
}: {
  chipAction: any;
  handleClickChip: (data: any) => void;
  isDisabled?: boolean;
}) {
  const { config } = useConfig();
  return (
    <button
      className={`flex gap-[10px] items-center text-sm font-semibold border p-3 rounded-[8px] ${
        isDisabled ? "opacity-50 !border-gray-800 !text-gray-800" : ""
      }`}
      style={{
        color: config?.colors?.brand_color,
        borderColor: config?.colors?.brand_color,
      }}
      onClick={
        isDisabled
          ? undefined
          : () => {
              handleClickChip(chipAction);
            }
      }
    >
      {chipAction?.icon && <GetActionIcons icon={chipAction?.icon} />}
      <span>{chipAction?.action_display}</span>
    </button>
  );
}
