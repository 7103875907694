import React from "react";
import { format, isToday, isYesterday, parseISO } from "date-fns";

type Props = {
  date: string;
};

const TimelineSeparation = ({ date }: Props) => {
  const parsedDate = parseISO(date);

  const formattedDate = isToday(parsedDate)
    ? "Today"
    : isYesterday(parsedDate)
    ? `Yesterday, ${format(parsedDate, "d MMM")}`
    : format(parsedDate, "d MMM, yy");

  return (
    <div className="flex flex-row gap-4 items-center justify-center">
      {/* <hr className="grow" />{" "} */}
      <div className="text-xs text-gray-800">{formattedDate}</div>{" "}
      {/* <hr className="grow" /> */}
    </div>
  );
};

export default TimelineSeparation;
