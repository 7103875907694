import React from "react";
import { ReactComponent as SearchIcon } from "assets/images/search-icon.svg";

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchQuery,
  setSearchQuery,
}) => {
  return (
    <div className="flex items-center border border-gray-400 rounded-lg w-full hover:border-inkAlpha-300 outline-gray-100 outline-0">
      <div className="pr-3 pl-2 py-[11px]">
        <div className="w-4 h-4">
          <SearchIcon />
        </div>
      </div>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search..."
        className="py-2.5 text-gray-900 placeholder:text-inkAlpha-400 text-sm outline-0 grow rounded-lg"
      />
    </div>
  );
};

export default SearchBar;
