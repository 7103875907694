import React, { useState } from "react";
import CheckGreen from "assets/images/check-green.svg";
import Warning from "assets/images/warning-icon.svg";
import CaretRight from "assets/images/caret-right.svg";
import { InputPayload } from "components/ChatbotUIClient";
import ActionChips from "components/ActionChips";
import InputButton from "components/InputButton/InputButton";

interface DynamicInputButtonsProps {
  config?: any;
  data?: {
    title: string; // "Please Click Continue to proceed"
    click_value: string;
  }[];
  onClick: (params: {
    title: string;
    click_value: string;
    input_id: string;
  }) => void;
  exec_id?: string;
  input_id?: string;
  isCompleted: boolean;
  isDisabled?: boolean;
}

const DynamicInputButtons: React.FC<DynamicInputButtonsProps> = ({
  data,
  onClick,
  exec_id,
  input_id,
  isCompleted,
  config,
  isDisabled,
}) => {
  console.log("Buttons", data, config);
  return (
    <>
      {!!data?.length && (
        <div className="flex flex-col gap-2 align-center w-full pl-0">
          {data?.map((value) => {
            return (
              <InputButton
                disabled={isDisabled}
                onClick={() => {
                  console.log("input_id", input_id);
                  if (onClick && input_id)
                    onClick({
                      title: value.title,
                      click_value: value.click_value,
                      input_id,
                    });
                }}
              >
                {value.title}
              </InputButton>
            );
          })}
          {/* {data?.map((value) => { */}
          {/* return ( */}
          {/* <ActionChips
            removePadding
            isDisabled={isDisabled}
            chipActions={data?.map((value) => ({
              action_display: value?.title,
              action_value: value?.click_value,
            }))}
            handleClickChip={(action) => {
              console.log("input_id", input_id);
              if (onClick && input_id)
                onClick({
                  title: action?.action_display,
                  click_value: action?.action_value,
                  input_id,
                });
            }}
          /> */}
          {/* ); */}
          {/* })} */}
        </div>
      )}
    </>
  );
};

export default DynamicInputButtons;
