import { useConfig } from "contexts/ConfigContext";
import React from "react";
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link-icon.svg";

export interface ViewCardProduct {
  image_url: string | null;
  info_l1: string;
  info_l2: string | number;
  order_id: string | number;
  product_url: string | null;
  title: string;
}

interface CardItemProps {
  product: ViewCardProduct;
  isHovered: boolean;
  onHover: () => void;
  onLeave: () => void;
}

const CardItem: React.FC<CardItemProps> = ({
  product,
  isHovered,
  onHover,
  onLeave,
}) => {
  const { config } = useConfig();
  console.log("config", config);
  return (
    <div
      className={`flex items-start  transition-all duration-300 cursor-pointer max-h-[80px] first:rounded-t-lg last:rounded-b-lg last:border-b-0 first:border-t-0 ${
        isHovered ? "bg-inkAlpha-50" : "bg-white"
      } border-b border-gray-200`}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <img
        src={product.image_url || ""}
        alt={product.title}
        className="w-20 h-[79px] object-cover group-first:rounded-t-lg group-last:rounded-b-lg mr-4"
      />
      <div className="flex-grow">
        <div className="p-3">
          <div
            className={`text-sm font-semibold text-ink flex flex-row items-center gap-1 cursor-pointer`}
            style={{ color: isHovered ? config?.colors?.brand_color : "" }}
            onClick={() =>
              product.product_url
                ? window.open(product.product_url || "", "_blank")
                : null
            }
          >
            <h2>{product.title}</h2>
            <span>
              <div
                className={`w-5 h-5 ${isHovered ? "visible" : "invisible"}`}
                style={{
                  color: config?.colors?.brand_color || "",
                }}
              >
                <ExternalLinkIcon />
              </div>
            </span>
          </div>
          {!!product.info_l1 && (
            <p className="text-xs text-gray-800">{product.info_l1}</p>
          )}
          {!!product.info_l2 && (
            <p className="text-xs text-gray-800">{product.info_l2}</p>
          )}
          {/* <p className="text-xs text-gray-800">${product.price.toFixed(2)}</p> */}
        </div>
      </div>
      <div className="text-right"></div>
    </div>
  );
};

export default CardItem;
