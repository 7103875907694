import SelectBox from "components/Library/SelectBox";
import React from "react";
import { ReactComponent as GoogleSheetsIcon } from "assets/images/google-sheets-icon.svg";
import { hexToRgbA } from "utils/Misc";
import { useConfig } from "contexts/ConfigContext";
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link-icon.svg";

interface Props {
  outputFileType: string;
  handleOutputConfigChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  InputField: {
    file_upload_config: {
      allowed_formats: string[];
    };
  };
  OutputOptionsDisplayMap: { [key: string]: string };
  stepErrors?: { output_config?: string; output_config_connection?: string };
  formValues: { googleSheetConnected?: boolean };
  googleUserInfo?: { email: string };
  handleGoogleSheetConnect: () => void;
  handleGoogleSheetDisconnect: () => void;
}

const OutputSelectConfig: React.FC<Props> = ({
  outputFileType,
  handleOutputConfigChange,
  InputField,
  OutputOptionsDisplayMap,
  stepErrors,
  formValues,
  googleUserInfo,
  handleGoogleSheetConnect,
  handleGoogleSheetDisconnect,
}) => {
  const { config } = useConfig();
  console.log("outputFileType", outputFileType);
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row gap-3 items-center pt-4">
        <div className="text-ink text-sm font-semibold">Configure Output</div>
        <hr className="grow h-[1px] bg-gray-400" />
      </div>
      <div className="flex flex-col gap-1 md:grid grid-cols-1 md:grid-cols-2 md:gap-4">
        <SelectBox
          disablePlaceholder={true}
          disabled={!outputFileType}
          label="Output Format"
          name="outputFormat"
          selectedValue={outputFileType}
          onChange={handleOutputConfigChange}
          options={InputField?.file_upload_config?.allowed_formats.map(
            (format) => ({
              id: format,
              name: OutputOptionsDisplayMap?.[format] || "",
            })
          )}
          placeholder="Select Output Format..."
        />

        {!!stepErrors?.output_config && (
          <div className="text-robylon-error text-xs px-1 py-1">
            {stepErrors?.output_config}
          </div>
        )}
      </div>
      {formValues?.googleSheetConnected &&
        googleUserInfo &&
        outputFileType === "GOOGLE_SHEETS" && (
          <div
            className="px-3 py-2 rounded-lg   justify-between items-center gap-3 flex flex-row"
            style={{
              backgroundColor: config?.colors?.brand_color
                ? hexToRgbA(config?.colors?.brand_color, 0.04)
                : "",
            }}
          >
            <div className="flex items-center gap-2">
              <div className="w-[13px] h-[18px]">
                <GoogleSheetsIcon />
              </div>
              <span className="text-ink font-semibold text-xs">
                <span className="text-gray-800 text-xs">Account: </span>
                {googleUserInfo?.email}
              </span>
            </div>
            <button
              type="button"
              className={`ml-1 text-xs font-semibold flex flex-row items-center gap-1`}
              style={{ color: config?.colors?.brand_color || "" }}
              onClick={handleGoogleSheetDisconnect}
            >
              <span>Disconnect</span>
            </button>
          </div>
          // <div>Account: {googleUserInfo?.email}</div>
        )}
      {!formValues?.googleSheetConnected &&
        outputFileType === "GOOGLE_SHEETS" && (
          <div
            className="px-3 py-2 rounded-lg   justify-between items-center gap-3 flex flex-row"
            style={{
              backgroundColor: config?.colors?.brand_color
                ? hexToRgbA(config?.colors?.brand_color, 0.04)
                : "",
            }}
          >
            <div className="flex items-center gap-2">
              <div className="w-[13px] h-[18px]">
                <GoogleSheetsIcon />
              </div>
              <span className="text-gray-900 text-xs">
                Connect Google Sheets Account
              </span>
            </div>
            <button
              type="button"
              className={`ml-1 text-xs font-semibold flex flex-row items-center gap-1`}
              style={{ color: config?.colors?.brand_color || "" }}
              onClick={() => handleGoogleSheetConnect()}
            >
              <span>Connect</span>
              <div className="w-4 h-4">
                <ExternalLinkIcon />
              </div>
            </button>
          </div>

          // <div className="flex flex-row">
          //   <div>Connect to Google Sheets </div>
          //   <div
          //     onClick={handleGoogleSheetConnect}
          //     className="text-blue-500 cursor-pointer"
          //   >
          //     Connect
          //   </div>
          // </div>
        )}
      {formValues?.googleSheetConnected &&
        googleUserInfo &&
        outputFileType === "GOOGLE_SHEETS" && (
          <div className=" text-xs -mt-2 text-gray-800">
            A New file will be created with name
            <span className="text-pink-700">{` "{spreadsheet name}_{worksheet name}_output"`}</span>{" "}
            in your Google Drive
          </div>
        )}
      {outputFileType === "CSV" && (
        <div className=" text-xs -mt-2 text-gray-800">
          A CSV file with name
          <span className="text-pink-700">{` "{csv name}_output"`}</span> will
          be sent to you by co-pilot at the end of the automation with desired
          results
        </div>
      )}
      {!!stepErrors?.output_config_connection && (
        <div className="text-robylon-error text-xs px-2 bg-red-50 rounded-lg py-1">
          {stepErrors?.output_config_connection}
        </div>
      )}
    </div>
  );
};

export default OutputSelectConfig;
