import React, { useState } from "react";
import TaskItem, { AllAutomationWorkflow } from "components/TaskItem";
import { useConfig } from "contexts/ConfigContext";
import { ReactComponent as NoAutomationsIcon } from "assets/images/no-automations-icon.svg";
import { ReactComponent as NoFavouritesIcon } from "assets/images/no-favourite-icon.svg";

interface TaskListProps {
  tasks: AllAutomationWorkflow[];
  onBookmark: (id: string, bookmarked: boolean) => void;
  onRun: (workflow: AllAutomationWorkflow) => Promise<void>;
  canRun?: boolean;
  favouriteFilterApplied?: boolean;
}

const TaskList: React.FC<TaskListProps> = ({
  tasks,
  onBookmark,
  onRun,
  canRun,
  favouriteFilterApplied,
}) => {
  const { config } = useConfig();
  const [activePopover, setActivePopover] = useState<string | null>(null);

  const handlePopoverToggle = (workflowId: string) => {
    setActivePopover((prev) => (prev === workflowId ? null : workflowId));
  };
  const [workflowRunning, setWorkflowRunning] = useState<string>();
  console.log("Can Run in TaskList", canRun);
  return (
    <div className="w-full">
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4  mb-4">
        {!!tasks?.length &&
          tasks.map((task) => (
            <TaskItem
              onTogglePopover={() => handlePopoverToggle(task.workflow_id)}
              showPopover={activePopover === task.workflow_id}
              isRunning={workflowRunning === task.workflow_id}
              isDisabled={!canRun}
              onRun={async (workflow) => {
                setWorkflowRunning(workflow?.workflow_id);
                await onRun(workflow);
              }}
              key={task.workflow_id}
              task={task}
              onBookmark={onBookmark}
              brandColor={config?.colors?.brand_color}
            />
          ))}
      </div>

      {tasks && !tasks?.length && (
        <div className="h-[60vh] flex flex-col justify-center items-center">
          <div className="p-2 bg-gray-100 rounded-lg ">
            {!favouriteFilterApplied && (
              <div className="h-6 w-6">
                <NoAutomationsIcon />
              </div>
            )}
            {!!favouriteFilterApplied && (
              <div className="h-6 w-6">
                <NoFavouritesIcon />
              </div>
            )}
          </div>
          {!favouriteFilterApplied && (
            <div className="text-center text-base text-gray-800">
              No Automations Found...
            </div>
          )}
          {!!favouriteFilterApplied && (
            <div className="text-center text-base text-gray-800">
              No Favourite Automations Yet...
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskList;
