import { useConfig } from "contexts/ConfigContext";
import React from "react";

interface FooterProps {
  onCancel: () => void;
  nextStep: () => void;
  cancelBtnTitle?: string;
}

const FormFooter: React.FC<FooterProps> = ({
  onCancel,
  nextStep,
  cancelBtnTitle,
}) => {
  const { config } = useConfig();
  return (
    <div
      className="fixed bottom-0 left-0 right-0  p-4 flex justify-between gap-4"
      style={{ boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.16)" }}
    >
      <button
        style={{
          borderColor: config?.colors?.brand_color || "",
          color: config?.colors?.brand_color || "",
        }}
        type="button"
        className="basis-1/2 py-2 px-4 border rounded-md font-semibold text-base"
        onClick={onCancel}
      >
        {cancelBtnTitle || "Go Back"}
      </button>
      <button
        style={{ background: config?.colors?.brand_color || "" }}
        type="button"
        className={`py-2 px-4 basis-1/2 text-white ${
          !config?.colors?.brand_color ? "" : "bg-robylon-blue"
        } rounded-md font-semibold  text-base`}
        onClick={nextStep}
      >
        Next Step
      </button>
    </div>
  );
};

export default FormFooter;
