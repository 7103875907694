import { hexToRgbA } from "utils/Misc";
import React from "react";

interface FilterButtonProps {
  label: string | React.ReactNode;
  count: number | null;
  isSelected: boolean;
  onClick: () => void;
  brandColor?: string;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  label,
  count,
  isSelected,
  onClick,
  brandColor,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        borderColor: isSelected ? brandColor : "",
        backgroundColor: isSelected ? hexToRgbA(brandColor, 0.08) : "",
      }}
      className={`px-3 py-1 rounded-full border transition-colors duration-200 text-xs gap-2 flex items-center whitespace-pre
        ${
          !isSelected
            ? "bg-white border-gray-500 text-gray-500 hover:border-gray-600 hover:text-gray-700 hover:bg-filterTag group"
            : "font-normal"
        }
      `}
    >
      <span
        className="text-ink"
        style={{ color: isSelected ? brandColor : "" }}
      >
        {label}
      </span>
      {count !== null && (
        <span
          className={`text-gray-500 group-hover:text-gray-700`}
          style={{ color: isSelected ? brandColor : "" }}
        >
          {count}
        </span>
      )}
    </button>
  );
};

export default FilterButton;
