import React from "react";

type Props = {
  onClick: () => void;
  title: string | React.ReactNode;
  rightIcon?: React.ReactNode;
  isLoading?: boolean;
  brand_colour?: string;
};

const ExecutionStateButton = ({
  onClick,
  title,
  rightIcon,
  isLoading,
  brand_colour,
}: Props) => {
  return (
    <div
      className={`flex flex-row items-center justify-center gap-1 cursor-pointer w-full rounded-b-[12px] ${
        isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-50"
      } p-3`}
      onClick={onClick}
    >
      <div
        style={{
          color: brand_colour || "",
        }}
        className={`text-sm ${
          !brand_colour ? "text-robylon-blue" : ""
        } rounded-md font-semibold  flex flex-row items-center gap-1`}
      >
        {title}
        {!!rightIcon && rightIcon}
      </div>
    </div>
  );
};

export default ExecutionStateButton;
