import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  Button,
} from "@chatscope/chat-ui-kit-react";
import Loader from "assets/lotties/loader-light-animation.json";
import {
  Organization,
  PageInfo,
  User,
  dummyCopilotData,
  getAuthToken,
  getCopilotData,
  getOrgListApi,
  getUserFromApi,
} from "apiService";
import { useLocation } from "react-router-dom";
import { ReactComponent as SendMessageIcon } from "assets/images/send-message-icon.svg";
import SendMessageJiraIcon from "assets/images/send-message-icon-jira.svg";
import SendMessageAirmeetIcon from "assets/images/send-message-icon-airmeet.svg";
import SendMessageTeachmintIcon from "assets/images/send-message-icon-teachmint.svg";
import SendMessageFareyeIcon from "assets/images/send-message-icon-fareye.svg";

import {
  downloadFile,
  formatDateTooltipTime,
  formatMessageTimestamp,
  generateRandomString,
  getBestFontColor,
  hexToRgbA,
  isLowContrastColor,
  truncateWords,
} from "utils/Misc";
import AutomationInputForm from "./AutomationInputForm";
import ExecutionState from "components/ExecutionState";
import {
  jiraConfig,
  airmeetConfig,
  teachmintConfig,
  fareyeConfig,
} from "utils/Config";
import CloseButton from "components/CloseButton";
import RefreshButton from "components/RefreshButton";
import AskSessionStandalone from "components/AskSessionStandalone";
import SettingsButton from "components/SettingsButton";
import SettingsPage from "components/SettingsPage";
import DynamicInputButtons from "components/DynamicInputButtons";
import DynamicInputDataToShow from "components/DynamicInputDataToShow";
import TimelineSeparation from "components/TimelineSeparation";
import { WorkflowStatusEnum } from "components/ExecutionStateStatus";
import ExpandButton from "components/ExpandButton";
import CollapseButton from "components/CollapseButton";
import { ReactComponent as BackIcon } from "assets/images/back-arrow-icon.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import BotTypingAnimation from "assets/lotties/bot-typing-animation.json";
import AllAutomations from "components/AllAutomations";
import { useConfig } from "contexts/ConfigContext";
import { AllAutomationWorkflow } from "components/TaskItem";
import { ReactComponent as CaretRight } from "assets/images/caret-right-icon.svg";
import { ReactComponent as DownloadIcon } from "assets/images/download-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link-icon.svg";
import { ReactComponent as CSVFileIcon } from "assets/images/csv-file-icon.svg";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import RedirectLinksContainer from "./RedirectLinksContainer";
import { mockProducts, mockTable } from "components/mockProducts";
import ViewCardList from "components/CardListTemplates/ViewCardList";
import SelectableCardList from "components/CardListTemplates/SelectableCardList/SelectableCardList";
import PlainTable from "components/TableTemplates/PlainTable/PlainTable";
import { ViewCardProduct } from "components/CardListTemplates/CardItem";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "styles/chatscope.overrides.css";
import { SelectableCardProduct } from "components/CardListTemplates/SelectableCardList/SelectableCardItem";
import StatusChangeSeparation from "components/StatuChangeSeparation";
import DamageProductImageUploadpage from "./DamageProductImageUploadpage";
import DamagedProductOptionButton from "./DamagedProductOptionButton";
import ActionChips from "./ActionChips";
import Chip from "@components/ActionChips/Chip";
import DamageImageChatPreview from "./DamageImageChatPreview";
import SrcollToBottomBtn from "components/ScrollToBottomBtn/SrcollToBottomBtn";
import DynamicInputCarousel from "components/Carousel/DynamicInputCarousel";
import ChatbotCSATModal, {
  CSATFormTemplate,
  FormFieldType,
  RatingType,
} from "components/CSATForm/ChatbotCSATModal";
import ChatClosedSeparation from "components/ChatClosedSeparation";
import ReportNewIssueButton from "components/ReportNewIssueButton";
import CsatCompleteSeparation from "components/CsatCompleteSeparation";
import { handleLinkClick, sanitizeAndModifyLinks } from "utils/LinkHandler";

const allowedHistoryEvents = [
  "server_m",
  "workflow_found",
  "capture_session_complete",
  "workflow_inputs_complete",
  "wf_exec_log",
  "dynamic_input",
  "dynamic_input_complete",
  "file_download_info",
  "workflow_not_found",
  "user_m",
  "agent_assign",
  "item_select_complete",
  "message_group",
  "submit_images_complete",
  "chat_closed",
];

export enum MessageFormattingTypes {
  TEXT = "TEXT",
  LINKS = "LINKS",
  TABLE = "TABLE",
  PRODUCT_LIST = "PRODUCT_LIST",
  STATUS_CHANGE = "STATUS_CHANGE",
  PROMPT_CHIPS = "PROMPT_CHIPS",
  PRODUCT_LIST_SELECT = "PRODUCT_LIST_SELECT",
  DAMAGED_PRODUCT = "DAMAGED_PRODUCT",
  BUTTON = "BUTTON",
  CAROUSEL = "CAROUSEL",
  IMAGES = "IMAGES",
}

type WorkflowStatus = "LOADING" | "SUCCESS" | "FAILED";

enum DynamicInputType {
  BUTTON = "BUTTON",
  DATA = "DATA",
}

interface IMessage {
  text: string;
  direction: "incoming" | "outgoing";
  loading?: boolean;
  isForm?: boolean;
  isFromList?: boolean;
  inputs?: any;
  contextId?: string;
  status?: WorkflowStatusEnum;
  workflow?: { workflow: Workflow | AllAutomationWorkflow };
  video_url?: string;
  input_payload?: InputPayload;
  input_id?: string;
  exec_id?: string;
  isDynamicContent?: boolean;
  dynamicInputType?: DynamicInputType;
  m_id?: string;
  isDisabled?: boolean;
  file_info?: FileDownloadInfo;
  ts: string;
  isHistory?: boolean;
  hideExecution?: boolean;
  msg_formatting?: MessageFormattingTypes;
  formattingData?: any;
  isItemSelectComplete?: boolean;
  actions?: ActionType[];
  isDamageProductImage?: boolean;
  imageUrls?: string[] | undefined | null;
  isChatClosed?: boolean;
}

interface Props {
  showClose?: boolean;
  showRefresh?: boolean;
  onClose?: () => void;
  isStandAlone?: boolean;
  isPlugin?: boolean;
}

export interface Workflow {
  workflow_id: string;
  workflow_data: object;
  title: string | null;
  description: string | null;
  input_fields: any | null;
  requires_inputs: boolean | null;
  published: boolean;
  created_at: string;
  updated_at: string;
  last_run_at: string | null;
  apps: any[];
  user: string;
  is_auth_complete?: boolean;
  workflow_num?: number;
}

export interface MetaInfo {
  can_trigger_wf: boolean;
  running_wf?: {
    exec_id: string | null;
    wf_info: Workflow;
  };
}

export interface AutomationList {
  data: AllAutomationWorkflow[] | undefined;
  page_info: AutomationPageInfo | null;
  meta_info: MetaInfo | null;
}

interface InputPayload {
  button: {
    text: string;
    values: Array<{
      value: string;
      ID: string;
      primary_button: boolean;
    }>;
  };
  data_to_show: {
    text: string;
    values: Array<{
      value: string;
      ID: string;
      display_text: string;
    }>;
  };
  input_form: {
    text: string;
    values: Array<{
      value: string;
      ID: string;
      type: string;
    }>;
  };
}

interface AutomationPageInfo extends PageInfo {
  tag_counter: {
    [key: string]: number;
  };
}

interface HistoryPageInfo extends PageInfo {
  cursor: string | null;
}

export interface FileUploadUrlResponse {
  workflow_id: string;
  tracker_id: string;
  presigned_url: string;
}

export interface GoogleUserProfile {
  id: string;
  name: string;
  email: string;
  picture: string;
  verified_email: boolean;
}

export interface DocumentMetadata {
  createdTime: string;
  id: string;
  modifiedTime: string;
  name: string;
}

export interface WorksheetMetadata {
  id: string;
  title: string;
}

export interface FileDownloadInfo {
  message_to_show: string;
  file_type: string;
  size: string;
  url: string;
  file_name: string;
}

export interface HistoryInfo {
  data: WebSocketMessage;
  sender: "SERVER" | "USER";
}

type ActionParam = {
  param_key: string; // or another appropriate type for 'image'
  type: string;
  param_value: string;
};

interface ActionType {
  action_display: string;
  action_name: string;
  params: ActionParam[];
  product_name: string;
  type: string;
}

interface UploadUrlDataType {
  presigned_url: string;
  s3_url: string;
  tracker_id: string;
}

export interface WebSocketMessage {
  m_id?: string;
  chat_id: string;
  user_id?: string;
  input_id?: string;
  event_type: string;
  message?: string;
  error?: string;
  inputs: any;
  result?: any;
  context_id?: string;
  workflow?: { workflow: Workflow };
  status?: WorkflowStatusEnum;
  video_url?: string;
  generated_content?: { [key: string]: string };
  exec_id?: string;
  live_stream_url?: string;
  end_state_info?: {
    url: string;
    button_name: string;
  };
  input_payload?: InputPayload;
  text_to_display?: string;
  workflows: AllAutomationWorkflow[];
  page_info: AutomationPageInfo;
  meta_info: any;
  input_info?: FileUploadUrlResponse;
  validation_info?: {
    headers?: string[];
  };
  connected?: boolean; // This is for google sheets connection
  authorization_url?: string; // This is for google auth
  user_info?: GoogleUserProfile;
  spreadsheets?: DocumentMetadata[];
  worksheets?: WorksheetMetadata[];
  worksheet_headers?: string[];
  file_download_info?: FileDownloadInfo;
  ts: string;
  history_info?: { history: HistoryInfo[]; page_info: HistoryPageInfo };
  msg_formatting?: MessageFormattingTypes;
  messages?: WebSocketMessage[];
  actions: ActionType[];
  upload_info?: UploadUrlDataType;
  image_urls: string[] | null;
  title?: string;
  click_value?: string;
  csat_form?: { template: CSATFormTemplate };
  enable_text_box?: boolean;
}

interface SendMessageParams {
  event_type?: string;
  message?: string;
  user_inputs?: any[];
  m_id?: string;
  chat_id?: string;
  user_id?: string;
  session_info?: any;
  cookies_info?: any;
  storage_info?: any;
  current_url?: string;
  org_id?: string | null;
  domain_name?: string | null;
}

const idToConfigMap: Record<string, any> = {
  jira: jiraConfig,
  airmeet: airmeetConfig,
  teachmint: teachmintConfig,
  fareye: fareyeConfig,
};

const idToIconMap: Record<string, string> = {
  jira: SendMessageJiraIcon,
  airmeet: SendMessageAirmeetIcon,
  teachmint: SendMessageTeachmintIcon,
  fareye: SendMessageFareyeIcon,
};

const fareyeuserId = "4c928499-1549-416e-8f45-3ef18c94a82d";

const setUserId = (user_id: string, isFarEye: boolean) => {
  localStorage?.setItem("user_id", isFarEye ? fareyeuserId : user_id);
};

const isFormattedMessage = (formatting?: MessageFormattingTypes) => {
  return !formatting || formatting === MessageFormattingTypes.TEXT
    ? undefined
    : formatting;
};

const isBlocking = (formatting?: MessageFormattingTypes) => {
  const nonBlockingFormats = [
    MessageFormattingTypes?.PRODUCT_LIST,
    MessageFormattingTypes.TEXT,
    MessageFormattingTypes.STATUS_CHANGE,
    MessageFormattingTypes.LINKS,
  ];
  if (formatting && nonBlockingFormats.includes(formatting)) {
    return false;
  }
  return true;
};
const ChatbotUIClientSdk: React.FC<Props> = ({
  showClose,
  showRefresh,
  onClose,
  isStandAlone,
  isPlugin,
}: Props) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const messagesRef = useRef(messages);
  const [inputValue, setInputValue] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>("10");
  const [botTyping, setBotTyping] = useState<boolean>(false);
  const location = useLocation();
  const [parentDomain, setParentDomain] = useState<string>(
    document.referrer ? new URL(document.referrer).hostname : ""
  );
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [popupOpened, setPopupOpened] = useState<boolean>(false);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const websocketRef = useRef<WebSocket | null>(websocket);
  const [showInputForm, setShowInputForm] = useState<boolean>(false);
  const [inputMessage, setInputMessage] = useState<IMessage>();
  const [workflowContextId, setWorkflowContextId] = useState("");
  const [workflowStatusMap, setWorkflowStatusMap] = useState<
    Record<string, Record<string, any>>
  >({});
  const [execId, setExecId] = useState<string>();
  const [workflowVideoMap, setWorkflowVideoMap] = useState<
    Record<string, Record<string, any>>
  >({});
  const [workflowStreamMap, setWorkflowStreamMap] = useState<
    Record<string, Record<string, any>>
  >({});
  const [endStateInfo, setEndStateInfo] = useState<any>({});
  const [rephrasedValues, setRephrasedValues] = useState<{
    [key: string]: string;
  }>();
  const [sessionData, setSessionData] = useState<any>();

  const [sessionDataLoading, setSessionDataLoading] = useState<boolean>(false);
  const [sessionDataSuccess, setSessionDataSuccess] = useState<boolean>(false);
  const [sessionDataError, setSessionDataError] = useState<boolean>(false);
  // const [config, setConfig] = useState<Organization>();
  const { config, setConfig, organization, setOrganization } = useConfig();
  const [authComplete, setAuthcomplete] = useState<boolean>(false);
  // const [user, setUser] = useState<User>();
  const [thirdPartyUser, setThirdPartyUser] = useState<{ userId: string }>();
  const [openAuthScreen, setOpenAuthScreen] = useState<boolean>(false);
  const [openSettingsScreen, setOpenSettingsScreen] = useState<boolean>(false);
  const [openAllAutomations, setOpenAllAutomations] = useState<boolean>(false);
  const [isDamagedImageUploadOpen, setIsDamagedImageUploadOpen] =
    useState<boolean>(false);
  const [completedInputIds, setCompletedInputIds] = useState<string[]>([]);
  const lastSaveIndexRef = useRef<any>();
  const [isWindowExpanded, setIsWindowExpanded] = useState<boolean>(false);
  const [tokenExists, setTokenExists] = useState<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const extensionId = queryParams.get("extensionId");
  const [automationList, setAutomationList] = useState<AutomationList>({
    data: undefined,
    page_info: null,
    meta_info: null,
  });

  const [csatDetails, setCsatDetails] = useState<CSATFormTemplate | null>(null);
  const [wsUrl, setWsUrl] = useState<string | null>(null);
  const [currentWorkflow, setCurrentWorkflow] = useState<
    AllAutomationWorkflow | Workflow
  >();
  const currentWorkflowRef = useRef(currentWorkflow);
  const [triggerDirectRun, setTriggerDirectRun] = useState<boolean>(false);
  const [runFromList, setRunFromList] = useState<boolean>(false);
  const [showLeaveAlert, setShowLeaveAlert] = useState<boolean>(false);
  const [chatInited, setChatInited] = useState<boolean>(false);
  console.log("messages, ", messages);
  // Add a state to track if the history is being loaded
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const isLoadingHistoryRef = useRef<boolean>(isLoadingHistory);
  const [historyPageInfo, setHistoryPageInfo] = useState<HistoryPageInfo>();
  const messageListContainerRef = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isWindowOpened, setIsWindowOpened] = useState<boolean>(false);
  const [automationRunning, setAutomationRunning] = useState<boolean>(false);
  const [currentOrderId, setCurrentOrderId] = useState<ActionParam | undefined>(
    undefined
  );
  const [lastMid, setLastMid] = useState<string | null>(null);
  const [showAllAutomationCta, setShowAllAutomationCta] =
    useState<boolean>(false);
  const [isCSATModalOpen, setISCSATModalOpen] = useState(false);
  const [chatClosed, setChatClosed] = useState(false);
  const [csatComplete, setCsatComplete] = useState(false);
  const removeLoadingMessage = (
    messageList: IMessage[],
    message?: IMessage
  ) => {
    let updatedMessages = [...messageList];
    if (updatedMessages[updatedMessages.length - 1]?.loading) {
      if (message) updatedMessages[updatedMessages.length - 1] = message;
      else {
        updatedMessages = updatedMessages.slice(0, -1);
      }
    } else {
      if (message) {
        updatedMessages.push(message);
      }
    }

    // Replace the last message
    return updatedMessages;
  };

  const getAuthTokenFromPlugin = async () => {
    return new Promise<string | undefined>((resolve, reject) =>
      chrome.runtime.sendMessage(
        extensionId,
        { action: "getAuthToken" },
        (response) => {
          console.log("Response from getAuthToken", response);
          if (chrome.runtime.lastError || response.error) {
            reject(undefined);
          } else {
            console.log("getAuthToken response", response);
            resolve(response?.token);
          }
        }
      )
    );
  };

  const sendDataToToken = useCallback(
    async (data: any) => {
      try {
        console.log("received data sendDataToToken", data, thirdPartyUser);
        if (!thirdPartyUser) {
          console.error("Sdk not initialized");
          return;
        }

        let localstorage = data?.localStorage
          ? JSON.parse(data?.localStorage)
          : {};

        if (id === "66b33d16-5b38-4693-8772-1a1bd7dd412d") {
          localstorage = {
            ADMINS_GLOBAL:
              '{"067c97cd-55fd-456e-8f2b-320ce3de722e":{"CURRENT_INSTITUTE_ID":"RIV382","ACTIVE_ACADEMIC_SESSION_ID":"bbd75a36-bbcd-480d-93c1-07ca04b7c6c2","ADMIN_NOTIFICATIONS":"[]"}}',
          };
        }
        let sessionStorage = data?.sessionStorage
          ? JSON.parse(data?.sessionStorage)
          : {};
        if (id === "66b33d16-5b38-4693-8772-1a1bd7dd412d") {
          sessionStorage = {
            ADMIN_UUID: "067c97cd-55fd-456e-8f2b-320ce3de722e",
          };
        }
        const url = data?.url || null;

        const extraParams = {
          session_info: sessionStorage,
          storage_info: localstorage,
          cookie_info: data?.cookies,
          current_url: url || undefined,
          domain_name: data?.host,
        };

        const basePayload = {
          client_user_id: thirdPartyUser?.userId,
          org_id: id,
          client_user_info: {
            ...thirdPartyUser,
          },
          extra_info: {},
        };
        const payload = { ...basePayload, ...extraParams };
        if (id === "66b33d16-5b38-4693-8772-1a1bd7dd412d") {
          payload?.cookie_info?.push({
            name: "sessions",
            value:
              ".eJw9jstugzAQRX8l8rpEjI0fZN3uo7RS1RUCewgIsJFt1JAo_167UruamXtGR_dBAoYwOhvI6fAgpZC6ltoUnPemqLjAQvW0KxgtNTKDklL8fdxGkyYRogToqabKgFTSANNCy1qQlwOZ3dVtMX317RwwBXkHCYoCCM6OUAtalVUCHnuPYWiim9Bm7RvehnOnJva16I_x_fL9Od3V3Z45VNmMt3X0-7-NCYBjalYLzhPdAvom7msumu1za69ZmtTpsu2SAbm4bp-dPbzi4nKunY1oY9NucUg8-g2fz5Q3K_qltQn9pT_yLVmk.Zmnavw.71aUHWeRhsLxCxFfXwwuDRPz_wsN3T4kILSSOTMy5uOY4zJCnJ0qQOYova6SXgam80LkdmJ-_EyYa1vGRB9P_w",
          });
        }
        const token = await getAuthToken(payload);
        if (token) {
          setTokenExists(true);
          return true;
        } else {
          setTokenExists(false);
          return false;
        }
      } catch (error) {
        setTokenExists(false);
        return false;
      }
    },
    [thirdPartyUser]
  );

  useEffect(() => {
    if (id && thirdPartyUser) requestData();
  }, [id, thirdPartyUser]);

  useEffect(() => {
    if (id && tokenExists && !isPlugin) {
      getUserAndCompanies();
    }
    if (id && isPlugin) {
      getUserAndCompaniesForPlugin();
    }
  }, [id, tokenExists]);

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);
    return () => window.removeEventListener("message", handleMessageEvent);
  }, [websocket, tokenExists, thirdPartyUser]);

  useEffect(() => {
    if (authComplete) {
      if (openAuthScreen) {
        setOpenAuthScreen(false);
        resetSessionVariables();
        onRun(messages[messages.length - 1], true);
      }
    }
  }, [authComplete, messages, openAuthScreen]);

  useEffect(() => {
    if (!openSettingsScreen) resetSessionVariables();
  }, [openSettingsScreen]);

  useEffect(() => {
    if (!showInputForm) resetSessionVariables();
  }, [showInputForm]);

  useEffect(() => {
    if (config && wsUrl) startNewWs();
  }, [config, wsUrl]);

  useEffect(() => {
    const checkConnection = setInterval(() => {
      if (websocket && websocket.readyState !== WebSocket.OPEN) {
        console.error("Connection is not open");
        startNewWs();
      }
    }, 5000);
    return () => clearInterval(checkConnection);
  }, [websocket]);

  const handleMessageEvent = (event: MessageEvent) => {
    if (event?.data?.action === "capturedSessionData") {
      const receivedData = event.data;
      if (tokenExists) captureData(receivedData);
      else {
        sendDataToToken(receivedData);
      }
    }
    if (event?.data?.action === "registerUserId") {
      const receivedData = event?.data?.data;
      console.log("receivedData registerUserId", receivedData);
      setThirdPartyUser(receivedData);
    }
  };

  useEffect(() => {
    if (triggerDirectRun && currentWorkflowRef?.current) {
      onRunDirectWorkflow(currentWorkflowRef?.current);
    }
  }, [triggerDirectRun]);

  const getUserAndCompanies = async () => {
    try {
      const token = await getAuthToken();
      if (token) {
        const resultsUserAndOrg = await Promise.all([getCopilotData()]);
        const copilotInfo = resultsUserAndOrg[0];
        const company = copilotInfo?.user?.org_info;
        const currentOrg = company;
        const socketUrl = copilotInfo?.user?.ws_url;
        setWsUrl(socketUrl);
        console.log("currentOrg===>", currentOrg);
        setOrganization(currentOrg);
        setConfig(currentOrg?.brand_config);
      }
    } catch (error) {
      console.error("Error getting user and companies:", error);
    }
  };

  const getUserAndCompaniesForPlugin = async () => {
    try {
      const token = await getAuthTokenFromPlugin();
      if (token) {
        const resultsUserAndOrg = await Promise.all([getOrgListApi(token)]);
        console.log("resultsUserAndOrg", resultsUserAndOrg);
        const companies = resultsUserAndOrg[0]?.data?.result;
        const currentOrg = companies?.find((org: any) => id === org?.org_id);
        setOrganization(currentOrg);
        setConfig(currentOrg?.brand_config);
      }
    } catch (error) {}
  };

  // const getAuthToken = async (): Promise<string> => {
  //   return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE4NjI1NTc0LCJpYXQiOjE3MTc3NjE1NzQsImp0aSI6ImE1OTFhMTc3NDY0NDQxNWZhOGIyODZiZGIzNWRlYzY5IiwidXNlcl9pZCI6Ijc4NmI3MmQxLTYyMjEtNDZlNC1hNDM0LWQ2YjllY2FkZDg4ZSJ9.QD1U-tP42S2twA1pMZ4r7gX9GACbKgRB6cxG4Ef52hA";
  // };

  const requestData = useCallback(() => {
    window.parent.postMessage("captureSessionData", "*");
  }, []);

  const toggleWindowSize = useCallback(() => {
    if (!isWindowExpanded) {
      window.parent.postMessage("expand", "*");
      setIsWindowExpanded(true);
    }
    if (isWindowExpanded) {
      window.parent.postMessage("collapse", "*");
      setIsWindowExpanded(false);
    }
  }, [isWindowExpanded]);

  const captureData = async (response: any) => {
    setSessionDataLoading(true);
    try {
      const localstorage = response?.localStorage
        ? JSON.parse(response?.localStorage)
        : {};
      const sessionStorage = response?.sessionStorage
        ? JSON.parse(response?.sessionStorage)
        : {};
      const url = response?.url || null;

      const extraParams = {
        session_info: sessionStorage,
        storage_info: localstorage,
        cookies_info: response?.cookies,
        current_url: url || undefined,
        domain_name: response?.host,
      };
      const basePayload = getBasePayload("capture_session");
      const payload = { ...basePayload, ...extraParams };
      sendMessageToWs(payload);
      setSessionDataSuccess(true);
    } catch (error) {
      console.error("Error capturing data or sending to backend:", error);
      setSessionDataError(true);
    }
    setSessionDataLoading(false);
  };

  const getAllAutomations = (tags?: string[]) => {
    let payload: any = getBasePayload("list_workflows");
    if (tags) {
      payload = { ...payload, tags: tags };
    }
    sendMessageToWs(payload);
  };

  const resetAutomationList = () => {
    setAutomationList({
      data: undefined,
      page_info: null,
      meta_info: null,
    });
  };

  const onAddBookmark = (selectedId: string) => {
    const payload = getBasePayload("toggle_favourite_wf");
    sendMessageToWs({ ...payload, workflow_id: selectedId });
  };

  const getBasePayload = (event_name: string) => {
    if (websocket) {
      // let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
      let m_id = generateRandomString(8);
      let chatId = sessionStorage.getItem("rbyln-chatId");
      // setUserId(user_id, false);
      // setBotTyping(true);
      return {
        org_id: id,
        m_id: m_id,
        // user_id: user_id,
        event_type: event_name,
        chat_id: chatId || "",
      };
    }
    return {};
  };

  const sendMessageToWs = (params: any) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify(params));
    } else {
      console.error("WebSocket is not open. Cannot send message.");
    }
  };

  const sendMessage = async (text: string): Promise<void> => {
    if (text.trim() === "" || !conversationId || botTyping) return;
    setBotTyping(true);
    const newOutMessage: IMessage = {
      text,
      direction: "outgoing",
      ts: new Date().toISOString(),
    };
    console.log("Inside sendMessage Now");

    if (websocket && id) {
      console.log("Inside the send message condition");
      // let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
      let m_id = generateRandomString(8);
      let chatId = sessionStorage.getItem("rbyln-chatId");
      // setUserId(user_id, false);

      sendMessageToWs({
        org_id: id,
        m_id: m_id,
        // user_id: user_id,
        event_type: "user_m",
        chat_id: chatId || "",
        message: text,
      });
    }

    const loadingMessage: IMessage = {
      text: "Loading...",
      direction: "incoming",
      loading: true,
      ts: "",
    };
    // setMessages((prevMessages) => [
    //   ...prevMessages,
    //   newOutMessage,
    //   loadingMessage,
    // ]);

    setMessages((prevMessages) => [
      ...prevMessages,
      newOutMessage,
      loadingMessage,
    ]);
    setInputValue("");
  };

  const addLoadingMessage = () => {
    const loadingMessage: IMessage = {
      text: "Loading...",
      direction: "incoming",
      loading: true,
      ts: "",
    };
    // setMessages((prevMessages) => [
    //   ...prevMessages,
    //   newOutMessage,
    //   loadingMessage,
    // ]);

    setMessages((prevMessages) => [...prevMessages, loadingMessage]);
  };

  const handleMessage = (message: WebSocketMessage) => {
    console.log("message.m_id from message", message.m_id, lastMid);
    setLastMid(message.m_id || null);
    switch (message.event_type) {
      case "init":
        setBotTyping(false);
        handleInit(message);
        break;
      case "server_m":
      case "workflow_not_found":
        // setBotTyping(false);
        handleServerMessage(message);
        break;
      case "workflow_found":
        // setBotTyping(false);
        handleWorkflowFound(message);
        break;
      case "capture_session_complete":
        // setBotTyping(false);
        handleCaptureSessionComplete();
        break;
      case "u_input":
        // setBotTyping(false);
        handleUserInput(message);
        break;
      case "generate_content":
        // setBotTyping(false);
        handleGenerateContent(message);
        break;
      case "list_workflows_complete":
        handleAutomationList(message);
        break;
      // TODO: Change this to proper event from backend
      case "workflow_inputs_complete":
        // setBotTyping(false);
        handleExecutionFromList(message);
        break;
      case "wf_exec_log":
        if (!message.hasOwnProperty("file_download_info")) {
          setBotTyping(false);
        }
        handleWorkflowExecLog(message);
        break;
      case "dynamic_input":
        setBotTyping(true);
        if (message?.enable_text_box) {
          setBotTyping(false);
        }
        handleServerMessage(message);
        break;
      case "dynamic_input_complete":
        setBotTyping(true);
        handleDynamicInputComplete(message);
        break;
      case "file_download_info":
        // setBotTyping(false);
        handleFileDownload(message);
        break;
      case "chat_history_complete":
        handleChatHistoryComplete(message);
        break;
      case "item_select_complete":
        handleItemSelectComplete(message);
        break;
      case "message_group":
        handleSpecialMessage(message);
        break;
      case "chat_closed":
        handleChatClosedMessage(message);
        break;
      case "csat_form":
        handleCSATformOpen(message);
        break;
      case "submit_csat_form_complete":
        handleCSATSubmitComplete(message);
        break;
      case "submit_images_complete": {
        handleDamageImagePreview(message);
        setIsDamagedImageUploadOpen(false);
        removeActions(messages);
        break;
      }
      default:
        console.log("Unhandled message type:", message.event_type);
    }
    if (message?.enable_text_box) {
      setBotTyping(false);
    }
  };

  // Function to request chat history from the server
  const requestChatHistory = () => {
    if (
      historyPageInfo &&
      historyPageInfo?.has_next &&
      historyPageInfo?.cursor &&
      !isLoadingHistoryRef?.current
    ) {
      setIsLoadingHistory(true);
      isLoadingHistoryRef.current = true;
      const payload = getBasePayload("chat_history");
      const finalPayload = {
        ...payload,
        created_at: historyPageInfo?.cursor,
        page_number: historyPageInfo?.current + 1,
      };
      sendMessageToWs(finalPayload);
    }
  };

  // Function to request chat history from the server
  const recordMessage = (msg: string) => {
    const payload = getBasePayload("record_message");
    const finalPayload = {
      ...payload,
      message: msg,
    };
    sendMessageToWs(finalPayload);
  };

  const handleDamageImagePreview = (message: WebSocketMessage) => {
    const serverMessage: IMessage = {
      text: "",
      direction: "outgoing",
      ts: message?.ts,
      isItemSelectComplete: true,
      msg_formatting: message?.msg_formatting || undefined,
      isDamageProductImage: true,
      imageUrls: message?.image_urls || [],
      m_id: message?.m_id,
    };
    setMessages((prevMessages) => {
      const updatedMessages = removeLoadingMessage(prevMessages, serverMessage);
      return [...updatedMessages];
    });
  };

  // Function to handle the chat history response from the server
  const handleChatHistoryComplete = (message: WebSocketMessage) => {
    if (message.history_info?.history) {
      const historyMessages = message.history_info?.history
        .filter((history) =>
          allowedHistoryEvents?.includes(history?.data?.event_type)
        )
        .reverse()
        .flatMap((history) => {
          if (history.data?.messages) {
            return history.data?.messages?.map((messageItems) => {
              return convertToIMessage(
                messageItems,
                history.sender,
                history?.data?.ts
              );
            });
          } else {
            return convertToIMessage(history.data, history.sender);
          }
        });
      setHistoryPageInfo({
        ...message.history_info?.page_info,
        cursor: message?.ts,
      });
      setMessages((prevMessages) => [...historyMessages, ...prevMessages]);
      setIsLoadingHistory(false);
      isLoadingHistoryRef.current = false;
    }
  };

  const handleItemSelectComplete = (message: WebSocketMessage) => {
    if (message.message) {
      const serverMessage: IMessage = {
        text: message.message,
        direction: "outgoing", // THIS IS DONE TO ASSIST AVATAR DISPLAY LOGIC
        ts: message?.ts,
        isItemSelectComplete: true,
        msg_formatting: message?.msg_formatting || undefined,
        m_id: message?.m_id,
      };
      setMessages((prevMessages) => {
        const updatedMessages = removeLoadingMessage(
          prevMessages,
          serverMessage
        );
        return [...updatedMessages];
      });
    }
    // setBotTyping(false);
  };

  const removeActions = (messages: IMessage[]) => {
    setMessages((prevMessages) => {
      const foundIndex = prevMessages?.findIndex(
        (message) => message?.actions && message?.actions?.length > 0
      );
      console.log("foundIndex", foundIndex);
      // console.log("Found index message:", {
      //   slicedMessages: prevMessages.slice(foundIndex + 1, prevMessages.length),
      //   fullMessages: prevMessages,
      // });
      if (foundIndex > -1) {
        return [
          ...prevMessages?.slice(0, foundIndex),
          ...prevMessages?.slice(
            Math.min(foundIndex + 1, prevMessages.length - 1),
            prevMessages.length
          ),
        ];
      } else {
        return prevMessages;
      }
    });
  };

  const handleSpecialMessage = (message: WebSocketMessage) => {
    if (message.messages) {
      console.log("message.messages", message.messages);
      let hasBlockingMessage = true;
      let serverMessageWActions: IMessage;
      const messages = message?.messages?.map((messageItems) => {
        const serverMessage: IMessage = {
          text: messageItems.message || "",
          direction: "incoming",
          ts: message?.ts,
          msg_formatting: isFormattedMessage(messageItems?.msg_formatting)
            ? messageItems?.msg_formatting
            : undefined,
          formattingData: isFormattedMessage(messageItems?.msg_formatting)
            ? messageItems?.message
            : undefined,
          m_id: messageItems?.m_id || "",
        };
        if (isBlocking(messageItems?.msg_formatting)) {
          hasBlockingMessage = true;
        } else {
          hasBlockingMessage = false;
        }
        return serverMessage;
      });
      if (message?.actions && message?.actions?.length > 0) {
        serverMessageWActions = {
          text: "",
          direction: "outgoing",
          ts: message?.ts,
          actions: message?.actions,
        };
      }

      setMessages((prevMessages) => {
        const updatedMessages = removeLoadingMessage(prevMessages);
        return serverMessageWActions
          ? [...updatedMessages, ...messages, serverMessageWActions]
          : [...updatedMessages, ...messages];
      });
      // if (!hasBlockingMessage) {
      //   setBotTyping(false);
      // }
    }
  };

  const convertToIMessage = (
    message: WebSocketMessage,
    sender: "SERVER" | "USER",
    ts?: string
  ): IMessage => {
    const inProgressStatusList = [
      "NOT_RUNNING",
      "RUNNING",
      "STOPPING",
      "PREPARING",
      "QUEUED",
    ];

    return {
      text: message.message || "",
      direction:
        sender === "SERVER" &&
        message?.msg_formatting !== MessageFormattingTypes.STATUS_CHANGE
          ? message?.event_type === "submit_images_complete"
            ? "outgoing"
            : "incoming"
          : "outgoing",
      ts: ts || message.ts,
      m_id: message.m_id,
      inputs: message.inputs,
      contextId: message.context_id,
      workflow: message.workflow,
      status: message.status,
      video_url: message.video_url,
      input_payload: message.input_payload,
      exec_id: message.exec_id,
      file_info:
        message?.event_type !== "wf_exec_log"
          ? message.file_download_info
          : undefined,
      isForm:
        message?.event_type === "wf_exec_log" ||
        message?.event_type === "workflow_found",
      isHistory:
        message?.status && inProgressStatusList?.includes(message?.status)
          ? false
          : true,
      hideExecution: message?.event_type === "workflow_found",
      msg_formatting: isFormattedMessage(message?.msg_formatting) || undefined,
      formattingData: isFormattedMessage(message?.msg_formatting)
        ? message?.message
        : undefined,
      isItemSelectComplete: message?.event_type === "item_select_complete",
      isDamageProductImage: message?.event_type === "submit_images_complete",
      imageUrls: message?.image_urls || null,
      isChatClosed: message?.event_type === "chat_closed" ? true : false,
    };
  };

  const handleInit = (message: WebSocketMessage) => {
    const chatId = message.chat_id;
    sessionStorage.setItem("rbyln-chatId", chatId);
    if (message.history_info?.history) {
      const historyMessages = message.history_info?.history
        .filter((history) =>
          allowedHistoryEvents?.includes(history?.data?.event_type)
        )
        .reverse()
        .flatMap((history) => {
          if (history.data?.messages) {
            return history.data?.messages?.map((messageItems) => {
              return convertToIMessage(
                messageItems,
                history.sender,
                history?.data?.ts
              );
            });
          } else {
            return convertToIMessage(history.data, history.sender);
          }
        });
      setMessages((prevMessages) => [...prevMessages, ...historyMessages]);
      setHistoryPageInfo({
        ...message.history_info?.page_info,
        cursor: message?.ts,
      });
    }
    if (message.message) {
      const serverMessage: IMessage = {
        text: message.message,
        direction: "incoming",
        ts: message?.ts,
        m_id: message?.m_id || undefined,
      };
      setMessages((prevMessages) => {
        console.log("prevMessages in init===>", prevMessages);
        console.log(
          "prevmessages",
          prevMessages,
          prevMessages?.[prevMessages?.length - 1] &&
            prevMessages?.[prevMessages?.length - 1]?.loading
        );
        if (
          prevMessages?.length > 0 &&
          prevMessages?.[prevMessages?.length - 1] &&
          prevMessages?.[prevMessages?.length - 1]?.loading
        ) {
          const updatedMessages = removeLoadingMessage(
            prevMessages,
            serverMessage
          );
          return [...updatedMessages];
        }
        return [...prevMessages, serverMessage];
      });
      setChatClosed(false);
      setCsatComplete(false);
      setChatInited(true);
    }
  };

  const handleServerMessage = (message: WebSocketMessage) => {
    if (
      (message?.msg_formatting === MessageFormattingTypes.BUTTON ||
        message?.msg_formatting === MessageFormattingTypes.TEXT) &&
      message?.message?.length === 0
    ) {
      setMessages((prevMessages) => {
        const updatedMessages = removeLoadingMessage(prevMessages);
        return [...updatedMessages];
      });
      return;
    }
    if (message.message) {
      const serverMessage: IMessage = {
        text: typeof message.message === "string" ? message.message : "",
        direction: "incoming",
        ts: message?.ts,
        msg_formatting:
          isFormattedMessage(message?.msg_formatting) || undefined,
        formattingData: isFormattedMessage(message?.msg_formatting)
          ? message?.message
          : undefined,
        input_id: message?.input_id,
        m_id: message?.m_id,
      };
      setMessages((prevMessages) => {
        const updatedMessages = removeLoadingMessage(
          prevMessages,
          serverMessage
        );
        return [...updatedMessages];
      });
    } else {
      setMessages((prevMessages) => {
        const updatedMessages = removeLoadingMessage(prevMessages);
        return [...updatedMessages];
      });
    }
  };

  const handleChatClosedMessage = (message: WebSocketMessage) => {
    if (message) {
      // const serverMessage: IMessage = {
      //   text: typeof message.message === "string" ? message.message : "",
      //   direction: "incoming",
      //   ts: message?.ts,
      //   msg_formatting:
      //     isFormattedMessage(message?.msg_formatting) || undefined,
      //   formattingData: isFormattedMessage(message?.msg_formatting)
      //     ? message?.message
      //     : undefined,
      //   input_id: message?.input_id,
      //   m_id: message?.m_id,
      //   isChatClosed: true,
      // };
      // setMessages((prevMessages) => {
      //   const updatedMessages = removeLoadingMessage(
      //     prevMessages,
      //     serverMessage
      //   );
      //   return [...updatedMessages];
      // });
      setChatClosed(true);
    }
  };

  const handleCSATSubmitComplete = (message: WebSocketMessage) => {
    if (message) {
      setCsatComplete(true);
    }
  };
  const handleCSATformOpen = (message: WebSocketMessage) => {
    if (message) {
      if (message?.csat_form?.template) {
        setCsatDetails(message?.csat_form?.template);
        setISCSATModalOpen(true);
      }
    }
  };

  const handleWorkflowFound = (message: WebSocketMessage) => {
    const serverMessage: IMessage = {
      text: "",
      m_id: message?.m_id || "",
      direction: "incoming",
      isForm: true,
      inputs: message.inputs?.inputs,
      contextId: message?.context_id,
      workflow: message?.workflow,
      ts: message?.ts,
    };
    setMessages((prevMessages) => {
      const updatedMessages = removeLoadingMessage(prevMessages, serverMessage);
      return [...updatedMessages];
    });
    setWorkflowContextId(message.context_id || "");
    setAuthcomplete(message?.workflow?.workflow?.is_auth_complete ?? false);
    setCurrentWorkflow(message?.workflow?.workflow);
    currentWorkflowRef.current = message?.workflow?.workflow;
  };

  const handleCaptureSessionComplete = () => {
    setTimeout(() => {
      setAuthcomplete(true);
    }, 2000);
  };

  const handleUserInput = (message: WebSocketMessage) => {
    const serverMessage: IMessage = {
      m_id: message?.m_id || "",
      text: message?.message || "",
      direction: "incoming",
      isForm: true,
      inputs: message.inputs?.inputs,
      contextId: message.context_id,
      ts: message?.ts,
    };
    setInputMessage(serverMessage);
  };

  const handleGenerateContent = (message: WebSocketMessage) => {
    if (message && message?.generated_content) {
      setRephrasedValues(message?.generated_content);
    }
  };

  const handleAutomationList = (message: WebSocketMessage) => {
    if (message && message?.workflows && message?.page_info) {
      setAutomationList({
        data: message?.workflows,
        page_info: message?.page_info,
        meta_info: message?.meta_info,
      });
    }
  };

  const handleExecutionFromList = (message: WebSocketMessage) => {
    const serverMessage: IMessage = {
      m_id: message?.m_id || "",
      text: message?.message || "",
      direction: "incoming",
      isForm: true,
      isFromList: true,
      inputs: message.inputs?.inputs,
      contextId: message.context_id,
      workflow:
        message?.workflow ||
        (currentWorkflowRef?.current
          ? { workflow: currentWorkflowRef?.current }
          : undefined),
      ts: message?.ts,
    };
    setInputMessage(serverMessage);
    setWorkflowContextId(message.context_id || "");
    if (!message?.inputs && currentWorkflowRef?.current) {
      // onRunDirectWorkflow();
      setTriggerDirectRun(true);
      setOpenAllAutomations(false);
      resetAutomationList();
      setTimeout(() => {
        setMessages((prevMessages) => {
          const updatedMessages = removeLoadingMessage(
            prevMessages,
            serverMessage
          );
          return [...updatedMessages];
        });
      }, 2000);
    } else {
      setOpenAllAutomations(false);
      setShowInputForm(true);
    }
  };

  const handleWorkflowExecLog = (message: WebSocketMessage) => {
    setBotTyping(true);
    setAutomationRunning(true);
    if (message?.exec_id) {
      setExecId(message?.exec_id);
    }
    if (message.context_id && message?.status) {
      findAndUpdateWorkflowStatus(message.context_id, message?.status);
      if (
        message?.status === WorkflowStatusEnum.SUCCESS ||
        message?.status === WorkflowStatusEnum.FAILED
      ) {
        setAutomationRunning(false);
        // setBotTyping(false);
      }
    }
    if (message.context_id && message?.video_url) {
      findAndUpdateWorkflowVideo(message.context_id, message?.video_url);
    }
    if (message.context_id && message?.live_stream_url) {
      findAndUpdateWorkflowStream(message.context_id, message?.live_stream_url);
    }
    if (message.context_id && message?.end_state_info) {
      findAndUpdateEndStateInfo(message.context_id, message?.end_state_info);
    }
    // setBotTyping(false);
    setInputMessage(undefined);
    setRephrasedValues(undefined);
  };

  const handleCSATFormSubmit = (
    formData: { [key: string]: string },
    ratingMap?: { [key: string]: number }
  ) => {
    const defaultValues: { [key: string]: string | number | null } = {
      TEXTFIELD: null, // Default for text fields
      RATING: 0, // Default for rating
    };
    const formResponses = csatDetails?.form_fields?.fields.map((input) => {
      const fieldId = input.field_id;
      let fieldValue;

      // Assign value based on input type
      if (input.field_type === "TEXTFIELD") {
        fieldValue = formData[fieldId] || defaultValues[input.field_type];
      } else if (input.field_type === "RATING" && input.field_sub_type) {
        fieldValue = ratingMap?.[fieldId] || defaultValues[input.field_type];
      }

      return {
        field_id: fieldId,
        is_primary: input?.is_primary, // Assuming it's always false, change as needed
        field_value: fieldValue,
      };
    });
    console.log("Form Data:", formData);
    console.log("Rating:", ratingMap);
    const payload = getBasePayload("submit_csat_form");
    const csatresponse = {
      form_data: {
        template_id: csatDetails?.template_id,
        form_responses: formResponses,
      },
    };
    sendMessageToWs({ ...payload, ...csatresponse });
    // Submit to API or perform actions here
    setISCSATModalOpen(false); // Close the modal after submission
  };

  const handleDynamicInput = (message: WebSocketMessage) => {
    lastSaveIndexRef.current = {
      ...messagesRef?.current?.[messagesRef?.current?.length - 1],
    };
    // const serverMessage1: IMessage = {
    //   text: message?.message || "",
    //   direction: "incoming",
    //   input_id: message?.input_id,
    //   exec_id: message?.exec_id,
    //   input_payload: message.input_payload,
    //   contextId: message?.context_id,
    //   workflow: message?.workflow,
    //   isDynamicContent: true,
    //   dynamicInputType: DynamicInputType?.DATA,
    //   ts: message?.ts,
    // };
    // const serverMessage2: IMessage = {
    //   text: message?.message || "",
    //   direction: "incoming",
    //   input_id: message?.input_id,
    //   exec_id: message?.exec_id,
    //   input_payload: message.input_payload,
    //   contextId: message?.context_id,
    //   workflow: message?.workflow,
    //   isDynamicContent: true,
    //   dynamicInputType: DynamicInputType?.BUTTON,
    //   ts: message?.ts,
    // };

    const serverMessage: IMessage = {
      text: message?.message || "",
      direction: "outgoing", // THIS IS DONE TO ASSIST AVATAR DISPLAY LOGIC
      ts: message?.ts,
      isDynamicContent: true,
      msg_formatting: message?.msg_formatting || undefined,
    };

    setMessages((prevMessages) => {
      let updatedMessages = removeLoadingMessage(prevMessages, serverMessage);

      return [...updatedMessages];
    });
  };

  const handleDynamicInputComplete = (message: WebSocketMessage) => {
    if (message.message) {
      const serverMessage: IMessage = {
        text: message.message,
        direction: "outgoing", // THIS IS DONE TO ASSIST AVATAR DISPLAY LOGIC
        ts: message?.ts,
        m_id: message?.m_id,
      };

      setMessages((prevMessages) => {
        const loadingMessage: IMessage = {
          text: "Loading...",
          direction: "incoming",
          loading: true,
          ts: "",
        };
        const updatedMessages = removeLoadingMessage(
          prevMessages,
          serverMessage
        );
        return [...updatedMessages, loadingMessage];
      });
    }
    // setBotTyping(false);
  };

  const handleFileDownload = (message: WebSocketMessage) => {
    if (message?.file_download_info) {
      const serverMessage1: IMessage = {
        text: message?.file_download_info?.message_to_show || "",
        m_id: message?.m_id || "",
        direction: "incoming",
        isForm: false,
        ts: message?.ts,
      };
      const serverMessage2: IMessage = {
        text: "",
        m_id: message?.m_id || "",
        direction: "incoming",
        file_info: message?.file_download_info,
        ts: message?.ts,
      };
      setMessages((prevMessages) => {
        const updatedMessages = removeLoadingMessage(
          prevMessages,
          serverMessage1
        );
        return [...updatedMessages, serverMessage2];
      });
    }
  };

  const findAndUpdateWorkflowStatus = (
    contextId: string,
    status: WorkflowStatusEnum
  ) => {
    setWorkflowStatusMap((prev) => ({ ...prev, [contextId]: { status } }));
  };

  const findAndUpdateWorkflowVideo = (contextId: string, video_url: string) => {
    setWorkflowVideoMap((prev) => ({
      ...prev,
      [contextId]: { video_url },
    }));
  };

  const findAndUpdateWorkflowStream = (
    contextId: string,
    live_stream_url: string
  ) => {
    setWorkflowStreamMap((prev) => ({
      ...prev,
      [contextId]: { live_stream_url },
    }));
  };

  const findAndUpdateEndStateInfo = (contextId: string, info: any) => {
    setEndStateInfo((prev: any) => ({ ...prev, [contextId]: { ...info } }));
  };

  const resetSessionVariables = () => {
    setSessionDataSuccess(false);
    setSessionDataError(false);
    setOpenAuthScreen(false);
  };

  const sendDynamicInputMessage = ({
    title,
    click_value,
    input_id,
  }: {
    title: string;
    click_value: string;
    input_id: string;
  }) => {
    console.log("sendDynamicInputMessage", title, click_value);
    const basePayload = getBasePayload("dynamic_input");
    const outputId = {
      output_payload: {
        title: title,
        click_value: click_value,
      },
    };
    const payload = { ...basePayload, ...outputId, input_id };
    sendMessageToWs(payload);
    setBotTyping(true);
  };

  const startNewWs = async () => {
    let ws: WebSocket | null = null;
    const socketUrl = wsUrl || "";
    if (!socketUrl) {
      return;
    }
    ws = new WebSocket(socketUrl);
    try {
      const token = isPlugin
        ? await getAuthTokenFromPlugin()
        : await getAuthToken();
      ws.onopen = () => {
        if (ws) {
          // let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
          let m_id = generateRandomString(8);
          // setUserId(user_id, false);

          ws.send(
            JSON.stringify({
              m_id: m_id,
              // user_id: user_id,
              org_id: id,
              auth_token: token,
              event_type: "init",
            })
          );
        }
      };
    } catch (error) {}

    ws.onerror = (error) => {
      console.error("WebSocket error", error);
    };

    ws.onclose = (e) => {
      console.log("WebSocket connection closed", e);
    };

    ws.onmessage = (event) => {
      const message: WebSocketMessage = JSON.parse(event.data);
      handleMessage(message);
    };

    setWebsocket(ws);
    websocketRef.current = ws;
    return () => {
      if (ws) ws.close();
    };
  };

  const onRun = (message: IMessage, skipAuth?: boolean) => {
    setRunFromList(false);
    if (!message?.workflow?.workflow?.requires_inputs) {
      if (!message?.workflow?.workflow?.is_auth_complete && !skipAuth) {
        setOpenAuthScreen(true);
        return;
      }
      if (websocket && id) {
        // let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
        let m_id = generateRandomString(8);
        let chatId = sessionStorage.getItem("rbyln-chatId");
        // setUserId(user_id, false);
        setBotTyping(true);

        findAndUpdateWorkflowStatus(
          inputMessage?.contextId || "",
          WorkflowStatusEnum?.PREPARING
        );

        sendMessageToWs({
          org_id: id,
          m_id: m_id,
          // user_id: user_id,
          event_type: "execute",
          chat_id: chatId || "",
        });
        setShowInputForm(false);
        resetAutomationList();
      }
      return;
    }
    setShowInputForm(true);
  };
  console.log("botyping====>", botTyping);
  const onRunDirectWorkflow = (
    workflow: AllAutomationWorkflow | Workflow,
    skipAuth?: boolean
  ) => {
    if (workflow) {
      if (websocketRef.current && id) {
        // let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
        let m_id = generateRandomString(8);
        let chatId = sessionStorage.getItem("rbyln-chatId");
        // setUserId(user_id, false);
        setBotTyping(true);

        findAndUpdateWorkflowStatus(
          inputMessage?.contextId || "",
          WorkflowStatusEnum?.PREPARING
        );

        sendMessageToWs({
          org_id: id,
          m_id: m_id,
          // user_id: user_id,
          user_inputs: {},
          event_type: "execute",
          chat_id: chatId || "",
        });
        resetAutomationList();
      }
      return;
    }
  };

  const onConfirmLeaveForm = () => {
    if (runFromList) {
      setMessages((prev) => {
        const messagesWithoutLoading = removeLoadingMessage(prev);
        let updatedMessages = messagesWithoutLoading.slice(0, -1);
        messagesRef.current = updatedMessages;
        return updatedMessages;
      });
      resetAutomationList();
      getAllAutomations();
      setOpenAllAutomations(true);
    }
    setInputMessage(undefined);
    setShowInputForm(false);
    setShowLeaveAlert(false);
  };

  const onRunFromAllAutomations = async (workflow: AllAutomationWorkflow) => {
    const loadingMessage: IMessage = {
      text: "Loading...",
      direction: "incoming",
      loading: true,
      ts: "",
    };

    setMessages((prev) => [
      ...prev,
      {
        text: workflow?.title || "",
        direction: "outgoing",
        ts: "",
      },
      loadingMessage,
    ]);

    setRunFromList(true);
    return new Promise<void>((resolve, reject) => {
      if (!workflow?.requires_inputs) {
        setTimeout(() => {
          let payload: any = getBasePayload("workflow_inputs");
          if (workflow) {
            payload = { ...payload, workflow_id: workflow?.workflow_id };
          }
          sendMessageToWs(payload);
          recordMessage(workflow?.title || "");
          setCurrentWorkflow(workflow);
          currentWorkflowRef.current = workflow;
          resolve();
        }, 500);
      } else {
        setTimeout(() => {
          // setOpenAllAutomations(false);
          let payload: any = getBasePayload("workflow_inputs");
          if (workflow) {
            payload = { ...payload, workflow_id: workflow?.workflow_id };
          }
          sendMessageToWs(payload);
          setCurrentWorkflow(workflow);
          currentWorkflowRef.current = workflow;
          setAuthcomplete(workflow?.is_auth_complete ?? false);
          // This needs separated out
          // setMessages((prev) => [
          //   ...prev,
          //   {
          //     text: workflow?.title || "",
          //     direction: "outgoing",
          //   },
          //   loadingMessage,
          // ]);
          // addLoadingMessage();
          setTimeout(() => {
            // removeLoadingMessage(messages, {
            //   text: workflow?.title || "",
            //   direction: "outgoing",
            // });
            resolve();
          }, 500);
        }, 500);
      }
    });
  };

  console.log("automationList in sdk", automationList);

  const clearChat = () => {
    localStorage?.removeItem("cnvId");
    if (conversationId) localStorage?.removeItem(conversationId);
    setMessages([]);
    websocket?.close();
    setChatInited(false);
    startNewWs();
  };
  console.log("botTyping || !chatInited", botTyping, !chatInited);

  const handleResize = () => {
    console.log("Window resized");
    if (window.innerWidth > 0 && windowWidth === 0) {
      setIsWindowOpened(true);
    }
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  useEffect(() => {
    if (window.innerWidth > 0) {
      setIsWindowOpened(true);
    }
  }, []);

  // console.log("All messages are", messages);

  const shouldRenderMessageAvatar = (message: IMessage, index: number) => {
    if (
      !!config?.brand_logo_url &&
      message?.direction === "incoming" &&
      !message.isItemSelectComplete &&
      (index === messages.length - 1 ||
        messages[index + 1].direction !== message.direction ||
        !isSameDay(message?.ts, messages[index + 1]?.ts)) &&
      (!message?.msg_formatting ||
        message?.msg_formatting === MessageFormattingTypes.BUTTON ||
        message?.msg_formatting === MessageFormattingTypes.CAROUSEL)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isSameDay = (date1: string, date2: string) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const handleCurrentAction = (currentAction: ActionType) => {
    console.log("Current action selected is", currentAction);
    setCurrentOrderId(
      currentAction?.params?.find((param) => param?.param_key === "order_id")
    );
    switch (currentAction?.action_name) {
      case "images_not_available": {
        let m_id = generateRandomString(8);
        const newOutMessage: IMessage = {
          text: currentAction?.action_display,
          direction: "outgoing",
          ts: new Date().toISOString(),
        };
        sendMessageToWs({
          org_id: id,
          m_id: m_id,
          event_type: "user_m",
          // chat_id: chatId || "",
          message: currentAction?.action_display,
        });
        setMessages((prevMessages) => [...prevMessages, newOutMessage]);
        m_id = generateRandomString(8);
        sendMessageToWs({
          event_type: "record_message",
          m_id: m_id,
          org_id: "30e4fab6-cadb-4b99-b1e7-30fca6e147ac",
          message: currentAction?.action_display,
        });
        // sendMessage(currentAction?.action_display);
        m_id = generateRandomString(8);
        sendMessageToWs({
          event_type: "images_not_available",
          m_id: m_id,
          org_id: "30e4fab6-cadb-4b99-b1e7-30fca6e147ac",
          message: currentAction?.action_display,
        });
        removeActions(messages);
        break;
      }
      case "submit_images": {
        setIsDamagedImageUploadOpen(true);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (organization?.config?.show_all_automations) {
      setShowAllAutomationCta(organization?.config?.show_all_automations);
    }
  }, [organization?.config?.show_all_automations]);

  const msgListRef = useRef(null);

  const handleScrollArrowClick = (behavior?: "auto" | "smooth") =>
    (msgListRef?.current as any).scrollToBottom(behavior);

  const [inputFocussed, setInputFocussed] = useState(false);
  console.log("inputFocussed", inputFocussed);
  // useEffect(() => {
  //   if (msgListRef.current) {
  //     msgListRef?.current.
  //   }
  // }, [msgListRef]);

  console.log(
    "mid lastest",
    lastMid,
    messages[messages.length - 1]?.m_id,
    lastMid !== messages[messages.length - 1]?.m_id
  );

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        boxShadow: "0px 0px 40px 0px rgba(14, 14, 15, 0.24)",
      }}
      className="rounded-[1rem] bg-transparent  bg-paper overflow-x-hidden"
    >
      <div>
        {/* <button
          onClick={() => setISCSATModalOpen(true)}
          className="btn btn-primary"
        >
          Open Feedback Modal
        </button> */}

        {/* ChatbotCSATModal Component */}
        <ChatbotCSATModal
          template={csatDetails}
          isOpen={isCSATModalOpen}
          onClose={() => setISCSATModalOpen(false)}
          onSubmit={handleCSATFormSubmit}
        />
      </div>
      <MainContainer
        // style={{ margin: "0 auto" }}
        className="min-w-[400px] max-w-[800px] !shadow-none !border-none !bg-transparent relative"
      >
        {/* <SrcollToBottomBtn onClick={handleScrollArrowClick} /> */}
        <ChatContainer className="relative">
          <ConversationHeader
            className="px-4 !py-4  h-16 !border-0"
            style={{
              backgroundColor: config?.colors?.title_bar_color || "#fff",
              color: config?.colors?.title_bar_color
                ? getBestFontColor(config?.colors?.title_bar_color, 0.5)
                : "",
            }}
          >
            <ConversationHeader.Content
              className="!bg-transparent"
              // userName={config?.display_name || "Robylon Bot"}
            >
              <div className="flex flex-row items-center gap-2">
                <Avatar
                  src={config?.brand_logo_url}
                  className="!w-7 !h-7 !min-w-7 !min-h-7"
                />
                <div className="flex flex-col">
                  <div
                    className="text-base font-semibold"
                    style={{
                      color: config?.colors?.title_bar_color
                        ? getBestFontColor(config?.colors?.title_bar_color, 0.5)
                        : "",
                    }}
                  >
                    {config?.name || "Robylon Bot"}
                  </div>
                  {!!config?.tagline && (
                    <div
                      className="text-xs opacity-65"
                      style={{
                        color: config?.colors?.title_bar_color
                          ? getBestFontColor(
                              config?.colors?.title_bar_color,
                              0.5
                            )
                          : "",
                      }}
                    >
                      {config?.tagline}
                    </div>
                  )}
                </div>
                {/* {showRefresh && <RefreshButton onClick={clearChat} />} */}
              </div>
            </ConversationHeader.Content>
            <ConversationHeader.Actions>
              {showRefresh && (
                <RefreshButton
                  onClick={clearChat}
                  color={
                    config?.colors?.title_bar_color
                      ? getBestFontColor(config?.colors?.title_bar_color, 0.5)
                      : ""
                  }
                />
              )}
              {/* {!openSettingsScreen && (
                <SettingsButton
                  onClick={() => {
                    setOpenSettingsScreen(true);
                  }}
                />
              )} */}
              {/* {!isWindowExpanded && (
                <ExpandButton
                  onClick={() => {
                    toggleWindowSize();
                  }}
                />
              )} */}
              {isWindowExpanded && (
                <CollapseButton
                  onClick={() => {
                    toggleWindowSize();
                  }}
                />
              )}
              {showClose && onClose && (
                <CloseButton
                  color={
                    config?.colors?.title_bar_color
                      ? getBestFontColor(config?.colors?.title_bar_color, 0.5)
                      : ""
                  }
                  onClick={() => {
                    if (openSettingsScreen) {
                      setOpenSettingsScreen(false);
                      return;
                    }
                    websocket?.close();
                    onClose();
                  }}
                />
              )}
            </ConversationHeader.Actions>
          </ConversationHeader>
          {(!chatInited || !isWindowOpened) && (
            <MessageList>
              <>
                <div className="flex flex-col items-center justify-center h-full  min-h-[70vh]">
                  {/* <div>Initializing Chatbot...</div> */}
                  <Player
                    autoplay
                    src={Loader}
                    loop={true}
                    style={{ height: "32px", width: "32px" }}
                  ></Player>
                </div>
              </>
            </MessageList>
          )}

          {/* Check index.css as well. .cs-message-list .cs-message-list__scroll-wrapper. Change mt-[0px] to mt-[5px] there */}
          {!!chatInited && !!isWindowOpened && (
            <MessageList
              ref={msgListRef}
              className={showAllAutomationCta ? "mt-10" : ""}
              onYReachStart={requestChatHistory}
              autoScrollToBottom={true}
              autoScrollToBottomOnMount={true}
            >
              {isLoadingHistory && (
                <div className="flex justify-center py-10">
                  <Player
                    autoplay
                    loop
                    src={Loader}
                    style={{ height: "32px", width: "32px" }}
                  ></Player>
                </div>
              )}
              {chatInited && showAllAutomationCta && (
                <div
                  onClick={() => {
                    getAllAutomations();
                    setOpenAllAutomations(true);
                  }}
                  style={{
                    color: config?.colors?.brand_color
                      ? isLowContrastColor(config?.colors?.brand_color, 0.5)
                        ? "#0E0E0F"
                        : config?.colors?.brand_color
                      : "",
                  }}
                  className={`cursor-pointer  border-y border-gray-200 pl-5 pr-4 py-2.5 ${
                    !config?.colors?.brand_color ? "text-blue-500" : ""
                  } text-center flex items-center justify-center fixed w-full top-[62px] bg-white z-50 hover:bg-gray-50 active:bg-gray-100 active:opacity-70`}
                >
                  <div className="text-sm font-semibold">All Automations</div>
                  <div
                    style={{
                      color: config?.colors?.brand_color
                        ? isLowContrastColor(config?.colors?.brand_color, 0.5)
                          ? "#0E0E0F"
                          : config?.colors?.brand_color
                        : "",
                    }}
                    className={`text-xs font-normal w-6 h-6 ${
                      !config?.colors?.brand_color ? "text-blue-500" : ""
                    }`}
                  >
                    <CaretRight />
                  </div>
                </div>
              )}
              {chatInited && (
                <div className="p-4 pb-6 w-full">
                  {messages?.map((message, index) => {
                    const currentDate = new Date(
                      message?.ts
                    ).toLocaleDateString();
                    const previousDate =
                      index > 0
                        ? new Date(messages[index - 1]?.ts).toLocaleDateString()
                        : null;
                    console.log(
                      "currentDate, previousDate",
                      currentDate,
                      previousDate
                    );
                    const isCurrentDateValid = !isNaN(
                      new Date(message.ts).getTime()
                    );
                    const isPreviousDateValid =
                      index > 0 &&
                      !isNaN(new Date(messages[index - 1]?.ts).getTime());

                    const shouldShowTimelineSeparation =
                      index === 0 ||
                      (isCurrentDateValid &&
                        isPreviousDateValid &&
                        currentDate !== previousDate);
                    console.log("message?.ts", message?.ts);
                    const tooltipTime = message?.ts
                      ? formatDateTooltipTime(message?.ts)
                      : "";
                    console.log(
                      "message?.msg_formatting",
                      message?.msg_formatting
                    );
                    return (
                      <>
                        {shouldShowTimelineSeparation && (
                          <TimelineSeparation
                            date={new Date(message?.ts).toISOString()}
                          />
                        )}
                        {message?.msg_formatting ===
                          MessageFormattingTypes?.STATUS_CHANGE && (
                          <StatusChangeSeparation text={message?.text} />
                        )}

                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "flex-end",
                            marginLeft: !shouldRenderMessageAvatar(
                              message,
                              index
                            )
                              ? "44px"
                              : "",
                            maxWidth: "100%",
                          }}
                        >
                          {shouldRenderMessageAvatar(message, index) && (
                            <div className="pb-3">
                              <Avatar
                                src={config?.brand_logo_url}
                                className="!w-7 !h-7 !min-w-7 !min-h-7"
                              />
                            </div>
                          )}
                          {/* {!message?.isChatClosed && ( */}
                          <Message
                            className={`relative ${
                              messages[index - 1]?.direction !==
                              message?.direction
                                ? "!mt-4"
                                : "mt-2"
                            } ${
                              message?.msg_formatting ? "has-formatting" : ""
                            } ${
                              message?.msg_formatting ===
                              MessageFormattingTypes.CAROUSEL
                                ? "!-ml-[56px]"
                                : ""
                            }`}
                            bubbleStyle={
                              message.direction === "outgoing" &&
                              config?.colors?.brand_color &&
                              (!message?.actions ||
                                message?.actions?.length === 0) &&
                              !message?.isDamageProductImage
                                ? {
                                    backgroundColor:
                                      config?.colors?.brand_color,
                                  }
                                : message?.isForm ||
                                  message?.file_info ||
                                  message?.msg_formatting ||
                                  message?.actions?.length ||
                                  message?.isDamageProductImage
                                ? {
                                    padding: message?.msg_formatting
                                      ? "1px"
                                      : 0,
                                    backgroundColor: "transparent",
                                    borderRadius: "8px",
                                    maxWidth:
                                      message?.msg_formatting ===
                                      MessageFormattingTypes.CAROUSEL
                                        ? "300%"
                                        : "100%",
                                  }
                                : {}
                            }
                            model={{
                              sentTime: "just now",
                              sender:
                                message.direction === "incoming"
                                  ? "Other"
                                  : "You",
                              direction: message.direction,
                              position: "single",
                            }}
                            key={index}
                          >
                            <Message.CustomContent>
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <div
                                      className={`message-custom-div text-start ${
                                        message?.isForm ||
                                        message?.file_info ||
                                        message?.isDynamicContent ||
                                        message?.msg_formatting ||
                                        message?.actions?.length ||
                                        message?.isDamageProductImage
                                          ? "!p-0"
                                          : ""
                                      } ${
                                        message?.msg_formatting
                                          ? "!bg-transparent"
                                          : ""
                                      }`}
                                      style={{
                                        color:
                                          message?.direction === "outgoing"
                                            ? config?.colors?.brand_color
                                              ? getBestFontColor(
                                                  config?.colors?.brand_color,
                                                  0.5
                                                )
                                              : ""
                                            : "",
                                      }}
                                    >
                                      {message.loading ? (
                                        <Player
                                          autoplay
                                          loop={true}
                                          src={BotTypingAnimation}
                                          // style={{ height: "48px", width: "48px" }}
                                        ></Player>
                                      ) : !message?.isForm &&
                                        !message?.msg_formatting ? (
                                        <span
                                          className="text-sm"
                                          dangerouslySetInnerHTML={{
                                            __html: sanitizeAndModifyLinks(
                                              message?.text
                                            ),
                                          }}
                                          onClick={(e) =>
                                            handleLinkClick(
                                              e as unknown as MouseEvent
                                            )
                                          }
                                        ></span>
                                      ) : null}
                                      {message?.isForm && (
                                        <ExecutionState
                                          hideExecutionState={
                                            message?.hideExecution
                                          }
                                          workflowNum={
                                            message?.workflow?.workflow
                                              ?.workflow_num
                                          }
                                          executionState={
                                            message?.isHistory
                                              ? message?.status
                                              : workflowStatusMap?.[
                                                  message.contextId || ""
                                                ]?.status || undefined
                                          }
                                          isDisabled={message?.isDisabled}
                                          config={config}
                                          end_state_info={
                                            endStateInfo?.[
                                              message?.contextId || ""
                                            ]
                                          }
                                          streamLink={
                                            workflowStreamMap?.[
                                              message?.contextId || ""
                                            ]?.live_stream_url || undefined
                                          }
                                          videoLink={
                                            message?.isHistory &&
                                            message?.video_url
                                              ? message?.video_url
                                              : workflowVideoMap?.[
                                                  message?.contextId || ""
                                                ]?.video_url || ""
                                          }
                                          onCancel={() => {
                                            findAndUpdateWorkflowStatus(
                                              message?.contextId || "",
                                              WorkflowStatusEnum?.CANCELLED
                                            );
                                          }}
                                          isCancelled={
                                            workflowStatusMap?.[
                                              message.contextId || ""
                                            ]?.status ===
                                            WorkflowStatusEnum.CANCELLED
                                          }
                                          onRun={() => {
                                            onRun(message);
                                          }}
                                          workflowMsg={
                                            message?.text ||
                                            message?.workflow?.workflow
                                              ?.title ||
                                            ""
                                          }
                                          isError={
                                            workflowStatusMap?.[
                                              message.contextId || ""
                                            ]?.status ===
                                            WorkflowStatusEnum.FAILED
                                          }
                                          isLoading={
                                            workflowStatusMap?.[
                                              message.contextId || ""
                                            ]?.status ===
                                              WorkflowStatusEnum.PREPARING ||
                                            workflowStatusMap?.[
                                              message.contextId || ""
                                            ]?.status ===
                                              WorkflowStatusEnum.RUNNING
                                          }
                                          isSuccess={
                                            workflowStatusMap?.[
                                              message.contextId || ""
                                            ]?.status ===
                                            WorkflowStatusEnum.SUCCESS
                                          }
                                        />
                                      )}
                                      {/* {message?.isDynamicContent &&
                                        message?.msg_formatting ===
                                          MessageFormattingTypes.BUTTON && (
                                          <DynamicInputDataToShow
                                            data={
                                              message?.input_payload
                                                ?.data_to_show
                                            }
                                          />
                                        )} */}
                                      {message?.msg_formatting ===
                                        MessageFormattingTypes.BUTTON && (
                                        <DynamicInputButtons
                                          isCompleted={false}
                                          data={message.formattingData}
                                          onClick={sendDynamicInputMessage}
                                          exec_id={message?.exec_id}
                                          input_id={message?.input_id}
                                          config={config}
                                          isDisabled={
                                            message?.isHistory ||
                                            lastMid !== message?.m_id
                                          }
                                        />
                                      )}
                                      {message?.msg_formatting ===
                                      MessageFormattingTypes.CAROUSEL ? (
                                        <DynamicInputCarousel
                                          isCompleted={false}
                                          data={message.formattingData}
                                          onClick={sendDynamicInputMessage}
                                          exec_id={message?.exec_id}
                                          input_id={message?.input_id}
                                          config={config}
                                          isDisabled={
                                            message?.isHistory ||
                                            lastMid !== message?.m_id
                                          }
                                        />
                                      ) : null}
                                      {message?.msg_formatting ===
                                        MessageFormattingTypes.PRODUCT_LIST && (
                                        <ViewCardList
                                          products={
                                            message?.formattingData as ViewCardProduct[]
                                          }
                                          initialDisplayCount={3}
                                        />
                                      )}

                                      {message?.msg_formatting ===
                                        MessageFormattingTypes.PRODUCT_LIST_SELECT &&
                                        !message?.isHistory && (
                                          <SelectableCardList
                                            onSelectionChange={(
                                              selectedIds: string[] | number[]
                                            ) => {
                                              const payload =
                                                getBasePayload("item_select");
                                              let finalPayload: any = {
                                                ...payload,
                                              };
                                              finalPayload.item_type =
                                                "order_selection";
                                              finalPayload.item_info = {
                                                order_id: selectedIds[0],
                                                order_name: (
                                                  message?.formattingData as SelectableCardProduct[]
                                                )?.find((data) => {
                                                  return (
                                                    data?.order_id ===
                                                    selectedIds[0]
                                                  );
                                                })?.title,
                                              };
                                              removeActions(messages);
                                              sendMessageToWs(finalPayload);
                                            }}
                                            selectionMode="single"
                                            products={
                                              message?.formattingData as SelectableCardProduct[]
                                            }
                                            initialDisplayCount={3}
                                          />
                                        )}
                                      {message?.msg_formatting ===
                                        MessageFormattingTypes.PRODUCT_LIST_SELECT &&
                                        message?.isHistory && (
                                          <ViewCardList
                                            products={
                                              message?.formattingData as ViewCardProduct[]
                                            }
                                            initialDisplayCount={3}
                                          />
                                        )}

                                      {message?.msg_formatting ===
                                        MessageFormattingTypes.IMAGES && (
                                        <DamageImageChatPreview
                                          imageUrls={
                                            (message?.formattingData as any) ||
                                            []
                                          }
                                        />
                                      )}

                                      {message?.actions &&
                                        message?.actions.length > 0 && (
                                          <ActionChips
                                            chipActions={message?.actions}
                                            handleClickChip={(currentAction) =>
                                              handleCurrentAction(currentAction)
                                            }
                                          />
                                        )}
                                      {message?.isDamageProductImage &&
                                        message?.imageUrls?.length && (
                                          <DamageImageChatPreview
                                            imageUrls={message?.imageUrls}
                                          />
                                        )}

                                      {message?.file_info && (
                                        <div className="flex flex-row justify-between w-full">
                                          {message?.file_info?.file_type ===
                                            "CSV" && (
                                            <div className="flex flex-row justify-between w-full items-center border-gray-300 border rounded-[12px] bg-paper px-3 py-4">
                                              <div className="flex flex-row gap-3 items-center">
                                                <div className="p-2 bg-gray-100 rounded-lg">
                                                  <div className="w-6 h-6">
                                                    <CSVFileIcon />
                                                  </div>
                                                </div>
                                                <div className="flex flex-col">
                                                  <div className="h-0 invisible">
                                                    Successfully generated
                                                    output file for the
                                                    automation!
                                                  </div>
                                                  <div className="text-sm text-gray-900">
                                                    {
                                                      message?.file_info
                                                        ?.file_name
                                                    }
                                                  </div>
                                                  <div className="text-xs text-gray-900">
                                                    {message?.file_info?.size}
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="w-8 h-8 cursor-pointer"
                                                onClick={() => {
                                                  if (message?.file_info?.url) {
                                                    downloadFile(
                                                      message?.file_info?.url,
                                                      message?.file_info
                                                        ?.file_name
                                                    );
                                                  }
                                                }}
                                              >
                                                <DownloadIcon />
                                              </div>
                                            </div>
                                          )}
                                          {message?.file_info?.file_type ===
                                            "GOOGLE_SHEETS" && (
                                            <div
                                              className="flex flex-row justify-between w-full items-center text-sm py-2 px-3 rounded-[12px]  cursor-pointer"
                                              style={{
                                                color:
                                                  config?.colors?.brand_color ||
                                                  "",
                                                backgroundColor: config?.colors
                                                  ?.brand_color
                                                  ? hexToRgbA(
                                                      config?.colors
                                                        ?.brand_color,
                                                      0.08
                                                    )
                                                  : "",
                                              }}
                                              onClick={() => {
                                                window.open(
                                                  message?.file_info?.url,
                                                  "_blank"
                                                );
                                              }}
                                            >
                                              <div className="flex flex-col">
                                                <div className="h-0 invisible">
                                                  Successfully generated output
                                                  file for the automation!
                                                </div>
                                                <div>View in Google Sheets</div>
                                              </div>
                                              <div
                                                className="w-5 h-5"
                                                style={{
                                                  color:
                                                    config?.colors
                                                      ?.brand_color || "",
                                                }}
                                              >
                                                <ExternalLinkIcon />
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {/* {message?.direction === "incoming" && (
                                        <RedirectLinksContainer />
                                      )} */}
                                    </div>
                                  </TooltipTrigger>
                                  {message?.msg_formatting !==
                                    MessageFormattingTypes.BUTTON && (
                                    <TooltipContent sideOffset={-4}>
                                      <p>{tooltipTime || ""}</p>
                                    </TooltipContent>
                                  )}
                                </Tooltip>
                              </TooltipProvider>
                            </Message.CustomContent>
                          </Message>
                          {/* )} */}
                        </div>

                        {index === messages.length - 1 &&
                          message?.msg_formatting !==
                            MessageFormattingTypes?.STATUS_CHANGE &&
                          !message.isChatClosed &&
                          !message?.loading &&
                          message?.ts && (
                            <Message.Footer
                              style={{
                                marginLeft:
                                  message?.direction === "incoming"
                                    ? "0"
                                    : "auto",
                                paddingLeft:
                                  message?.direction === "incoming"
                                    ? "48px"
                                    : "auto",
                                justifyContent:
                                  message?.direction === "incoming"
                                    ? "start"
                                    : "end",
                                marginTop:
                                  message?.msg_formatting ===
                                  MessageFormattingTypes.CAROUSEL
                                    ? "-35px"
                                    : "",
                              }}
                            >
                              <div className="text-xs">
                                {message?.ts
                                  ? formatMessageTimestamp(message?.ts)
                                  : ""}
                              </div>
                            </Message.Footer>
                          )}

                        {index === messages.length - 2 &&
                          messages[messages.length - 1]?.loading &&
                          message?.direction === "outgoing" &&
                          message?.ts && (
                            <Message.Footer
                              style={{
                                marginLeft: "auto",
                                paddingLeft: "auto",
                                justifyContent: "end",
                                marginTop:
                                  message?.msg_formatting ===
                                  MessageFormattingTypes.CAROUSEL
                                    ? "-35px"
                                    : "",
                              }}
                            >
                              <div className="text-xs">
                                {message?.ts
                                  ? formatMessageTimestamp(message?.ts)
                                  : ""}
                              </div>
                            </Message.Footer>
                          )}
                        {chatClosed && index === messages.length - 1 && (
                          <ChatClosedSeparation />
                        )}
                        {csatComplete && index === messages.length - 1 && (
                          <CsatCompleteSeparation />
                        )}
                      </>
                    );
                  })}
                  {/* <DamagedProductOptionButton
                    setIsDamagedImageUploadOpen={setIsDamagedImageUploadOpen}
                  /> */}
                </div>
              )}
            </MessageList>
          )}

          {!chatClosed && (
            <MessageInput
              onFocus={() => {
                setInputFocussed(true);
              }}
              onBlur={() => {
                setInputFocussed(false);
              }}
              // onFocus={(e) => {
              //   console.log("inputRef?.current", inputRef?.current, e);
              //   // if (inputRef?.current) {
              //   //   inputRef.current.style.borderColor = "red" || "";
              //   // }
              // }}
              style={{
                border:
                  inputFocussed && !(botTyping || !chatInited)
                    ? `1px solid ${config?.colors?.brand_color}`
                    : "",
              }}
              className={`!border !border-solid ${
                !inputFocussed
                  ? "hover:!border-inkAlpha-300 !border-inkAlpha-100"
                  : ""
              } focus:border-transparent`}
              sendButtonComponent={
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center px-1.5 py-[7px] ${
                    !config?.colors?.brand_color && !botTyping
                      ? "bg-robylon-blue "
                      : botTyping || !chatInited
                      ? "!bg-gray-300 text-paper"
                      : ""
                  } ${!inputFocussed ? "bg-gray-300 text-paper" : ""}`}
                  style={{
                    color:
                      botTyping || !chatInited
                        ? ""
                        : !botTyping && config?.colors?.brand_color
                        ? getBestFontColor(config?.colors?.brand_color)
                        : "",
                    background:
                      !botTyping && inputFocussed
                        ? config?.colors?.brand_color
                        : "",
                  }}
                >
                  <div className="w-4 h-4">
                    <SendMessageIcon />
                  </div>
                </div>
                // <img
                //   src={
                //     config?.org_name &&
                //     idToIconMap[config?.org_name?.toLowerCase()]
                //       ? idToIconMap[config?.org_name?.toLowerCase()]
                //       : {ReactComponent as SendMessageIcon}
                //   }
                //   width={32}
                //   height={32}
                //   alt="send"
                // />
              }
              attachButton={false}
              placeholder={
                automationRunning
                  ? "1 Automation Running..."
                  : "Write something here..."
              }
              disabled={botTyping || !chatInited || automationRunning}
              value={inputValue}
              onChange={setInputValue}
              onSend={(innerHtml, textContent, innerText) => {
                sendMessage(innerText);
              }}
            />
          )}
          {chatClosed && (
            <button
              as={MessageInput}
              className="grow text-ssm border border-solid rounded-[99px] px-5 py-2 h-10  hover:opacity-90 mx-4 font-semibold mb-5"
              style={{
                color: config?.colors?.brand_color
                  ? isLowContrastColor(config?.colors?.brand_color, 0.5)
                    ? "#0E0E0F"
                    : config?.colors?.brand_color
                  : "",
                borderColor: config?.colors?.brand_color,
              }}
              onClick={clearChat}
            >
              {!chatInited && (
                <Player
                  autoplay
                  src={Loader}
                  loop={true}
                  style={{ height: "14px", width: "14px" }}
                ></Player>
              )}
              {!!chatInited && `Report a New Issue`}
            </button>
          )}
        </ChatContainer>

        <div
          className={`absolute border-0 top-0 bottom-0 left-0 right-0 ${
            showInputForm ||
            openAuthScreen ||
            openSettingsScreen ||
            openAllAutomations ||
            isDamagedImageUploadOpen
              ? "visible z-10"
              : "z-0 invisible"
          }`}
        >
          <ChatContainer>
            <ConversationHeader
              className="px-4 !py-4  h-16 !border-0"
              style={{
                backgroundColor: config?.colors?.title_bar_color || "#fff",
                color: config?.colors?.title_bar_color
                  ? getBestFontColor(config?.colors?.title_bar_color, 0.5)
                  : "",
              }}
            >
              <ConversationHeader.Back className="form-back">
                <div
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => {
                    if (openSettingsScreen) {
                      setOpenSettingsScreen(false);
                      return;
                    }
                    if (openAllAutomations) {
                      setOpenAllAutomations(false);
                      resetAutomationList();
                      return;
                    }
                    if (showInputForm) {
                      setShowLeaveAlert(true);
                    }
                    if (isDamagedImageUploadOpen) {
                      setIsDamagedImageUploadOpen(false);
                      return;
                    }
                    // setShowInputForm(false);
                  }}
                  style={{
                    color: config?.colors?.title_bar_color
                      ? getBestFontColor(config?.colors?.title_bar_color, 0.5)
                      : "",
                  }}
                >
                  <BackIcon />
                </div>
              </ConversationHeader.Back>
              {/* <ConversationHeader.Content
                userName={
                  openSettingsScreen ? "Copilot Settings" : "Inputs Required"
                }
                // info={messages[messages?.length - 1]?.workflow?.workflow?.title}
              /> */}
              <ConversationHeader.Content
                className="!bg-transparent"
                // userName={config?.display_name || "Robylon Bot"}
              >
                <div className="flex flex-row items-center gap-2">
                  <div className="text-base font-semibold">
                    {!!openSettingsScreen && "Copilot Settings"}
                    {!!openAllAutomations && "All Automations"}
                    {!openSettingsScreen &&
                      !openAllAutomations &&
                      !isDamagedImageUploadOpen &&
                      "Inputs Required"}
                    {!!isDamagedImageUploadOpen && "Received Damaged Product"}
                  </div>
                  {/* {showRefresh && <RefreshButton onClick={clearChat} />} */}
                </div>
              </ConversationHeader.Content>

              <ConversationHeader.Actions>
                {/* {showRefresh && <RefreshButton onClick={clearChat} />} */}
                {/* {!openSettingsScreen && (
                  <SettingsButton
                    onClick={() => {
                      setOpenSettingsScreen(true);
                    }}
                  />
                )} */}
                {/* {!isWindowExpanded && (
                  <ExpandButton
                    onClick={() => {
                      toggleWindowSize();
                    }}
                  />
                )} */}
                {isWindowExpanded && (
                  <CollapseButton
                    onClick={() => {
                      toggleWindowSize();
                    }}
                  />
                )}
                {showClose && onClose && (
                  <CloseButton
                    color={
                      config?.colors?.title_bar_color
                        ? getBestFontColor(config?.colors?.title_bar_color, 0.5)
                        : ""
                    }
                    onClick={() => {
                      if (openSettingsScreen) {
                        setOpenSettingsScreen(false);
                        return;
                      }

                      websocket?.close();
                      onClose();
                    }}
                  />
                )}
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList
              // onYReachStart={() => {
              //   alert();
              // }}
              // onYReachEnd={() => {}}
              autoScrollToBottom={false}
              scrollBehavior="smooth"
              className={`${
                showInputForm ? "pb-[73px]" : ""
              } input-message-list`}
            >
              <MessageList.Content>
                {showInputForm && (
                  <div className="flex flex-col gap-2">
                    <div className="p-4 px-5 border-b border-b-gray-200 fixed top-[60px] w-full z-[2000] bg-paper  h-[96px] overflow-hidden">
                      <div className={`text-xs text-gray-800 mb-1`}>
                        Automation #
                        {currentWorkflowRef?.current?.workflow_num || "..."}
                      </div>
                      <div className={` font-semibold text-sm text-ink`}>
                        {truncateWords(
                          currentWorkflowRef?.current?.title || "",
                          80
                        )}
                      </div>
                    </div>
                    <div>
                      <AutomationInputForm
                        onConfirmLeave={onConfirmLeaveForm}
                        showAlert={showLeaveAlert}
                        setShowAlert={setShowLeaveAlert}
                        currentWorkflow={currentWorkflow}
                        sendMessageToWs={sendMessageToWs}
                        getBasePayload={getBasePayload}
                        websocket={websocket || undefined}
                        // showAskSession={false}
                        showAskSession={false} // !authComplete}
                        askSessionProps={{
                          onClick: requestData,
                          success: sessionDataSuccess,
                          error: sessionDataError,
                          loading: sessionDataLoading,
                        }}
                        rephraseResponse={rephrasedValues}
                        onRephrase={(values) => {
                          if (websocket && id) {
                            // let user_id =
                            //   user?.user_id ||
                            //   localStorage?.getItem("user_id") ||
                            //   "";
                            let m_id = generateRandomString(8);
                            let chatId = sessionStorage.getItem("rbyln-chatId");
                            // setUserId(user_id, false);
                            sendMessageToWs({
                              org_id: id,
                              m_id: m_id,
                              // user_id: user_id,
                              event_type: "generate_content",
                              chat_id: chatId || "",
                              user_inputs: values,
                            });
                          }
                        }}
                        config={config}
                        onCancel={() => {
                          setShowLeaveAlert(true);
                          // if (runFromList) {
                          //   setMessages((prev) => {
                          //     const messagesWithoutLoading =
                          //       removeLoadingMessage(prev);
                          //     let updatedMessages = messagesWithoutLoading.slice(
                          //       0,
                          //       -1
                          //     );
                          //     messagesRef.current = updatedMessages;
                          //     return updatedMessages;
                          //   });
                          //   setCurrentWorkflow(undefined);
                          //   currentWorkflowRef.current = undefined;
                          //   resetAutomationList();
                          //   getAllAutomations();
                          //   setOpenAllAutomations(true);
                          // }
                          // setShowInputForm(false);
                        }}
                        workflowMsg={inputMessage?.text || ""}
                        inputs={inputMessage?.inputs || []}
                        onSubmit={(values) => {
                          console.log(
                            "websocket && id on Submit",
                            websocket && id
                          );
                          if (websocket && id) {
                            // let user_id =
                            //   user?.user_id ||
                            //   localStorage?.getItem("user_id") ||
                            //   "";
                            let m_id = generateRandomString(8);
                            let chatId = sessionStorage.getItem("rbyln-chatId");
                            // setUserId(user_id, false);

                            setBotTyping(true);

                            findAndUpdateWorkflowStatus(
                              inputMessage?.contextId || "",
                              WorkflowStatusEnum?.PREPARING
                            );
                            if (runFromList) {
                              recordMessage(
                                currentWorkflowRef?.current?.title || ""
                              );
                            }

                            sendMessageToWs({
                              org_id: id,
                              m_id: m_id,
                              // user_id: user_id,
                              event_type: "execute",
                              chat_id: chatId || "",
                              user_inputs: values,
                            });
                            resetAutomationList();
                            setShowInputForm(false);

                            if (runFromList && inputMessage) {
                              setTimeout(() => {
                                setMessages((prevMessages) => {
                                  const updatedMessages = removeLoadingMessage(
                                    prevMessages,
                                    inputMessage
                                  );
                                  return [...updatedMessages];
                                });
                              }, 2000);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                {openAuthScreen && (
                  <AskSessionStandalone
                    askSessionProps={{
                      onClick: requestData,
                      success: sessionDataSuccess,
                      error: sessionDataError,
                      loading: sessionDataLoading,
                      brandColor: config?.colors?.brand_color,
                    }}
                  />
                )}
                {openSettingsScreen && (
                  <SettingsPage
                    askSessionProps={{
                      onClick: requestData,
                      success: sessionDataSuccess,
                      error: sessionDataError,
                      loading: sessionDataLoading,
                      brandColor: config?.colors?.brand_color,
                    }}
                  />
                )}
                {openAllAutomations && (
                  <AllAutomations
                    onRun={onRunFromAllAutomations}
                    onAddBookmark={onAddBookmark}
                    automationList={automationList}
                    onTagSelect={(tags: string[]) => {
                      getAllAutomations(tags);
                    }}
                  />
                )}

                {isDamagedImageUploadOpen && (
                  <DamageProductImageUploadpage
                    sendMessageToWs={sendMessageToWs}
                    websocket={websocket}
                    orderId={currentOrderId?.param_value || ""}
                  />
                )}
              </MessageList.Content>
            </MessageList>
          </ChatContainer>
        </div>
      </MainContainer>
    </div>
  );
};

export default ChatbotUIClientSdk;
