import React from "react";

type Props = {
  text: string | React.ReactNode;
};

const InputLabel = ({ text }: Props) => {
  return (
    <label className="block text-center text-ink text-ssm mb-4">{text}</label>
  );
};

export default InputLabel;
