import React, { useState } from "react";
import CheckGreen from "assets/images/check-green.svg";
import Warning from "assets/images/warning-icon.svg";
import CaretRight from "assets/images/caret-right.svg";
import { InputPayload } from "components/ChatbotUIClient";
import Carousel, { Order } from "components/Carousel/Carousel";

interface DynamicInputButtonsProps {
  config?: any;
  data?: Order[];
  onClick: (params: {
    title: string;
    click_value: string;
    input_id: string;
  }) => void;
  exec_id?: string;
  input_id?: string;
  isCompleted: boolean;
  isDisabled?: boolean;
}

const DynamicInputCarousel: React.FC<DynamicInputButtonsProps> = ({
  data,
  onClick,
  exec_id,
  input_id,
  isCompleted,
  config,
  isDisabled,
}) => {
  console.log("Buttons", data, config);
  return (
    <div className="p-2 rounded-lg w-[386px] bg-transparent">
      {!!data?.length && (
        <div className="flex flex-col gap-1 align-center">
          <Carousel
            isDisabled={isDisabled}
            input_id={input_id}
            data={data}
            imageHeight="h-[144px]"
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default DynamicInputCarousel;
