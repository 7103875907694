import React, { useEffect, useRef, useState } from "react";

interface PopoverProps {
  children: React.ReactNode;
  show: boolean;
  targetRef: React.RefObject<HTMLElement>;
  maxWidth?: string;
}

const Popover: React.FC<PopoverProps> = ({
  children,
  show,
  targetRef,
  maxWidth = "400px", // Default maxWidth if not provided
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
    arrowLeft: "50%",
    arrowDirection: "bottom",
    popoverWidth: "auto",
  });

  // Helper function to get the closest parent with relative positioning
  const getRelativeParent = (
    element: HTMLElement | null
  ): HTMLElement | null => {
    if (!element) return null;
    const position = window.getComputedStyle(element).position;
    if (position === "relative" || position === "absolute") return element;
    return getRelativeParent(element.parentElement);
  };

  useEffect(() => {
    if (show && targetRef.current && popoverRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const relativeParent = getRelativeParent(targetRef.current);
      const parentRect = relativeParent
        ? relativeParent.getBoundingClientRect()
        : document.body.getBoundingClientRect();

      // Calculate available space
      const availableSpaceRight =
        parentRect.width - (targetRect.left - parentRect.left);
      const availableSpaceLeft = targetRect.left - parentRect.left;

      let popoverWidth = Math.min(
        parseInt(maxWidth, 10),
        availableSpaceLeft + availableSpaceRight
      );
      popoverWidth = Math.max(popoverWidth, targetRect.width); // Ensure popover doesn't shrink below the button width

      // Recalculate left position based on adjusted width
      let left =
        targetRect.left -
        parentRect.left +
        targetRect.width / 2 -
        popoverWidth / 2;

      // Ensure popover fits within the parent's bounds horizontally
      if (left < 0) {
        left = 0;
      }
      if (left + popoverWidth > parentRect.width) {
        left = parentRect.width - popoverWidth;
      }

      const arrowLeft = `${
        targetRect.left - parentRect.left + targetRect.width / 2 - left
      }px`;

      // Check if there's enough space below the button
      const fitsBelow =
        targetRect.bottom + popoverRef.current.offsetHeight + 8 <=
        window.innerHeight;
      if (!fitsBelow) {
        // If not enough space below, position above the button
        const top =
          targetRect.top - parentRect.top - popoverRef.current.offsetHeight - 8;
        setPosition({
          top,
          left,
          arrowLeft,
          arrowDirection: "top",
          popoverWidth: `${popoverWidth}px`,
        });
      } else {
        // Position below the button
        const top = targetRect.bottom - parentRect.top + 8;
        setPosition({
          top,
          left,
          arrowLeft,
          arrowDirection: "bottom",
          popoverWidth: `${popoverWidth}px`,
        });
      }
    }
  }, [show, targetRef, maxWidth]);

  return show ? (
    <div
      ref={popoverRef}
      className="absolute bg-black text-white text-xs rounded px-2 py-1 z-50"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        maxWidth: position.popoverWidth,
      }}
    >
      {children}
      <div
        className={`absolute ${
          position.arrowDirection === "bottom" ? "bottom-full" : "top-full"
        }`}
        style={{ left: position.arrowLeft }}
      >
        <div
          className={`w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent ${
            position.arrowDirection === "bottom"
              ? "border-b-4 border-b-black"
              : " border-t-4 border-t-black "
          }`}
        />
      </div>
    </div>
  ) : null;
};

export default Popover;
