// components/ChatbotCSATModal.tsx

import CSATForm from "components/CSATForm/CSATForm";
import BottomSheet from "components/Library/BottomSheet";
import React, { useState } from "react";

export enum RatingType {
  STAR = "STAR",
  EMOJI = "EMOJI",
}

export enum FormFieldType {
  TEXTFIELD = "TEXTFIELD",
  RATING = "RATING",
}

// Interfaces
export interface CSATFormField {
  field_id: string;
  field_label: string;
  field_type: FormFieldType;
  field_sub_type: RatingType | null;
  is_primary: boolean;
}

export interface CSATFormTemplate {
  template_id: string;
  org_id: string;
  name: string;
  description: string;
  form_fields: {
    fields: CSATFormField[];
  };
  created_by_name: string;
}

interface ChatbotCSATModalProps {
  isOpen: boolean;
  onClose: () => void;
  template?: CSATFormTemplate | null;
  onSubmit: (
    formData: { [key: string]: string },
    ratingMap?: { [key: string]: number },
    templateId?: string
  ) => void;
}

const ChatbotCSATModal: React.FC<ChatbotCSATModalProps> = ({
  isOpen,
  onClose,
  template,
  onSubmit,
}) => {
  // if (!isOpen) return null;

  const inputs = template?.form_fields?.fields?.map((field) => ({
    field_id: field.field_id,
    field_label: field.field_label,
    field_type: field.field_type,
    field_sub_type: field.field_sub_type,
    placeholder: field.is_primary ? "Describe your experience" : "",
  }));

  return (
    <BottomSheet isVisible={isOpen} title="" onClose={onClose}>
      {!!inputs && (
        <CSATForm
          inputs={inputs}
          submitButtonText="Submit"
          onSubmit={onSubmit}
          templateId={template?.template_id}
        />
      )}
    </BottomSheet>
  );
};

export default ChatbotCSATModal;
