import React, { useState } from "react";
import CardItem, { ViewCardProduct } from "./CardItem";
import ShowMore from "./ShowMore";

interface ViewCardListProps {
  products: ViewCardProduct[];
  initialDisplayCount: number;
}

const ViewCardList: React.FC<ViewCardListProps> = ({
  products,
  initialDisplayCount,
}) => {
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [hoveredCardId, setHoveredCardId] = useState<number | string | null>(
    null
  );

  const handleShowMore = () => {
    setDisplayCount((prevCount) => Math.min(prevCount + 3, products.length));
  };

  return (
    <div className=" rounded-lg  overflow-hidden border border-gray-400 bg-white w-[320px] md:max-w-[400px] md:w-[400px]">
      <div className="">
        {products.slice(0, displayCount).map((product) => (
          <CardItem
            key={product.order_id}
            product={product}
            isHovered={hoveredCardId === product.order_id}
            onHover={() => setHoveredCardId(product.order_id)}
            onLeave={() => setHoveredCardId(null)}
          />
        ))}
      </div>
      {initialDisplayCount < products.length && (
        <div className="px-4 py-2 flex flex-row items-center justify-between border-t border-gray-200">
          <p className="text-xs text-gray-800 py-2 grow">
            {displayCount} of {products.length} Products
          </p>
          {displayCount < products.length && (
            <ShowMore
              onShowLess={() => setDisplayCount(initialDisplayCount)}
              onShowMore={handleShowMore}
              remainingCount={products.length - displayCount}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ViewCardList;
