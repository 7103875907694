import React, { useState, useEffect, useRef, useMemo } from "react";
import Fuse from "fuse.js";
import TaskList from "./TaskList";
import FilterTags, { FilterTag } from "./FilterTags";
import SearchBar from "./SearchBar";
import { AllAutomationWorkflow } from "components/TaskItem";
import { fetchMockTasks } from "utils/mockData";
import {
  AutomationList,
  MetaInfo,
  Workflow,
} from "components/ChatbotUIClientSdk";
import ExecutionStateStatus, {
  WorkflowStatusEnum,
} from "components/ExecutionStateStatus";
import { Player } from "@lottiefiles/react-lottie-player";
import Loader from "assets/lotties/loader-light-animation.json";
import DarkLoader from "assets/lotties/loader-dark-animation.json";

interface Props {
  onAddBookmark: (id: string) => void;
  automationList: AutomationList;
  onTagSelect: (tags: string[]) => void;
  onRun: (workflow: AllAutomationWorkflow) => Promise<void>;
}

const AllAutomations: React.FC<Props> = ({
  onAddBookmark,
  automationList,
  onTagSelect,
  onRun,
}) => {
  const [tasks, setTasks] = useState<AllAutomationWorkflow[]>();
  const [metaInfo, setMetaInfo] = useState<MetaInfo>();
  const [filters, setFilters] = useState<FilterTag[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[] | null>(null);

  useEffect(() => {
    if (automationList) {
      setTasks(automationList?.data);
    }
    if (automationList?.meta_info) {
      setMetaInfo(automationList?.meta_info);
    }
    if (automationList?.page_info?.tag_counter) {
      setFilters(
        Object.entries(automationList?.page_info?.tag_counter)
          .map(([k, v]) => ({ name: k, id: v }))
          .sort((a, b) => {
            if (a.name === "FAVOURITE") return -1;
            if (b.name === "FAVOURITE") return 1;
            return a.name.localeCompare(b.name);
          })
      );
    }
  }, [automationList]);
  console.log("automationList===>", tasks, metaInfo);

  // const fetchMoreTasks = async () => {
  //   if (isLoading || !hasMore) return;
  //   setIsLoading(true);
  //   try {
  //     const newTasks = await fetchMockTasks(tasks?.length, 10);
  //     setTasks((prevTasks) => [
  //       ...(prevTasks || []),
  //       ...(newTasks as AllAutomationWorkflow[]),
  //     ]);
  //     if ((newTasks as AllAutomationWorkflow[]).length < 10) setHasMore(false);
  //   } catch (error) {
  //     console.error("Error fetching more tasks", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleScroll = () => {
  //   if (!containerRef.current) return;

  //   const container = containerRef.current;
  //   if (
  //     container.scrollHeight - container.scrollTop <=
  //       container.clientHeight + 100 &&
  //     !isLoading
  //   ) {
  //     // fetchMoreTasks();
  //   }
  // };

  const handleBookmark = (id: string, bookmarked: boolean) => {
    console.log("handleBookmark id", id);
    setTasks((prevTasks) =>
      prevTasks?.map((task) =>
        task.workflow_id === id ? { ...task, favourite: bookmarked } : task
      )
    );
    onAddBookmark(id);
  };

  const onSelectFilter = (name: string) => {
    setSelectedFilters((prev) => {
      const newFilters = prev ? [...prev] : [];
      const index = newFilters.indexOf(name);
      if (index !== -1) {
        newFilters.splice(index, 1);
      } else {
        newFilters.push(name);
      }
      onTagSelect(newFilters);
      setTasks(undefined);
      return newFilters;
    });
  };

  // Fuse.js options for fuzzy search
  const fuseOptions = useMemo(
    () => ({
      keys: ["title"], // Adjust based on your data structure
      threshold: 0.3, // Adjust the threshold as needed
    }),
    []
  );

  // Fuse instance, memoized to only recreate when tasks change
  const fuse = useMemo(
    () => new Fuse(tasks || [], fuseOptions),
    [tasks, fuseOptions]
  );

  let filteredTasks = searchQuery
    ? fuse.search(searchQuery).map((result) => result.item)
    : tasks;

  useEffect(() => {
    return () => {
      setMetaInfo(undefined);
      setTasks(undefined);
    };
  }, []);

  return (
    <div ref={containerRef} className="">
      {!!metaInfo && !metaInfo?.can_trigger_wf && (
        <div className="flex flex-row items-center md:justify-start bg-gray-50 px-5 py-4 gap-3 border-b border-b-gray-300">
          <div className="flex flex-row items-center gap-1 grow md:grow-0 whitespace-nowrap">
            <Player
              autoplay
              src={Loader}
              loop={true}
              style={{ height: "16px", width: "16px" }}
            ></Player>
            <div className="text-gray-800 font-normal text-xs">In-Progress</div>
          </div>
          <div className="bg-gray-300 w-[1px] h-4"></div>
          <div className="truncate grow text-xs text-ink">
            {metaInfo?.running_wf?.wf_info?.title || ""}
          </div>
        </div>
      )}
      <div className="px-4 py-4  min-h-[90vh]">
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

        <FilterTags
          filters={filters}
          onSelectFilter={onSelectFilter}
          selectedFilters={selectedFilters || []}
        />
        {!!filteredTasks && (
          <>
            <TaskList
              favouriteFilterApplied={selectedFilters?.includes("FAVOURITE")}
              tasks={filteredTasks || []} // Use filteredTasks here
              onBookmark={handleBookmark}
              onRun={onRun}
              canRun={metaInfo && metaInfo?.can_trigger_wf ? true : false}
            />
          </>
        )}
        {!filteredTasks && (
          <div className="flex flex-col items-center justify-center h-full  min-h-[70vh]">
            <Player
              autoplay
              src={Loader}
              loop={true}
              style={{ height: "80px", width: "80px" }}
            ></Player>
          </div>
        )}
        {isLoading && <p>Loading more tasks...</p>}
      </div>
    </div>
  );
};

export default AllAutomations;
