import React, { useEffect } from "react";
import { ReactComponent as CloseIcon } from "assets/images/close-icon-dynamic-color.svg";

interface AuthModalProps {
  googleAuthUrl?: string;
  modalIsOpen: boolean;
  closeModal: () => void;
  authWindowId: string | null;
  title?: string;
}

const AuthModal: React.FC<AuthModalProps> = ({
  googleAuthUrl,
  modalIsOpen,
  closeModal,
  authWindowId,
}) => {
  useEffect(() => {
    if (modalIsOpen && authWindowId) {
      const authWindow = window.open(
        googleAuthUrl,
        "Google Auth",
        "width=500,height=600"
      );

      const authCheckInterval = setInterval(() => {
        if (authWindow && authWindow.closed) {
          clearInterval(authCheckInterval);
          closeModal();
        }
      }, 1000);
    }
  }, [modalIsOpen, googleAuthUrl, closeModal, authWindowId]);

  return (
    <>
      {modalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-200 auth-modal z-[1000000]">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full sm:max-w-lg mx-8 py-4">
            <div className="px-4  flex flex-row justify-between">
              <div className="flex justify-end grow">
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700 text-lg"
                >
                  <div className="w-6 h-6 text-gray-800">
                    <CloseIcon />
                  </div>
                </button>
              </div>
            </div>
            <div className="p-4 text-sm">
              <p>Please complete the authentication in the new window.</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthModal;
