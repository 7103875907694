import axios, { AxiosResponse } from "axios";
import { del, get, post, put } from "request";

interface CopilotData {
  user: {
    token: string;
    user_info: { [key: string]: any };
    org_info: Organization;
    ws_url: string;
  };
}

let token: any =
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5MDY1ODc3LCJpYXQiOjE3MTgyMDE4NzcsImp0aSI6IjMyOGU0ZTEwNDNiMDRjMGVhYjE3ZGU5NTQyY2VmNDUxIiwidXNlcl9pZCI6Ijc4NmI3MmQxLTYyMjEtNDZlNC1hNDM0LWQ2YjllY2FkZDg4ZSJ9.qvIdW2NRQuEI7EILiK1LTVgdpRPFDVKqoCRjcWpf2GQ" ||
  undefined;
let copilotData: CopilotData | undefined = undefined;

const getAuthTokenFromBackend = async (data: any) => {
  // try {
  //   const reponse = await post("/users/auth/copilot/", data, {
  //     viaOauth: false,
  //   });
  //   token = reponse?.data?.user?.token || undefined;
  //   if (token) return token;
  //   throw new Error();
  // } catch (e) {
  //   throw e;
  // }
  return new Promise<string>((resolve, reject) => {
    function handleAuthToken(event: MessageEvent) {
      console.log("event?.data?.type=====>", event?.data?.type);
      // if (event.origin !== window.location.origin) {
      //   // Ensure the message is coming from the expected origin
      //   return;
      // }

      if (event.data.type === "provideAuthToken") {
        token = event.data.copilotData?.user?.token;
        copilotData = event?.data?.copilotData;
        console.log("copilotData====>", copilotData, token);
        window.removeEventListener("message", handleAuthToken);
        resolve(token);
      }
    }

    window.addEventListener("message", handleAuthToken);

    // Send message to request the auth token
    window.parent.postMessage({ type: "requestAuthToken" }, "*");

    // Set a timeout to reject the promise if the token is not received
    setTimeout(() => {
      window.removeEventListener("message", handleAuthToken);
      reject(new Error("Timeout while waiting for auth token"));
    }, 50000); // 5 seconds timeout
  });
};

export const getAuthToken = async (data?: any) => {
  console.log("getAuthToken ===>", token);

  if (token) {
    return Promise.resolve(token);
  }
  try {
    token = await getAuthTokenFromBackend(data);
    console.log("token=====>", token);
    return token;
  } catch (error) {
    // alert("error");
    console.error("error", error);
    // throw error;
  }
};

export const getCopilotData = async (): Promise<CopilotData> => {
  return new Promise((resolve, reject) => {
    if (copilotData) resolve(copilotData);
    else reject("No copilot data found");
  });
};

// const BASE_URL = "https://chatbot-stage.own.fan";

// const token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzA3MDcxMDA2LCJpYXQiOjE3MDYyMDcwMDYsImp0aSI6ImJjNzU0ZDMwMDMyMDQwY2Q4NmE0ZDZiYTg2NWE3ZDc2IiwidXNlcl9pZCI6ImU0M2M1YWM3LTczYzYtNDU4ZS1hYTYyLWMyZjA3ZjIyNDY0NyJ9.EZQo57um_TNPFYRvwP6wU599AoWafx-kQr97m2VmPQc";

// Define types for your data, for example:

export enum conversation_status {
  CREATED = "CREATED",
  LEAD_PENDING = "LEAD_PENDING",
  LEAD_GENERATED = "LEAD_GENERATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}
interface StartConverstaionResponse {
  // Add more fields as per your data structure
  data: {
    result: {
      conversation_id: string;
      status: conversation_status;
    };
  };
}

interface SubmitChatResponse {
  // Add more fields as per your data structure
  data: {
    result: {
      message: string;
      conversation_status: conversation_status;
    };
  };
}

export enum ConversationSources {
  CHATMASON = "CHATMASON",
  WIDGET_OR_IFRAME = "WIDGET_OR_IFRAME",
}

export const startConverstaionApi = async (
  chatbot_id: string,
  source: ConversationSources,
  domain?: string
): Promise<StartConverstaionResponse> => {
  try {
    const token = await getAuthToken();
    const response = await post(
      "/conversation/start/",
      {
        chatbot_id: chatbot_id,
        domain,
        source,
      },
      { viaOauth: false }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface ChatSubmitArgs {
  message: string;
  chatbot_id: string;
  conversation_id?: string;
  domain?: string;
}

export const submitChatApi = async ({
  message,
  chatbot_id,
  conversation_id,
  domain,
}: ChatSubmitArgs): Promise<SubmitChatResponse> => {
  try {
    const token = await getAuthToken();
    const response = await post(
      "/conversation/chat/",
      {
        chatbot_id: chatbot_id,
        conversation_id: conversation_id,
        messages: [
          {
            role: "user",
            content: message,
          },
        ],
        temperature: 0,
        domain: domain,
      },
      { token: token, viaOauth: false }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface SourcesSubData {
  text: any[];
  files: any;
  links: any;
}

interface AddSourcesRequestData {
  chatbot_id: string;
  sources: SourcesSubData;
}

export interface AddSourceResponseData {
  source_id: string;
  source_type: "TEXT" | "WEBSITE" | "FILE" | "GOOGLE_DRIVE";
  name: string;
  char_count: number;
  file_id: string;
  trained: boolean;
  status: "CREATED";
  created_at: string;
  updated_at: string;
  trained_at: null | string;
  additional_info: AdditionalInfo;
  chatbot: string;
}

interface AdditionalInfo {
  s3_key: string;
  s3_presigned_url: string;
  url?: string;
  item?: any;
}

interface AddSourceResponse {
  data: {
    result: AddSourceResponseData[];
  };
}

export const addSourcesApi = async (
  data: AddSourcesRequestData
): Promise<AddSourceResponse> => {
  try {
    const token = await getAuthToken();
    const response = await post("/chatbot/sources/add/", data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface CrawlResponse {
  data: {
    result: [
      {
        url: string;
        char_count: number;
      }
    ];
  };
}

export const crawlWebsite = async (data: {
  chatbot_id: string;
  website: string;
}): Promise<CrawlResponse> => {
  try {
    const token = await getAuthToken();
    const response = await post("/chatbot/sources/crawl/", data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getSources = async (data: {
  chatbot_id: string;
}): Promise<AddSourceResponse> => {
  try {
    const token = await getAuthToken();
    const response = await get(`/chatbot/${data?.chatbot_id}/sources/`, data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface TrainingStatus {
  chatbot_id: string;
  name: string;
  status: "TRAINED";
  trained_at: string;
}

interface TrainingStatusResponse {
  data: {
    result: TrainingStatus;
  };
}

export const getTrainingStatus = async (data: {
  chatbot_id: string;
}): Promise<TrainingStatusResponse> => {
  try {
    const token = await getAuthToken();
    const response = await get(`/chatbot/status/${data?.chatbot_id}/`, data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Add more functions for other API calls with appropriate types
export const startTraining = async (data: {
  chatbot_id: string;
}): Promise<{ data: { result: true } }> => {
  try {
    const token = await getAuthToken();
    const response = await post("/chatbot/train/", data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getTextSource = async (data: {
  chatbot_id: string;
}): Promise<{ data: string }> => {
  try {
    const token = await getAuthToken();
    const response = await get(
      `/chatbot/${data?.chatbot_id}/sources/get_text/`,
      data,
      {
        token: token,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface Tokens {
  access_token: string;
  refresh_token: string;
}

export interface Subscription {
  plan: string;
  character_limit: number;
  chatbot_limit: number;
}

interface ChatbotMessageUsage {
  chatbot_id: string;
  name: string;
  num_messages: number; // Assuming the property should be 'num_messages' instead of 'num_meesages'
}

interface MessageLimits {
  total: number;
  used: number;
  renews_on: string; // Assuming this should be a date in string format, otherwise change as needed
  chatbots: ChatbotMessageUsage[];
}

export interface User {
  user_id: string;
  username: null | string;
  email: string;
  picture: null | string;
  name: null | string;
  time_zone: null | string;
  country_code: null | string;
  account_tag: string;
  login_provider: string;
  subscription: Subscription;
  message_limits: MessageLimits;
}

interface AuthResponse {
  tokens: Tokens;
  user: User;
}

export const getLoginInfo = async (): Promise<{ data: AuthResponse }> => {
  try {
    const token = await getAuthToken();
    const response = await post(
      "/users/login/",
      {
        email: "aman.khandelwal1@gmail.com",
        password: "sample password",
      },
      {
        token: token,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const doLoginFirebase = async (
  googleAuthToken: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await post(
      "/users/login/firebase/",
      {},
      {
        token: googleAuthToken,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export interface NewChatbotData {
  name: string;
  model: string;
}

export interface CreateChatbotResponse {
  result: string;
}

export const createChatbot = async (
  data: NewChatbotData
): Promise<AxiosResponse<CreateChatbotResponse>> => {
  try {
    const token = await getAuthToken();
    const response = await post("/chatbot/create/", data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export interface AIModel {
  model_name: string;
  model_code: string;
  plans: string[];
  available: boolean;
  description: string;
}

export interface AIModelsResult {
  result: AIModel[];
}

export const getModelData = async (): Promise<
  AxiosResponse<AIModelsResult>
> => {
  try {
    const token = await getAuthToken();
    const response = await get(
      `/config/models/`,
      {},
      {
        token: token,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getUserFromApi = async (
  orgId?: string
): Promise<AxiosResponse<{ result: User }>> => {
  try {
    const token = await getAuthToken();
    const response = await get(
      `/users/`,
      {},
      {
        token: token,
        viaOauth: true,
        headers: {
          "X-ORG-ID": orgId || "",
        },
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface RateLimitConfig {
  ip_limit: number;
  ip_limit_message: string;
  ip_limit_timeframe: number;
}

interface ChatInterfaceConfig {
  // Specify the structure if you have details, otherwise use 'any' or define it as an empty interface
}

interface LeadFieldConfig {
  label: string;
  active: boolean;
}

interface LeadsConfig {
  name: LeadFieldConfig;
  email: LeadFieldConfig;
  phone: LeadFieldConfig;
  title: string;
}

interface NotificationsConfig {
  daily_leads_report: boolean;
  daily_conversations_report: boolean;
}

export interface Chatbot {
  chatbot_id: string;
  name: string;
  vector_collection: string;
  status: string;
  created_at: string;
  updated_at: string;
  trained_at: string;
  model: string;
  instructions: string;
  temperature: number;
  num_chars: number | null;
  visibility: string;
  rate_limit_config: RateLimitConfig;
  chat_interface_config: ChatInterfaceConfig;
  leads_config: { fields: LeadsConfig; title: string };
  notifications_config: NotificationsConfig;
  user: string;
  allowed_domains: string[];
  restrict_iframe_domains: boolean;
  profile_picture: string | null;
  leads_collection: boolean;
}

interface ChatbotApiResponse {
  result: Chatbot[];
}

export const getChatbots = async (): Promise<
  AxiosResponse<ChatbotApiResponse>
> => {
  try {
    const token = await getAuthToken();
    const response = await get(
      `/chatbot/list/`,
      {},
      {
        token: token,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface ChatbotDetailsApiResponse {
  result: any;
}

export const getChatbotDetails = async (
  chatbot_id: string
): Promise<AxiosResponse<ChatbotDetailsApiResponse>> => {
  try {
    const token = await getAuthToken();
    const response = await get(
      `/chatbot/${chatbot_id}/`,
      {},
      {
        token: token,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const updateChatbotDetails = async (
  chatbot_id: string,
  data: any
): Promise<AxiosResponse<ChatbotDetailsApiResponse>> => {
  try {
    const token = await getAuthToken();
    const response = await put(`/chatbot/update/${chatbot_id}/`, data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface UploadChatbotImageRequest {
  chatbot_id: string;
  filename: string;
  event_type: string;
}

export const uploadChatbotImage = async (
  data: UploadChatbotImageRequest
): Promise<
  AxiosResponse<{ result: { s3_key: string; s3_presigned_url: string } }>
> => {
  try {
    const token = await getAuthToken();
    const response = await post(`/chatbot/image/upload/`, data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface AcknowledgePicRequest {
  chatbot_id: string;
  s3_key: string;
}

export const aknowledgePicUpload = async (
  data: AcknowledgePicRequest
): Promise<AxiosResponse<{ result: string }>> => {
  try {
    const token = await getAuthToken();
    const response = await post("/chatbot/image/upload/ack/", data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface LeadData {
  chatbot_id: string;
  conversation_id: string;
  lead_info: {
    name?: string;
    email?: string;
    phone?: string;
  };
}

export const updateLeadInfo = async (
  data: LeadData
): Promise<AxiosResponse<{ result: boolean }>> => {
  try {
    const token = await getAuthToken();
    const response = await post("/leads/submit/", data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export interface LeadInfo {
  id: number;
  ip_address: string | null;
  name: string;
  email: string | null;
  phone: string | null;
  collected_info: {
    name: string;
  };
  created_at: string;
  chatbot: string;
  conversation: string;
}

export interface PageInfo {
  total_pages: number;
  page_size: string;
  page_number: string;
  current: number;
  has_next: boolean;
  has_previous: boolean;
  total_items: number;
}

export interface LeadsResponse {
  result: {
    leads: LeadInfo[];
    page_info: PageInfo;
  };
}

export const getLeadsList = async (
  chatbot_id: string,
  page: number,
  PAGE_SIZE: number
): Promise<AxiosResponse<LeadsResponse>> => {
  console.log("page getLeadsList", page);
  try {
    const token = await getAuthToken();
    const response = await get(
      `/leads/submitted/list`,
      {
        chatbot_id,
        page_number: page,
        page_size: PAGE_SIZE,
      },
      {
        token: token,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export interface ChatHistoryMessage {
  message_id: string;
  role: string;
  content: string;
  confidence_score: null | number;
  vote: number;
  feedback: null | string;
}

export interface ChatHistory {
  chatbot_id: string;
  conversation_id: string;
  created_at: string;
  source: string;
  messages: ChatHistoryMessage[] | null;
}

interface ChatHistoryResponse {
  result: {
    history: ChatHistory[];
    page_info: PageInfo;
  };
}

export const getChatLogs = async (
  chatbot_id: string,
  page: number,
  PAGE_SIZE: number
): Promise<AxiosResponse<ChatHistoryResponse>> => {
  console.log("page getLeadsList", page);
  try {
    const token = await getAuthToken();
    const response = await get(
      `/conversation/history/`,
      {
        chatbot_id,
        page_number: page,
        page_size: PAGE_SIZE,
      },
      {
        token: token,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export interface Feedback {
  chatbot_id: string;
  conversation_id: string;
  message_id: string;
  vote: number;
  feedback: string;
}

export const submitMessageFeedback = async (
  data: Feedback
): Promise<AxiosResponse<{ result: boolean }>> => {
  try {
    const token = await getAuthToken();
    const response = await post("/conversation/history/feedback/", data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export interface ChatbotIssue {
  chatbot_id: string | null;
  email: string;
  subject: string;
  issue: string;
}

export const submitHelp = async (
  data: ChatbotIssue
): Promise<AxiosResponse<{ result: boolean }>> => {
  try {
    const token = await getAuthToken();
    const response = await post("/users/help/submit/", data, {
      token: token,
      viaOauth: true,
    });
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const deleteChatbot = async (
  chatbot_id: string
): Promise<AxiosResponse<{ result: boolean }>> => {
  try {
    const token = await getAuthToken();
    const response = await del(
      `/chatbot/delete/${chatbot_id}/`,
      {},
      {
        token: token,
        viaOauth: true,
      }
    );
    return response;
  } catch (error) {
    // Handle or throw the error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

export enum UserRole {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  MEMBER = "MEMBER",
  GUEST = "GUEST",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export interface BrandConfig {
  chatbot_id: string;
  name: string;
  tagline: string;
  colors: {
    brand_color: string;
    title_bar_color: string;
  };
  org_id: string;
  created_by_name: string;
  brand_logo_url: string;
  launcher_logo_url: string;
  avatar_icon_url: string;
}

export interface OrganizationConfig {
  display_name: string;
  redirect_url?: string;
  redicrect_url?: string;
  brand_colour: string;
  welcome_message: string;
  image_url: string;
  show_all_automations: boolean;
}

export interface Organization {
  org_id: string;
  org_name: string;
  role: UserRole;
  auth_status: string;
  domain: string;
  config: OrganizationConfig;
  brand_config: BrandConfig;
}

interface OrgListApiResponse {
  result: Organization[];
}

export const getOrgListApi = async (orgId?: string): Promise<any> => {
  return Promise.resolve({
    data: {
      result: [
        {
          org_id: "30e4fab6-cadb-4b99-b1e7-30fca6e147ac",
          org_name: "Robylon AI",
          role: "SUPER_ADMIN",
          auth_status: "APPROVED",
          domain: "robylon.ai",
          config: {
            display_name: "Robylon AI",
            redirect_url: "https://app-stage.robylon.ai/",
            brand_colour: "#6B4DFF",
            welcome_message: "Hey! What can we help you with today?",
            image_url:
              "https://d1sshylvsnyqeg.cloudfront.net/robylon-favicon.png",
          },
        },
        {
          org_id: "66b33d16-5b38-4693-8772-1a1bd7dd412d",
          org_name: "Teachmint",
          role: "MEMBER",
          auth_status: "APPROVED",
          domain: "teachmint.com",
          config: {
            display_name: "Teachmint",
            redirect_url: "https://accounts.teachmint.com/",
            brand_colour: "#1EA1F1",
            welcome_message: "Hey! What can we help you with today?",
            image_url:
              "https://d1sshylvsnyqeg.cloudfront.net/teachmint-logo.png",
          },
        },
        {
          org_id: "c8d90ebd-8911-4f76-9507-bf1b21373a17",
          org_name: "Airmeet",
          role: "MEMBER",
          auth_status: "APPROVED",
          domain: "airmeet.com",
          config: {
            display_name: "Airmeet",
            redirect_url: "https://www.airmeet.com/",
            brand_colour: "#6B4DFF",
            welcome_message: "Hey! What can we help you with today?",
            image_url: "https://d1sshylvsnyqeg.cloudfront.net/airmeet-logo.png",
          },
        },
      ],
    },
  });
  try {
    const token = await getAuthToken();
    const response = await get(
      "/users/org/list/",
      {},
      {
        token: token,
        viaOauth: true,
        headers: {
          "X-ORG-ID": orgId || "",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadFileOnPresignedUrl = async (
  presignedUrl: string,
  file: File
) => {
  console.log("uploadFileOnPresignedUrl called", file);
  try {
    const response = await fetch(presignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        // "Content-Type": file.type, // Set appropriate content type
        "Content-Type": file?.type === "text/plain" ? "text/plain" : file.type,
      },
    });

    return true;
  } catch (error) {
    return false;
  }
};

export const dummyCopilotData: any = {
  user: {
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI3NTM5ODQ0LCJpYXQiOjE3MjY2NzU4NDQsImp0aSI6Ijc2YzY2NTZjOTZlMzQ1YzJhMDUzYzBmMjg1YTE2ZTliIiwidXNlcl9pZCI6IjcwZDE0YWRjLWUwODMtNDBhZi05Y2RmLTJkOWE1Mzg3YjhlOCJ9.s0An1u7z566vcQLH8lBHDjh-GMQX0rA9E9Cwqfj6ClY",
    user_info: {
      email: "jobin.abraham15@gmail.com",
      user_id: "70d14adc-e083-40af-9cdf-2d9a5387b8e8",
      name: "Jobin Abraham",
    },
    org_info: {
      org_id: "30e4fab6-cadb-4b99-b1e7-30fca6e147ac",
      org_name: "Robylon AI",
      role: "MEMBER",
      auth_status: "APPROVED",
      domain: "robylon.ai",
      config: {
        display_name: "Robylon AI",
        redirect_url: "https://app-stage.robylon.ai/",
        brand_colour: "#136AC1",
        welcome_message: "Hey! What can we help you with today?",
        image_url:
          "https://d1h2odiipi7033.cloudfront.net/default_chat_logo.png",
        show_all_automations: true,
      },
      brand_config: {
        chatbot_id: "256763a7-d9c0-4655-b69b-f69e764ee0f4",
        name: "Customer Support Bot",
        tagline: "Your AI Assitant",
        colors: {
          brand_color: "#0E6CD8",
          title_bar_color: "#136",
        },
        org_id: "30e4fab6-cadb-4b99-b1e7-30fca6e147ac",
        created_by_name: "Riju Mondal",
        brand_logo_url:
          "https://d1h2odiipi7033.cloudfront.net/acac6b2a-62a7-44a7-a5ce-1ddf8946413d/Jira_Logo.jpg",
        launcher_logo_url:
          "https://d1h2odiipi7033.cloudfront.net/ee6f2790-a2f2-4826-b829-989aee6351eb/favicon.png",
        avatar_icon_url:
          "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8NDhINEBMWDxAOFQ0QDw0ODhIQEA4SFREWGRURExUYKCghGBolJxUTITImKTU3Oi4uGCs3ODYsOyguOisBCgoKDg0OGxAQGy0lHyI3LzcrMi0tMDIrLTIwLS0rNy4rKzArNS0rKy0rL",
      },
    },
  },
};
