import React from "react";
import { format, isToday, isYesterday, parseISO } from "date-fns";
import { ReactComponent as ClosedCheck } from "assets/images/closed-check.svg";

const ChatClosedSeparation = () => {
  return (
    <div className="flex flex-row gap-2 items-center py-6 justify-center flex-wrap">
      <div className="w-3 h-3">
        <ClosedCheck />
      </div>
      <div className="text-xs text-green-700 text-center md:w-[60%]">
        The issue was marked <b>CLOSED</b> by Agent
      </div>{" "}
      {/* <hr className="grow" /> */}
    </div>
  );
};

export default ChatClosedSeparation;
