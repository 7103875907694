import React, { useEffect, useState } from "react";
import SelectableCardItem, {
  SelectableCardProduct,
} from "./SelectableCardItem";
import ShowMore from "components/CardListTemplates/ShowMore";

interface SelectableCardListProps {
  products: SelectableCardProduct[];
  onSelectionChange?: (selectedIds: number[]) => void;
  initialDisplayCount?: number;
  selectionMode: "single" | "multiple";
  readonly?: boolean;
}

const SelectableCardList: React.FC<SelectableCardListProps> = ({
  products,
  onSelectionChange,
  initialDisplayCount = 3,
  selectionMode = "single",
  readonly,
}) => {
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [hoveredCardId, setHoveredCardId] = useState<number | string | null>(
    null
  );

  const [isReadonly, setIsReadonly] = useState(readonly);

  const handleSelect = (id: any) => {
    setSelectedIds((prevIds) => {
      let newIds: any[];
      if (selectionMode === "single") {
        newIds = prevIds.includes(id) ? [] : [id];
      } else {
        newIds = prevIds.includes(id)
          ? prevIds.filter((prevId) => prevId !== id)
          : [...prevIds, id];
      }

      onSelectionChange?.(newIds);
      setIsReadonly(true);
      return newIds;
    });
  };

  const handleShowMore = () => {
    setDisplayCount((prevCount) => Math.min(prevCount + 3, products.length));
  };

  useEffect(() => {
    if (selectionMode === "single" && selectedIds.length > 0) {
      setIsReadonly(true);
    }
  }, [selectedIds, selectionMode]);

  return (
    <div
      className="rounded-lg  overflow-hidden border border-gray-400 bg-white w-[320px] "
      style={{
        pointerEvents: isReadonly ? "none" : "auto",
        opacity: isReadonly ? 0.6 : 1,
      }}
    >
      {products.slice(0, displayCount).map((product) => (
        <SelectableCardItem
          onHover={() => setHoveredCardId(product.order_id)}
          onLeave={() => setHoveredCardId(null)}
          key={product.order_id}
          isHovered={hoveredCardId === product.order_id}
          product={product}
          isSelected={selectedIds.includes(product.order_id)}
          onSelect={handleSelect}
        />
      ))}
      {initialDisplayCount < products.length && (
        <div className="px-4 py-2 flex flex-row items-center justify-between border-t border-gray-200">
          <p className="text-xs text-gray-800 py-2 grow">
            {displayCount} of {products.length} Products
          </p>
          {displayCount < products.length && (
            <ShowMore
              onShowLess={() => setDisplayCount(initialDisplayCount)}
              onShowMore={handleShowMore}
              remainingCount={products.length - displayCount}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SelectableCardList;
