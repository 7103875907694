import React from "react";
import { ReactComponent as CloseImage } from "assets/images/close-icon.svg";
interface Props {
  onClick: () => void;
  color?: string;
}

function CloseButton({ onClick, color }: Props) {
  return (
    <div
      onClick={onClick}
      style={{
        width: "30px",
        height: "30px",
        color: color,
      }}
      className="mx-2 cursor-pointer"
    >
      <CloseImage />
    </div>
    // <img
    //   onClick={onClick}
    //   src={CloseImage}
    //   alt="Description"
    //   width={30}
    //   height={30}
    //   className="cursor-pointer mx-2"
    // />
  );
}

export default CloseButton;
