import React, { useEffect, useState } from "react";
import AutomationInput from "./AutomationInput";
import AutomationTextArea from "./AutomationTextArea";
import { ReactComponent as AiSparkle } from "assets/images/ai-sparkle.svg";
import { ReactComponent as AiSparkleDisabled } from "assets/images/ai-sparkle-disabled.svg";
import AutomationInputCheckbox from "./AutomationInputCheckbox";
import AutomationInputRadio from "components/AutomationInputRadio";
import AskSession, { AskSessionProps } from "components/AskSession";
import Loader from "assets/lotties/loader-dark-animation.json";
import { Player } from "@lottiefiles/react-lottie-player";
import MultiStepForm from "components/MultiStepForm";
import { Socket } from "net";
import { AllAutomationWorkflow } from "@components/TaskItem";
import { Workflow } from "components/ChatbotUIClientSdk";
import { truncateWords } from "utils/Misc";
import FormAlertDialog from "components/FormAlertDialog";

export interface EventTypeMap {
  click: "CLICK";
  scrollend: "SCROLL";
  clickHold: "HOLD_CLICK";
  dblclick: "DOUBLE_CLICK";
  keyPress: "KEY_PRESS";
  typing: "TYPE";
  submit: "SUBMIT";
  resize: "RESIZE";
  textSelection: "TEXT_SELECT";
  contextmenu: "CLICK";
  keydown: "KEY_DOWN";
  tabChange: "TAB_CHANGE";
  input: "INPUT";
}

export interface FileHeaderMapping {
  input_header: string;
  template_header: string;
}

interface FileInputSheetsConfig {
  sheet_id?: string;
  worksheet_id?: number;
}

interface FileInputConfig {
  source: string; // Assuming source can have more than one type
  file_tracker_id?: string;
  input_sheets_config: FileInputSheetsConfig;
}

interface FileOutputSheetsConfig {
  action: "NEW_SPREADSHEET"; // Assuming action can have more than one type
}

interface FileOutputConfig {
  source: string; // Assuming source can have more than one type
  output_sheets_config: FileOutputSheetsConfig;
}

export interface FileUploadPayload {
  isFileUploadField?: boolean;
  input_id?: string;
  mappings: FileHeaderMapping[];
  input_config: FileInputConfig;
  output_config: FileOutputConfig;
}

type InputField = {
  label: string;
  step_id: string;
  tag_name: string;
  event_type: EventTypeMap[keyof EventTypeMap];
  input_type: string | null;
};

interface InputValue {
  value_type: string;
  value: any;
}

type Props = {
  askSessionProps?: AskSessionProps;
  showAskSession?: boolean;
  onSubmit: (finalValues: any[]) => void;
  workflowMsg: string;
  inputs: any[];
  onCancel: () => void;
  config?: any;
  onRephrase?: (
    data: {
      step_id: string;
      label: string;
      values: InputValue[];
    }[]
  ) => void;
  rephraseResponse?: { [key: string]: string };
  workflowNum?: number;
  websocket?: WebSocket; // TODO: convert to data context later
  sendMessageToWs?: Function; // TODO: convert to data context later
  getBasePayload?: Function; // TODO: convert to data context later
  currentWorkflow?: AllAutomationWorkflow | Workflow;
  showAlert?: boolean;
  onConfirmLeave?: () => void;
  setShowAlert?: (showAlert: boolean) => void;
};

const isMultipleChoiceField = (input_type: string) => {
  return ["checkbox"].includes(input_type);
};

const AutomationInputForm = ({
  inputs,
  workflowMsg,
  onSubmit,
  onCancel,
  config,
  onRephrase,
  rephraseResponse,
  askSessionProps,
  showAskSession,
  workflowNum,
  websocket, // TODO: convert to data context later
  sendMessageToWs, // TODO: convert to data context later
  getBasePayload, // TODO: convert to data context later
  currentWorkflow,
  showAlert,
  onConfirmLeave,
  setShowAlert,
}: Props) => {
  console.log("showAlert", showAlert);
  const [formValues, setFormValues] = useState<{
    [key: string]:
      | string
      | string[]
      | boolean
      | File
      | FileUploadPayload
      | null;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputFields, setInputFields] = useState<any[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [genInProgress, setGenInProgress] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [showLeavingAlert, setShowLeavingAlert] = useState<boolean>(false);

  const handleSubmit = () => {
    setSubmitInProgress(true);
    console.log("validateForm()", formValues, validateForm());
    if (validateForm()) {
      let finalValues: any = [];
      inputFields?.forEach((field) => {
        const step = finalValues.find(
          (step: any) => step.step_id === field?.step_id
        );

        const valueObject = isMultipleChoiceField(field?.input_type)
          ? (formValues?.[field?.input_id] as string[])?.map((item) => {
              return {
                value_type: field?.input_type,
                value: item,
                input_id: field?.input_id,
              };
            })
          : (formValues?.[field?.input_id] as FileUploadPayload)
              ?.isFileUploadField
          ? (formValues?.[field?.input_id] as FileUploadPayload)
          : [
              {
                value_type: field?.input_type,
                value: formValues?.[field?.input_id],
                input_id: field?.input_id,
              },
            ];

        if (step) {
          if (field?.isFileUploadField) {
            (valueObject as FileUploadPayload).mappings = (
              valueObject as FileUploadPayload
            )?.mappings?.filter((mapping) => !!mapping.input_header);
            step.values = valueObject;
          } else {
            step.values.push(...(valueObject as Array<any>));
          }
        } else {
          if (field?.isFileUploadField) {
            (valueObject as FileUploadPayload).mappings = (
              valueObject as FileUploadPayload
            )?.mappings?.filter((mapping) => !!mapping.input_header);
            finalValues.push({
              step_id: field?.step_id,
              values: valueObject,
            });
          } else {
            finalValues.push({
              step_id: field?.step_id,
              values: valueObject,
            });
          }
        }
      });

      console.log("finalValues", finalValues);
      // for (const [key, value] of Object.entries(formValues)) {
      //   const stepId = key?.split(":")?.[1];
      //   const values = [{ value_type:  }];
      // }
      setTimeout(() => {
        setSubmitInProgress(false);
        onSubmit(finalValues);
      }, 2000);

      return;
    }
    setSubmitInProgress(false);
  };
  console.log("formValues====>", formValues);
  const validateForm = () => {
    let valid = true;
    let newErrors = {};
    console.log("validateForm", formValues);
    for (const [key, value] of Object.entries(formValues)) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        newErrors = {
          ...newErrors,
          [key]: "Required field",
        };
        valid = false;
      }
    }
    if (Object.entries(formValues).length === 0 || !formValues) {
      inputFields?.forEach((input) => {
        newErrors = {
          ...newErrors,
          [input?.input_id]: "Required field",
        };
      });
      valid = false;
    }
    // if (!taskSummary?.trim()) {
    //   newErrors = {
    //     ...newErrors,
    //     taskSummary: "Required field",
    //   };
    //   valid = false;
    // }
    console.log("newErrors", newErrors);
    setErrors(newErrors);
    return valid;
  };

  const handleGenAIChange = (fieldName: string) => {
    try {
      setGenInProgress(true);
      let finalValues: any = [];
      console.log("formValues", formValues);
      inputFields?.forEach((field) => {
        const step = finalValues.find(
          (step: any) => step.step_id === field?.step_id
        );

        const valueObject = isMultipleChoiceField(field?.input_type)
          ? (formValues?.[field?.input_id] as string[])?.map((item) => {
              return {
                value_type: field?.input_type,
                value: item,
                input_id: field?.input_id,
              };
            })
          : [
              {
                value_type: field?.input_type,
                value: formValues?.[field?.input_id],
                input_id: field?.input_id,
              },
            ];

        if (step) {
          step.values.push(...valueObject);
        } else {
          finalValues.push({
            step_id: field?.step_id,
            values: valueObject,
          });
        }
      });
      // let finalValues: any = [];
      // inputFields?.forEach((field) => {
      //   const step = {
      //     step_id: field?.step_id,
      //     label: field?.label,
      //     values: [
      //       {
      //         value_type: field?.input_type,
      //         value: formValues?.[field?.input_id],
      //       },
      //     ],
      //   };
      //   finalValues = [...finalValues, step];
      // });
      if (onRephrase) {
        onRephrase(finalValues);
      }
    } catch (error) {}
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>,
    isFile?: boolean
  ) => {
    const { name, id, value, type, checked } = e.target as HTMLInputElement;
    if (type === "checkbox") {
      const existingValues: string[] =
        (formValues?.[e.target.name] as string[]) || [];
      if (checked) {
        existingValues?.push(id);
      } else {
        const index = existingValues.indexOf(id);
        if (index > -1) {
          existingValues.splice(index, 1);
        }
      }
      setFormValues({ ...formValues, [e.target.name]: existingValues });
    } else if (type === "radio") {
      setFormValues({
        ...formValues,
        [e.target.name]: checked ? id : "",
      });
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    let allInputs: any[] = [];
    const preFilledformvalues: any = {};
    inputs?.forEach((input) => {
      if (input?.input_fields) {
        allInputs = [...allInputs, ...input?.input_fields];
      }
    });
    allInputs.forEach((InputField) => {
      if (InputField?.detected_value) {
        preFilledformvalues[InputField?.input_id] = InputField?.detected_value;
      } else {
        if (InputField?.input_type === "FILE_UPLOAD") {
          preFilledformvalues[InputField?.input_id] = {
            isFileUploadField: true,
            input_id: InputField?.input_id,
            mappings: [],
            input_config: {
              source: "",
              file_tracker_id: "",
              input_sheets_config: {
                sheet_id: undefined,
                worksheet_id: undefined,
              },
            },
            output_config: {
              source: "",
              output_sheets_config: {
                action: "NEW_SPREADSHEET",
              },
            },
          } as FileUploadPayload;
        } else preFilledformvalues[InputField?.input_id] = "";
      }
    });
    setFormValues({ ...formValues, ...preFilledformvalues });
    setInputFields(allInputs);
  }, [inputs]);

  useEffect(() => {
    if (rephraseResponse) {
      for (const [key, value] of Object.entries(rephraseResponse)) {
        setFormValues({ ...formValues, [key]: value });
      }
      setGenInProgress(false);
    }
  }, [rephraseResponse]);

  const hasFileUploadField = inputFields?.some(
    (field) => field.input_type === "FILE_UPLOAD"
  );

  // useEffect(() => {
  //   if (showAlert) setShowLeavingAlert(showAlert);
  // }, [showAlert]);

  return (
    <>
      {hasFileUploadField ? (
        <MultiStepForm
          inputs={inputFields}
          formValues={formValues}
          setFormValues={setFormValues}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          validateForm={validateForm}
          onCancel={onCancel}
          genInProgress={genInProgress}
          handleGenAIChange={handleGenAIChange}
          websocket={websocket}
          sendMessageToWs={sendMessageToWs}
          getBasePayload={getBasePayload}
          currentWorkflow={currentWorkflow}
          submitInProgress={submitInProgress}
          isLoading={isLoading}
        />
      ) : (
        <>
          <div className="my-5 mt-[120px] relative grid grid-cols-1 md:grid-cols-2 gap-4  px-5 pb-12">
            {showAskSession && (
              <AskSession
                {...askSessionProps}
                brandColor={config?.brand_colour}
              />
            )}
            {inputFields?.map((InputField, index) => {
              return InputField?.ai_generated ? (
                <AutomationTextArea
                  key={index}
                  rows={6}
                  value={formValues?.[InputField?.input_id] as string}
                  onChange={handleChange}
                  name={InputField?.input_id}
                  error={errors?.[InputField?.input_id]}
                  label={
                    <div className="w-full flex flex-row items-center justify-between">
                      <div>{InputField?.label || ""}</div>
                      <div
                        className={`flex flex-row items-center gap-0.5 cursor-pointer ${
                          genInProgress ? "pointer-events-none" : ""
                        } ${
                          !formValues?.[InputField?.input_id]
                            ? "pointer-events-none text-['#8D9196']"
                            : ""
                        }`}
                        onClick={() => handleGenAIChange(InputField?.input_id)}
                      >
                        <div
                          className={`text-semibold text-xs font-semibold ${
                            !formValues?.[InputField?.input_id]
                              ? "text-[#8D9196]"
                              : "text-robylon-pink"
                          }`}
                        >
                          {genInProgress
                            ? "Rephrasing...."
                            : "Rephrase with AI"}
                        </div>
                        {!genInProgress && (
                          <div className="w-4 h-4">
                            {!!formValues?.[InputField?.input_id] && (
                              <AiSparkle />
                            )}
                            {!formValues?.[InputField?.input_id] && (
                              <AiSparkleDisabled />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  }
                />
              ) : (
                <>
                  {(InputField?.input_type === "text" ||
                    InputField?.input_type === "password" ||
                    InputField?.input_type === "email") && (
                    <AutomationInput
                      key={index}
                      placeholder="Type here..."
                      label={InputField?.label || ""}
                      type="text"
                      name={InputField?.input_id}
                      value={formValues?.[InputField?.input_id] as string}
                      error={errors?.[InputField?.input_id]}
                      onChange={handleChange}
                    />
                  )}
                  {InputField?.input_type === "checkbox" && (
                    <AutomationInputCheckbox
                      key={index}
                      possbileValues={InputField?.possible_values}
                      label={InputField?.label || ""}
                      name={InputField?.input_id}
                      value={formValues?.[InputField?.input_id] as string[]}
                      error={errors?.[InputField?.input_id]}
                      onChange={handleChange}
                    />
                  )}
                  {InputField?.input_type === "radio" && (
                    <AutomationInputRadio
                      key={index}
                      possbileValues={InputField?.possible_values}
                      label={InputField?.label || ""}
                      name={InputField?.input_id}
                      value={formValues?.[InputField?.input_id] as string}
                      error={errors?.[InputField?.input_id]}
                      onChange={handleChange}
                    />
                  )}

                  {InputField?.input_type === "textarea" && (
                    <AutomationTextArea
                      key={index}
                      rows={6}
                      value={formValues?.[InputField?.input_id] as string}
                      onChange={handleChange}
                      name={InputField?.input_id}
                      error={errors?.[InputField?.input_id]}
                      label={InputField?.label || ""}
                    />
                  )}
                </>
              );
            })}

            <div
              className="fixed bottom-0 left-0 right-0 bg-white border-t p-4 flex justify-between gap-4"
              style={{ boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.16)" }}
            >
              <button
                style={{
                  borderColor: config?.brand_colour || "",
                  color: config?.brand_colour || "",
                }}
                className={`py-2 px-4 grow basis-1/2 ${
                  !config?.brand_colour ? "text-robylon-blue" : ""
                } bg-white border ${
                  !config?.brand_colour ? "border-robylon-blue" : ""
                } font-semibold rounded-md ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                style={{
                  background: config?.brand_colour || "",
                }}
                onClick={(e) => {
                  handleSubmit();
                }}
                className={`py-2 px-4 grow text-white basis-1/2 ${
                  !config?.brand_colour ? "" : "bg-robylon-blue"
                } rounded-md font-semibold ${
                  isLoading &&
                  "opacity-50 cursor-not-allowed disabled:bg-inkAlpha-100"
                }`}
                disabled={submitInProgress || genInProgress}
              >
                {!submitInProgress ? (
                  "Execute"
                ) : (
                  <Player
                    autoplay
                    src={Loader}
                    loop={true}
                    style={{ height: "20px", width: "20px" }}
                  ></Player>
                )}
              </button>
            </div>
          </div>
        </>
      )}
      <FormAlertDialog
        title="Are you sure?"
        body="Do you want to cancel this automation run? All the progress made in filling the form will be lost."
        closeModal={() => {
          if (setShowAlert) setShowAlert(false);
        }}
        modalIsOpen={showAlert || false}
        onConfirm={() => {
          if (onConfirmLeave) onConfirmLeave();
        }}
      />
    </>
  );
};

export default AutomationInputForm;
