import DOMPurify from "dompurify";

// Configuration for DOMPurify
const purifyConfig = {
  ADD_ATTR: ["target", "rel"], // Allow target and rel attributes
  ADD_TAGS: ["a"], // Ensure anchor tags are allowed
};

/**
 * Adds target="_blank" and rel="noopener noreferrer" to all anchor tags in HTML string
 * @param html Original HTML string
 * @returns Sanitized HTML string with modified anchor tags
 */
export const sanitizeAndModifyLinks = (html: string): string => {
  if (!html) return "";

  // Create a temporary div to parse HTML
  const tempDiv = document.createElement("div");

  // First sanitize the HTML using DOMPurify
  const sanitizedHtml = DOMPurify.sanitize(html, purifyConfig);
  tempDiv.innerHTML = sanitizedHtml;

  // Find all anchor tags and modify them
  const links = tempDiv.getElementsByTagName("a");
  for (let i = 0; i < links.length; i++) {
    links[i].setAttribute("target", "_blank");
    links[i].setAttribute("rel", "noopener noreferrer");
  }

  return tempDiv.innerHTML;
};

// Optional: Event handler to force links to open in new tab
export const handleLinkClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (target.tagName === "A") {
    event.preventDefault();
    const href = (target as HTMLAnchorElement).href;
    if (href) {
      window.open(href, "_blank", "noopener,noreferrer");
    }
  }
};
