import React, { useRef, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Loader from "assets/lotties/loader-dark-animation.json";
import { Workflow } from "components/ChatbotUIClientSdk";
import Popover from "components/Library/Popover";
import { getBestFontColor, hexToRgbA } from "utils/Misc";
import { ReactComponent as BookmarkIconOutline } from "assets/images/bookmark-outline.svg";
import { ReactComponent as BookmarkIconFilled } from "assets/images/bookmark-filled.svg";
import { config } from "process";
import { useConfig } from "contexts/ConfigContext";

export interface AllAutomationWorkflow extends Workflow {
  favourite: boolean;
  display_tag: string | null;
}

interface TaskItemProps {
  task: AllAutomationWorkflow;
  brandColor?: string;
  onBookmark: (id: string, bookmarked: boolean) => void;
  onRun: (workflow: AllAutomationWorkflow) => Promise<void>;
  isRunning?: boolean;
  showPopover: boolean;
  onTogglePopover: () => void;
  isDisabled?: boolean;
}

const TaskItem: React.FC<TaskItemProps> = ({
  task,
  onBookmark,
  brandColor,
  onRun,
  isRunning,
  showPopover,
  onTogglePopover,
  isDisabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [hoverClass, setHoverClass] = useState<string>();
  const { config } = useConfig();

  const handleBookmark = async () => {
    try {
      const updatedBookmarkedStatus = !task?.favourite;
      onBookmark(task.workflow_id, updatedBookmarkedStatus);
    } catch (error) {
      console.error("Error updating bookmark status", error);
    }
  };

  const handleRunClick = async () => {
    if (!isDisabled) {
      if (!isRunning) {
        setIsLoading(true);
        await onRun(task);
        setIsLoading(false);
      }
    } else {
      onTogglePopover();
    }
  };

  return (
    <div
      className={`border p-4 rounded-lg flex transition-all duration-200 group border-gray-300 relative gap-3 ${hoverClass} `}
      style={{
        borderColor: hoverClass ? hexToRgbA(hoverClass, 0.24) : "",
        background: hoverClass ? hexToRgbA(hoverClass, 0.04) : "",
      }}
      onMouseOver={() => setHoverClass(`${brandColor}`)}
      onMouseLeave={() => setHoverClass("")}
    >
      <div className="flex flex-col basis-3/4 justify-between">
        <div className="mb-3 text-sm text-ink">
          <p>{task.title}</p>
        </div>
        <div className="flex items-center gap-2 text-xs">
          <div
            className="text-yellow-500 cursor-pointer"
            onClick={handleBookmark}
          >
            {task?.favourite ? <BookmarkIconFilled /> : <BookmarkIconOutline />}
          </div>
          {!!task?.display_tag && (
            <>
              <div className="bg-gray-300 w-[1px] h-4"></div>
              <div>{task?.display_tag}</div>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center basis-1/4 ">
        <button
          ref={buttonRef}
          className={`px-2 py-1 transition-all duration-200 rounded-md text-xs w-full ${
            isDisabled ? "bg-inkAlpha-100 text-inkAlpha-500" : ""
          } ${!isLoading ? "hidden" : "block"} group-hover:block`}
          style={{
            backgroundColor: !isDisabled ? brandColor : "",
            color: !isDisabled
              ? config?.colors?.brand_color
                ? getBestFontColor(config?.colors?.brand_color)
                : ""
              : "",
          }}
          onBlur={onTogglePopover}
          onClick={handleRunClick}
        >
          {!isLoading && <span>Run ▶</span>}
          {isLoading && (
            <Player
              autoplay
              src={Loader}
              loop={true}
              style={{ height: "20px", width: "20px" }}
            ></Player>
          )}
        </button>
        {/* <div className="group-hover:block hidden"> */}
        <Popover show={showPopover} targetRef={buttonRef} maxWidth="240px">
          <div className="text-xs">Can run only 1 automation at a time</div>
        </Popover>
        {/* </div> */}
      </div>
    </div>
  );
};

export default TaskItem;
