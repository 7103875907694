// src/components/CSATForm.tsx

import React, { useState } from "react";
import { ReactComponent as Star } from "assets/images/CSAT/star.svg";
import { ReactComponent as StarHover } from "assets/images/CSAT/star-hover.svg";
import { ReactComponent as StarSelected } from "assets/images/CSAT/star-selected.svg";
import { ReactComponent as Emoji1 } from "assets/images/CSAT/emoji-1.svg";
import { ReactComponent as Emoji1Hover } from "assets/images/CSAT/emoji-1-hover.svg";
import { ReactComponent as Emoji1Selected } from "assets/images/CSAT/emoji-1-selected.svg";
import { ReactComponent as Emoji2 } from "assets/images/CSAT/emoji-2.svg";
import { ReactComponent as Emoji2Hover } from "assets/images/CSAT/emoji-2-hover.svg";
import { ReactComponent as Emoji2Selected } from "assets/images/CSAT/emoji-2-selected.svg";
import { ReactComponent as Emoji3 } from "assets/images/CSAT/emoji-3.svg";
import { ReactComponent as Emoji3Hover } from "assets/images/CSAT/emoji-3-hover.svg";
import { ReactComponent as Emoji3Selected } from "assets/images/CSAT/emoji-3-selected.svg";
import { ReactComponent as Emoji4 } from "assets/images/CSAT/emoji-4.svg";
import { ReactComponent as Emoji4Hover } from "assets/images/CSAT/emoji-4-hover.svg";
import { ReactComponent as Emoji4Selected } from "assets/images/CSAT/emoji-4-selected.svg";
import { ReactComponent as Emoji5 } from "assets/images/CSAT/emoji-5.svg";
import { ReactComponent as Emoji5Hover } from "assets/images/CSAT/emoji-5-hover.svg";
import { ReactComponent as Emoji5Selected } from "assets/images/CSAT/emoji-5-selected.svg";
import InputLabel from "components/CSATForm/InputLabel";
import { useConfig } from "contexts/ConfigContext";
import { getBestFontColor } from "utils/Misc";

interface FeedbackFormProps {
  inputs: {
    field_id: string;
    field_label: string;
    field_type: "TEXTFIELD" | "RATING";
    field_sub_type: "STAR" | "EMOJI" | null;
    placeholder?: string;
  }[];
  submitButtonText: string;
  onSubmit: (
    formData: { [key: string]: string },
    ratingMap?: { [key: string]: number },
    templateId?: string
  ) => void;
  templateId?: string;
}

const CSATForm: React.FC<FeedbackFormProps> = ({
  inputs,
  submitButtonText,
  onSubmit,
  templateId,
}) => {
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [ratingMap, setRatingMap] = useState<{ [key: string]: number }>({});
  const [hoveredMap, setHoveredMap] = useState<{
    [key: string]: number | null;
  }>({});
  const { config } = useConfig();

  const handleInputChange = (field_id: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field_id]: value }));
  };

  const handleRatingClick = (field_id: string, value: number) => {
    setRatingMap((prev) => ({ ...prev, [field_id]: value }));
  };

  const handleMouseEnter = (field_id: string, value: number) => {
    setHoveredMap((prev) => ({ ...prev, [field_id]: value }));
  };

  const handleMouseLeave = (field_id: string) => {
    setHoveredMap((prev) => ({ ...prev, [field_id]: null }));
  };

  const handleSubmit = () => {
    onSubmit(formData, ratingMap, templateId);
  };

  return (
    <div>
      {inputs.map((input, index) => (
        <div
          key={input.field_id}
          className={`py-4 ${index === 0 ? "pt-0" : ""} px-6`}
        >
          {/* <label className="block text-center text-gray-600 mb-2">
            {input.field_label}
          </label> */}

          <InputLabel text={input.field_label} />

          {/* Textfield Input */}
          {input.field_type === "TEXTFIELD" && (
            <textarea
              rows={3}
              placeholder={input.placeholder || "Type here..."}
              className="w-full border border-inkAlpha-200 focus:border-inkAlpha-300 rounded p-2 resize-none outline-none placeholder:text-inkAlpha-400 text-sm text-ink"
              onChange={(e) =>
                handleInputChange(input.field_id, e.target.value)
              }
            />
          )}

          {/* Star Rating Input */}
          {input.field_type === "RATING" && input.field_sub_type === "STAR" && (
            <div className="flex justify-center gap-2">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  onClick={() => handleRatingClick(input.field_id, star)}
                  onMouseEnter={() => handleMouseEnter(input.field_id, star)}
                  onMouseLeave={() => handleMouseLeave(input.field_id)}
                  // className="mx-1"
                >
                  {ratingMap[input.field_id] &&
                  ratingMap[input.field_id] >= star ? (
                    <StarSelected />
                  ) : hoveredMap[input.field_id] &&
                    hoveredMap[input.field_id]! >= star ? (
                    <StarHover />
                  ) : (
                    <Star />
                  )}
                </button>
              ))}
            </div>
          )}

          {/* Emoji Rating Input */}
          {input.field_type === "RATING" &&
            input.field_sub_type === "EMOJI" && (
              <div className="flex justify-center gap-2">
                {[1, 2, 3, 4, 5].map((emoji, index) => {
                  const emojiIcons = [
                    {
                      Default: Emoji1,
                      Hover: Emoji1Hover,
                      Selected: Emoji1Selected,
                    },
                    {
                      Default: Emoji2,
                      Hover: Emoji2Hover,
                      Selected: Emoji2Selected,
                    },
                    {
                      Default: Emoji3,
                      Hover: Emoji3Hover,
                      Selected: Emoji3Selected,
                    },
                    {
                      Default: Emoji4,
                      Hover: Emoji4Hover,
                      Selected: Emoji4Selected,
                    },
                    {
                      Default: Emoji5,
                      Hover: Emoji5Hover,
                      Selected: Emoji5Selected,
                    },
                  ];

                  const { Default, Hover, Selected } = emojiIcons[index];

                  return (
                    <button
                      key={index}
                      onClick={() =>
                        handleRatingClick(input.field_id, index + 1)
                      }
                      onMouseEnter={() =>
                        handleMouseEnter(input.field_id, index + 1)
                      }
                      onMouseLeave={() => handleMouseLeave(input.field_id)}
                      // className="mx-1"
                    >
                      {ratingMap[input.field_id] === index + 1 ? (
                        <Selected />
                      ) : hoveredMap[input.field_id] === index + 1 ? (
                        <Hover />
                      ) : (
                        <Default />
                      )}
                    </button>
                  );
                })}
              </div>
            )}
        </div>
      ))}
      <div className="mt-4 mb-4 px-6">
        <button
          style={{
            backgroundColor: config?.colors?.brand_color,
            color: config?.colors?.brand_color
              ? getBestFontColor(config?.colors?.brand_color)
              : "",
          }}
          onClick={handleSubmit}
          className={`w-full ${
            !config?.colors?.brand_color
              ? "bg-blue-600 hover:bg-blue-700 text-white"
              : "hover:opacity-90"
          }  py-2 rounded-lg text-sm font-semibold`}
        >
          {submitButtonText}
        </button>
      </div>
    </div>
  );
};

export default CSATForm;
