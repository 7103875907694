import { useConfig } from "contexts/ConfigContext";
import React from "react";

interface ShowMoreProps {
  onShowMore: () => void;
  onShowLess: () => void;
  remainingCount: number;
}

const ShowMore: React.FC<ShowMoreProps> = ({
  onShowMore,
  remainingCount,
  onShowLess,
}) => {
  const { config } = useConfig();
  console.log("remainingCount", remainingCount);
  return (
    <>
      {remainingCount > 0 && (
        <button
          onClick={onShowMore}
          className=" py-2 text-center text-xs  font-semibold hover:opacity-80 rounded-lg transition-colors duration-300"
          style={{ color: config?.colors?.brand_color }}
        >
          Show {Math.min(remainingCount, 3)} More
        </button>
      )}
      {remainingCount <= 0 && (
        <button
          onClick={onShowLess}
          className=" py-2 text-center  font-semibold hover:opacity-80 rounded-lg transition-colors duration-300"
          style={{ color: config?.colors?.brand_color }}
        >
          Show Less
        </button>
      )}
    </>
  );
};

export default ShowMore;
