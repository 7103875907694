import React from "react";
import { ReactComponent as ImageIcon } from "assets/images/Upload-Image-Icon.svg";

export default function GetActionIcons({ icon }: { icon: string }) {
  switch (icon) {
    case "IMAGE":
      return <ImageIcon />;
    default:
      return <></>;
  }
}
