import ExecutionStateStatus, {
  WorkflowStatusEnum,
} from "components/ExecutionStateStatus";
import React from "react";

type Props = {
  onBtnClick: () => void;
  btnTitle: string | React.ReactNode;
  btnRightIcon?: any;
  isLoading?: boolean;
  brandColor?: string;
  executionState?: WorkflowStatusEnum;
};

const ExecutionStateFooter = ({
  onBtnClick,
  btnTitle,
  btnRightIcon,
  isLoading,
  brandColor,
  executionState,
}: Props) => {
  return (
    <div
      className={`flex flex-row items-center justify-between gap-1  w-full rounded-b-[12px] px-3 py-3`}
    >
      {!!executionState && <ExecutionStateStatus status={executionState} />}
      {!!btnTitle && (
        <button
          onClick={onBtnClick}
          style={{
            color: brandColor || "",
          }}
          className={` text-sm ${
            !brandColor ? "text-robylon-blue" : ""
          } rounded-md font-semibold  flex flex-row items-center gap-1 hover:outline hover:outline-gray-100 hover:outline-1 p-1 px-1.5`}
        >
          {btnTitle}
          {!!btnRightIcon && btnRightIcon}
        </button>
      )}
    </div>
  );
};

export default ExecutionStateFooter;
