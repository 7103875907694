import React from "react";
import { format, isToday, isYesterday, parseISO } from "date-fns";
import { ReactComponent as ClosedCheck } from "assets/images/closed-check.svg";

const CsatCompleteSeparation = () => {
  return (
    <div className="flex flex-row gap-2 items-center pt-6 justify-center flex-wrap">
      {/* <div className="w-3 h-3">
        <ClosedCheck />
      </div> */}
      <div className="text-xs text-gray-800 text-center md:w-[60%]">
        Thank you for your Feedback :)
      </div>{" "}
      {/* <hr className="grow" /> */}
    </div>
  );
};

export default CsatCompleteSeparation;
