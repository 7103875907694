import React, { useEffect } from "react";
import { ReactComponent as CloseIcon } from "assets/images/close-icon-dynamic-color.svg";

interface FormAlertDialogProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  title?: string;
  body?: string | React.ReactNode;
}

const FormAlertDialog: React.FC<FormAlertDialogProps> = ({
  modalIsOpen,
  closeModal,
  onConfirm,
  body,
  title,
}) => {
  return (
    <>
      {modalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-200 auth-modal z-[1000000]">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full sm:max-w-lg mx-8 pt-4 pb-1">
            <div className="px-4  flex flex-row justify-between">
              <div className="text-ink  text-base font-semibold">{title}</div>
              <div className="flex justify-end grow">
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700 text-lg"
                >
                  <div className="w-6 h-6 text-gray-800">
                    <CloseIcon />
                  </div>
                </button>
              </div>
            </div>
            <div className="p-4 text-sm">{body}</div>
            <div className="flex flex-row gap-3 justify-end p-4  items-center">
              <div
                className="cursor-pointer text-ink text-sm font-semibold"
                onClick={closeModal}
              >
                No
              </div>
              <button
                onClick={onConfirm}
                className="bg-red-600 py-1.5 px-3 font-semibold  text-sm text-white rounded-lg hover:bg-red-700"
              >
                Cancel the Run
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormAlertDialog;
