import { ReactComponent as RefreshImage } from "assets/images/refresh-icon.svg";
import { useState } from "react";

interface Props {
  onClick: () => void;
  noRotate?: boolean;
  color?: string;
}

function RefreshButton({ onClick, noRotate, color }: Props) {
  const [rotation, setRotation] = useState(0);

  const handleHover = () => {
    setRotation((prevRotation) => prevRotation - 360); // Add 180 degrees on each hover
  };

  const buttonStyle = {
    transform: `rotate(${rotation}deg)`,
    transition: "transform 0.5s ease",
  };

  return (
    <RefreshImage
      onClick={onClick}
      style={{
        width: "20px",
        height: "20px",
        cursor: "pointer",
        color,
        ...buttonStyle,
      }}
      onMouseEnter={handleHover}
    />
    // <img
    //   onMouseEnter={handleHover} /* Add onMouseEnter event */
    //   onClick={onClick}
    //   src={RefreshImage}
    //   alt="Refresh"
    //   width={20}
    //   height={20}
    //   className="cursor-pointer"
    //   style={!noRotate ? buttonStyle : {}}
    // />
  );
}

export default RefreshButton;
