import React from "react";
import CollapseIcon from "assets/images/collapse-icon.svg";
interface Props {
  onClick: () => void;
}

function CollapseButton({ onClick }: Props) {
  return (
    <img
      onClick={onClick}
      src={CollapseIcon}
      alt="Description"
      width={20}
      height={20}
      className="cursor-pointer mx-2"
    />
  );
}

export default CollapseButton;
