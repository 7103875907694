import React, { useCallback, useMemo, useState } from "react";
import CheckGreen from "assets/images/check-green.svg";
import Warning from "assets/images/warning-icon.svg";
import { ReactComponent as CaretRight } from "assets/images/caret-right.svg";
import ExecutionStateButton from "components/ExecutionStateButton";
import ExecutionStateFooter from "components/ExecutionStateFooter";
import { WorkflowStatusEnum } from "components/ExecutionStateStatus";

interface ExecutionStateProps {
  workflowMsg: string;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isCancelled?: boolean;
  onRun: () => void;
  onCancel: () => void;
  videoLink?: string;
  streamLink?: string;
  end_state_info?: {
    url: string;
    button_name: string;
  };
  config?: any;
  isDisabled?: boolean;
  executionState?: WorkflowStatusEnum;
  workflowNum?: number;
  hideExecutionState?: boolean;
}

const ExecutionState: React.FC<ExecutionStateProps> = ({
  workflowMsg,
  isLoading,
  isSuccess,
  isError,
  isCancelled,
  onRun,
  onCancel,
  videoLink,
  streamLink,
  end_state_info,
  config,
  isDisabled,
  executionState,
  workflowNum,
  hideExecutionState,
}) => {
  const checkEndState = useCallback((state?: WorkflowStatusEnum) => {
    if (
      (state && state === WorkflowStatusEnum.SUCCESS) ||
      state === WorkflowStatusEnum.FAILED ||
      state === WorkflowStatusEnum.CANCELLED
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const checkIsRunning = useCallback((state?: WorkflowStatusEnum) => {
    if (
      state === WorkflowStatusEnum.PREPARING ||
      state === WorkflowStatusEnum.RUNNING
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const getBtnTitle = () => {};

  const isEndState = useMemo(
    () => checkEndState(executionState),
    [executionState, checkEndState]
  );

  const isRunning = useMemo(
    () => checkIsRunning(executionState),
    [executionState, checkIsRunning]
  );
  return (
    <div
      className=" border border-gray-300 bg-paper rounded-[12px] min-w-[264px] max-w-[372px] relative"
      role="alert"
      aria-live="polite"
    >
      {isDisabled && (
        <div
          className="absolute left-0 right-0 top-0 bottom-0 bg-white opacity-50"
          aria-hidden="true"
        ></div>
      )}
      <div
        className={`p-4 ${
          !hideExecutionState
            ? "border-b border-b-gray-300"
            : "border-b border-b-gray-300"
        }`}
      >
        <div
          className={`text-xs text-gray-800 mb-1 ${
            isCancelled ? "text-black" : "text-black"
          }`}
        >
          Automation #{workflowNum || "..."}
        </div>
        <div
          className={`${
            isCancelled ? "text-black" : "text-black"
          } font-semibold text-sm`}
        >
          {workflowMsg}
        </div>
      </div>
      {!isDisabled && !hideExecutionState && (
        <>
          {isEndState && (
            <>
              {executionState === WorkflowStatusEnum.SUCCESS && (
                <ExecutionStateFooter
                  executionState={executionState}
                  onBtnClick={() => {
                    window.open(videoLink);
                  }}
                  btnTitle={videoLink ? "Recording" : ""}
                  btnRightIcon={videoLink && <CaretRight />}
                  brandColor={config?.brand_colour}
                />
              )}
              {executionState === WorkflowStatusEnum.FAILED && (
                <ExecutionStateFooter
                  executionState={executionState}
                  onBtnClick={() => {
                    window.open(videoLink);
                  }}
                  btnTitle={videoLink ? "Recording" : ""}
                  btnRightIcon={videoLink && <CaretRight />}
                  brandColor={config?.brand_colour}
                />
              )}
            </>
          )}

          {!isEndState && !isRunning && (
            <div className="flex flex-row gap-2 items-center justify-center">
              {/* <button
                onClick={onCancel}
                style={{
                  borderColor: config?.brand_colour || "",
                  color: config?.brand_colour || "",
                }}
                className={`py-2 px-3 ${
                  !config?.brand_colour
                    ? "text-robylon-blue border-robylon-blue"
                    : ""
                } bg-white border font-semibold rounded-md ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
                disabled={isLoading}
              >
                Cancel
              </button> */}
              <ExecutionStateButton
                onClick={onRun}
                isLoading={isLoading}
                brand_colour={config?.brand_colour}
                title={isLoading ? "In progress..." : "Run"}
              />
              {/* <button
                onClick={onRun}
                style={{
                  color: config?.brand_colour || "",
                }}
                className={`py-2 px-3 text-sm ${
                  !config?.brand_colour ? " text-robylon-blue" : ""
                } rounded-md font-semibold  ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
                disabled={isLoading}
              >
                {isLoading ? "In progress..." : "Run"}
              </button> */}
            </div>
          )}
          {!isEndState && isRunning && (
            <ExecutionStateFooter
              executionState={executionState}
              btnTitle={streamLink ? "Live" : ""}
              btnRightIcon={
                streamLink && (
                  <CaretRight />
                  // <img src={{ReactComponent as CaretRight}} alt="Caret Right" className="mt-0.5" color="" />
                )
              }
              onBtnClick={() => {
                if (streamLink) window.open(streamLink);
              }}
              brandColor={config?.brand_colour}
            />
          )}
          {isCancelled && <div className="text-robylon-gray">Cancelled</div>}
        </>
      )}
      {hideExecutionState && (
        <div className="flex flex-row gap-2 items-center justify-center">
          {/* <button
                onClick={onCancel}
                style={{
                  borderColor: config?.brand_colour || "",
                  color: config?.brand_colour || "",
                }}
                className={`py-2 px-3 ${
                  !config?.brand_colour
                    ? "text-robylon-blue border-robylon-blue"
                    : ""
                } bg-white border font-semibold rounded-md ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
                disabled={isLoading}
              >
                Cancel
              </button> */}
          <ExecutionStateButton
            onClick={() => {}}
            isLoading={true}
            brand_colour={"#707378"}
            title={"Run"}
          />
          {/* <button
                onClick={onRun}
                style={{
                  color: config?.brand_colour || "",
                }}
                className={`py-2 px-3 text-sm ${
                  !config?.brand_colour ? " text-robylon-blue" : ""
                } rounded-md font-semibold  ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
                disabled={isLoading}
              >
                {isLoading ? "In progress..." : "Run"}
              </button> */}
        </div>
      )}
      {isDisabled && <div className="text-robylon-gray-dark">Paused</div>}
    </div>
  );
};

export default ExecutionState;
