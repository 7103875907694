// components/BottomSheet.tsx

import React, { useEffect, useState } from "react";

interface BottomSheetProps {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  isVisible: boolean;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
  title,
  children,
  onClose,
  isVisible,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsOpen(true); // Make the BottomSheet visible
      setTimeout(() => setIsAnimating(true), 10); // Trigger the slide-in after mount
    } else {
      setIsAnimating(false); // Trigger slide-out
      setTimeout(() => setIsOpen(false), 300); // Wait for the exit animation
    }
  }, [isVisible]);

  return (
    <>
      {isOpen && (
        <div
          className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-50 flex justify-center items-end transition-opacity duration-300 ${
            isAnimating ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`bg-white w-full md:max-w-md rounded-t-[12px] shadow-lg transform transition-transform duration-300 ${
              isAnimating ? "translate-y-0" : "translate-y-full"
            }`}
          >
            <div className="flex justify-end items-center">
              <button
                onClick={onClose}
                className="text-ink/50 hover:text-ink/90 mx-4 text-xl mt-2"
              >
                &times;
              </button>
            </div>

            <div>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default BottomSheet;
