import React, { useState, useRef, useEffect } from "react";
import { useConfig } from "contexts/ConfigContext";
import { isLowContrastColor } from "utils/Misc";
import { ReactComponent as ArrowLeft } from "assets/images/carousel-left-arrow.svg";
import { ReactComponent as ArrowRight } from "assets/images/carousel-right-arrow.svg";

interface OrderButton {
  title: string;
  click_value: string;
}

export interface Order {
  title: string;
  click_value: string;
  subtext: string;
  third_line: string;
  image_source: string;
  buttons: OrderButton[];
}

export interface CarouselProps {
  data: Order[];
  imageHeight?: string;
  input_id?: string;
  onClick: (params: {
    title: string;
    click_value: string;
    input_id: string;
  }) => void;
  isDisabled?: boolean;
  edgeSlideVisibility?: number;
}

const Carousel: React.FC<CarouselProps> = ({
  data,
  imageHeight = "h-40",
  onClick,
  input_id,
  isDisabled,
  edgeSlideVisibility = 0.17,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const slideWidth = 200;
  const slideSpacing = 20;
  const { config } = useConfig();

  const carouselRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (carouselRef.current) {
        setContainerWidth(carouselRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const visibleWidth = containerWidth;
  const slidesInView = Math.floor(visibleWidth / (slideWidth + slideSpacing));
  const offsetCenteringAdjustment =
    (visibleWidth -
      (slidesInView * (slideWidth + slideSpacing) - slideSpacing)) /
    2;

  const calculateOffset = (index: number) => {
    let baseOffset =
      -(index * (slideWidth + slideSpacing)) + offsetCenteringAdjustment;

    if (index === 0) {
      baseOffset -= slideWidth * edgeSlideVisibility;
    } else if (index === data.length - 1) {
      baseOffset += slideWidth * edgeSlideVisibility;
    }

    return baseOffset;
  };

  const [offset, setOffset] = useState(calculateOffset(currentIndex));

  useEffect(() => {
    setOffset(calculateOffset(currentIndex));
  }, [currentIndex, containerWidth, edgeSlideVisibility]);

  const goToPrevious = () => {
    if (currentIndex > 0 && !isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex(currentIndex - 1);
      setTimeout(() => setIsTransitioning(false), 300);
    }
  };

  const goToNext = () => {
    if (currentIndex < data.length - 1 && !isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex(currentIndex + 1);
      setTimeout(() => setIsTransitioning(false), 300);
    }
  };

  const isLowContrast = isLowContrastColor(
    config?.colors?.brand_color || "#ffffff"
  );

  const fontColor = isLowContrast ? "#0E0E0F" : config?.colors?.brand_color;

  return (
    <div
      ref={carouselRef}
      className="w-full relative overflow-hidden pb-3 mb-6 pt-4"
    >
      <div className="absolute -top-0 left-0 right-0 h-[100%] mx-14 rounded-[12px] pt-4"></div>
      <div
        className="flex transition-transform duration-300 ease-in-out"
        style={{ transform: `translateX(${offset}px)` }}
      >
        {data.map((item, index) => (
          <div
            key={index}
            className="flex-shrink-0 bg-white rounded-lg overflow-hidden relative border border-gray-300"
            style={{
              width: `${slideWidth}px`,
              marginRight: `${slideSpacing}px`,
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
            }}
          >
            <img
              src={item.image_source}
              alt={`Slide ${index}`}
              className={`object-cover w-full ${imageHeight}`}
            />
            <div className="p-4">
              {/* Title limited to 2 lines with ellipsis */}
              <div className="text-ssm text-ink line-clamp-1 overflow-hidden">
                {item.title}
              </div>

              {/* Subtext limited to 3 lines with ellipsis */}
              <div className="text-xs text-gray-800 mt-1 line-clamp-1 overflow-hidden">
                <span dangerouslySetInnerHTML={{ __html: item.subtext }} />
              </div>

              {/* Third line limited to 1 line with ellipsis */}
              <div className="text-xs text-gray-800 mt-1 line-clamp-1 overflow-hidden">
                {item.third_line}
              </div>
            </div>
            <div className="flex flex-col">
              {item.buttons.map((button, buttonIndex) => (
                <button
                  key={buttonIndex}
                  disabled={isDisabled}
                  onClick={() => {
                    if (onClick && input_id) {
                      onClick({
                        title: button.title,
                        click_value: button.click_value,
                        input_id,
                      });
                    }
                  }}
                  className={`font-semibold py-3 w-full  disabled:text-ink  disabled:border-gray-200 border-t border-gray-200 relative group ${
                    buttonIndex === item.buttons.length - 1
                      ? "rounded-b-xl"
                      : ""
                  }`}
                  style={{
                    color: !isDisabled ? fontColor : "",
                  }}
                >
                  <div
                    className="absolute left-0 right-0 top-0 bottom-0 group-hover:block group-active:hidden group-disabled:hidden hidden"
                    style={{
                      backgroundColor: config?.colors?.brand_color,
                      opacity: 0.08,
                    }}
                  ></div>
                  <div
                    className="absolute left-0 right-0 top-0 bottom-0 group-hover:hidden group-active:block group-disabled:hidden hidden"
                    style={{
                      backgroundColor: config?.colors?.brand_color,
                      opacity: 0.16,
                    }}
                  ></div>
                  <span className={`${isDisabled ? "opacity-40" : ""}`}>
                    {button.title}
                  </span>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* Left edge gradient overlay */}
      <div
        className="absolute left-0 top-0 bottom-0 w-4 z-10 transition-opacity duration-300 pointer-events-none"
        style={{
          background:
            "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
          opacity: currentIndex > 0 ? 1 : 0,
        }}
      ></div>
      {/* Right edge gradient overlay */}
      <div
        className="absolute right-0 top-0 bottom-0 w-4 z-10 transition-opacity duration-300 pointer-events-none"
        style={{
          background:
            "linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)",
          // opacity: currentIndex < data.length - 1 ? 1 : 0,
        }}
      ></div>
      {currentIndex !== 0 && (
        <button
          onClick={goToPrevious}
          className={`absolute left-10 top-1/2 transform -translate-y-1/2 p-2 bg-paper rounded-full focus:outline-none z-20 hover:bg-gray-50 active:bg-gray-50 active:shadow-carouselBtnActive w-8 h-8 shadow-carouselBtn`}
          disabled={currentIndex === 0 || isTransitioning}
          // style={{
          //   boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.24)",
          // }}
        >
          <ArrowLeft />
        </button>
      )}
      {currentIndex !== data.length - 1 && (
        <button
          onClick={goToNext}
          className={`absolute right-10 top-1/2 transform -translate-y-1/2 p-2 bg-paper rounded-full focus:outline-none z-20  w-8 h-8 shadow-carouselBtn active:shadow-carouselBtnActive`}
          disabled={currentIndex === data.length - 1 || isTransitioning}
        >
          <ArrowRight />
        </button>
      )}
    </div>
  );
};

export default Carousel;
