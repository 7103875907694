import React from "react";
import { useConfig } from "contexts/ConfigContext";
import { ReactComponent as SelectedCheck } from "assets/images/product-selected-check.svg";
import { ReactComponent as HoverCheck } from "assets/images/product-selection-hover.svg";

// interface Product {
//   id: number;
//   title: string;
//   subtitle?: string;
//   price?: number;
//   image: string;
//   thirdLine?: string;
// }

export interface SelectableCardProduct {
  image_url: string | null;
  info_l1: string;
  info_l2: string | number;
  order_id: string | number;
  product_url: string | null;
  title: string;
}

interface SelectableCardItemProps {
  product: SelectableCardProduct;
  isSelected: boolean;
  onSelect: (id: number | string) => void;
  isHovered: boolean;
  onHover: () => void;
  onLeave: () => void;
}

const SelectableCardItem: React.FC<SelectableCardItemProps> = ({
  product,
  isSelected,
  onSelect,
  isHovered,
  onHover,
  onLeave,
}) => {
  const { config } = useConfig();

  return (
    <div
      className={`flex items-start  transition-all duration-300 max-h-[80px] relative cursor-pointer group bg-white first:rounded-t-lg last:rounded-b-lg last:border-b-0 first:border-t-0 ${
        isHovered && !isSelected ? "bg-ink-alpha-50" : ""
      }`}
      onClick={() => onSelect(product.order_id)}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {isSelected && (
        <div className="absolute left-0 right-0 h-full bg-green-800/[0.12] z-20"></div>
      )}
      {!isSelected && isHovered && (
        <div className="absolute left-0 right-0 h-full bg-gray-800/[0.12] z-20"></div>
      )}
      <div className="relative w-[80px] h-[79px] mr-4 flex-shrink-0">
        <img
          src={product.image_url || ""}
          alt={product.title}
          className={`w-full h-full object-cover group-first:rounded-t-lg group-last:rounded-b-lg`}
        />
        {isSelected && (
          <>
            <div className="absolute left-0 right-0 top-0 h-full bg-green-800/[0.48] z-20"></div>
            <div className="absolute left-0 right-0 top-0 bottom-0  rounded-full flex items-center justify-center shadow z-30">
              <div className="w-6 h-6 ">
                <SelectedCheck />
              </div>
              {/* <svg
                className="w-4 h-4 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg> */}
            </div>
          </>
        )}
        {!isSelected && isHovered && (
          <>
            <div className="absolute left-0 right-0 top-0 h-full bg-gray-800/[0.48] z-20"></div>
            <div className="absolute left-0 right-0 top-0 bottom-0  rounded-full flex items-center justify-center shadow z-30">
              <div className="w-6 h-6">
                <HoverCheck />
              </div>
              {/* <svg
                className="w-4 h-4 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg> */}
            </div>
          </>
        )}
      </div>
      <div className="flex-grow ">
        <div className="flex flex-col p-4">
          <h3
            className={` text-sm font-semibold  flex flex-row items-center gap-1 cursor-pointer ${
              isHovered && !isSelected ? "" : "text-ink"
            }`}
            style={{
              color:
                isHovered && !isSelected ? config?.colors?.brand_color : "",
            }}
          >
            {product.title}
          </h3>
          {product.info_l1 && (
            <p className="text-xs text-gray-800">{product.info_l1}</p>
          )}
          {product.info_l2 && (
            <p className="text-xs text-gray-800">{product.info_l2}</p>
          )}
          {/* {product.price !== undefined && (
          <p className="text-lg font-semibold text-gray-900 mt-1">
            ${product.price.toFixed(2)}
          </p>
        )} */}
        </div>
      </div>
    </div>
  );
};

export default SelectableCardItem;
