import React, { useState, ChangeEvent, useEffect, useMemo } from "react";
import AutomationInput from "./AutomationInput";
import AutomationTextArea from "./AutomationTextArea";
import AutomationInputCheckbox from "./AutomationInputCheckbox";
import AutomationInputRadio from "components/AutomationInputRadio";
import { ReactComponent as AiSparkle } from "assets/images/ai-sparkle.svg";
import { ReactComponent as AiSparkleDisabled } from "assets/images/ai-sparkle-disabled.svg";
import {
  DocumentMetadata,
  GoogleUserProfile,
  WebSocketMessage,
  Workflow,
  WorksheetMetadata,
} from "components/ChatbotUIClientSdk";
import {
  generateRandomString,
  getFileExtension,
  hexToRgbA,
  truncateWords,
} from "utils/Misc";
import { AllAutomationWorkflow } from "components/TaskItem";
import { uploadFileOnPresignedUrl } from "apiService";
import { FileUploadPayload } from "components/AutomationInputForm";
import OutputSelectConfig from "components/OutputConfigSelect";
import { useConfig } from "contexts/ConfigContext";
import { ReactComponent as GoogleSheetsIcon } from "assets/images/google-sheets-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/images/delete-icon.svg";
import { ReactComponent as CheckGreen } from "assets/images/check-green-dynamic-color.svg";
import SelectBox from "components/Library/SelectBox";
import { Player } from "@lottiefiles/react-lottie-player";
import Loader from "assets/lotties/loader-dark-animation.json";
import FormFooter from "components/FormFooter";
import StepIndicator from "components/StepIndicator";
import AuthModal from "components/AuthModal";

// interface InputField {
//   label: string;
//   step_id: string;
//   tag_name: string;
//   event_type: string;
//   input_type: string | null;
//   ai_generated?: boolean;
//   possible_values?: string[];
// }

type FormValues = {
  [key: string]: string | boolean | string[] | File | null | FileUploadPayload;
};
enum LoaderPoint {
  CSV = "CSV",
  GOOGLE_SHEET = "Google Sheet",
}

const OutputOptionsDisplayMap: Record<string, string> = {
  CSV: "CSV",
  GOOGLE_SHEETS: "Google Sheets",
};

interface MultiStepFormProps {
  inputs: any[];
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  errors: { [key: string]: string };
  handleChange: (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLSelectElement>
  ) => void;
  handleSubmit: () => void;
  validateForm: () => boolean;
  onCancel: () => void;
  genInProgress: boolean;
  handleGenAIChange: (fieldName: string) => void;
  websocket?: WebSocket;
  sendMessageToWs?: Function;
  getBasePayload?: Function;
  currentWorkflow?: AllAutomationWorkflow | Workflow;
  submitInProgress?: boolean;
  isLoading?: boolean;
}

const addOrUpdateMapping = (
  mappings: { input_header: string; template_header: string }[],
  newMapping: { input_header: string; template_header: string }
) => {
  const existingMappingIndex = mappings.findIndex(
    (mapping) => mapping.template_header === newMapping.template_header
  );
  console.log("newMapping", newMapping, existingMappingIndex);

  // Check if the newMapping's input_header is empty, null, or undefined
  if (!newMapping.input_header) {
    // If newMapping's input_header is invalid, remove it from the array if it exists
    if (existingMappingIndex !== -1) {
      const updatedMappings = [...mappings];
      updatedMappings.splice(existingMappingIndex, 1); // Remove the existing mapping
      console.log("updatedMappings after removal", updatedMappings);
      return updatedMappings;
    }
    // If it doesn't exist, return the mappings as is
    return mappings;
  }

  if (existingMappingIndex !== -1) {
    // Update the existing mapping
    const updatedMappings = [...mappings];
    updatedMappings[existingMappingIndex] = newMapping;
    console.log("updatedMappings", updatedMappings);
    return updatedMappings;
  } else {
    // Add the new mapping
    return [...mappings, newMapping];
  }
};

const MultiStepForm: React.FC<MultiStepFormProps> = ({
  inputs,
  formValues,
  setFormValues,
  errors,
  handleChange,
  handleSubmit,
  validateForm,
  onCancel,
  genInProgress,
  handleGenAIChange,
  websocket,
  sendMessageToWs,
  getBasePayload,
  currentWorkflow,
  submitInProgress,
  isLoading,
}) => {
  const [step, setStep] = useState(1);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [googleSheetConnecting, setGoogleSheetConnecting] = useState(false);
  const [currentTrackerId, setCurrentTrackerId] = useState<string>();
  const [loader, setLoader] = useState<LoaderPoint>();
  const [currentCsvfile, setCurrentCsvfile] = useState<File>();
  const [fileRowHeaders, setFileRowHeaders] = useState<string[]>([]);
  const [stepErrors, setStepErrors] = useState<{ [key: string]: any }>();
  const [googleUserInfo, setGoogleUserInfo] = useState<GoogleUserProfile>();
  const [googleSheetsList, setGoogleSheetsList] =
    useState<DocumentMetadata[]>();
  const [googleWorksheetsList, setGoogleWorksheetsList] =
    useState<WorksheetMetadata[]>();
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});
  const { config } = useConfig();

  const [showGoogleSheetSection, setShowGoogleSheetSection] =
    useState<boolean>(false);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [authUrl, setAuthUrl] = useState<string>();

  const [selectedGoogleSheet, setSelectedGoogleSheet] = useState<string>();
  const [selectedWorkSheet, setSelectedWorkSheet] = useState<number>();
  const [outputFileType, setOutputFileType] = useState<string>("");
  const [authSuccess, setAuthSuccess] = useState(false);
  const [authWindowId, setAuthWindowId] = useState<string | null>(null);

  console.log("selectedWorkSheet", selectedWorkSheet);
  const nextStep = () => {
    setStep((prevStep) => {
      const stepNext = prevStep + 1;
      if (prevStep === 1 && validateForm && validateForm()) {
        return stepNext;
      }
      if (prevStep > 1 && validateFileSteps()) {
        return stepNext;
      }
      return prevStep;
    });
  };
  const prevStep = () => setStep(step - 1);

  const validateFileSteps = () => {
    let valid = true;
    let newErrors = {};
    for (const [key, value] of Object.entries(formValues)) {
      const field = value as FileUploadPayload;
      console.log("field===>", field);
      if (field?.isFileUploadField) {
        if (!field?.input_config?.source) {
          newErrors = {
            ...newErrors,
            input_config: "Please upload a file",
          };
          valid = false;
        }

        if (field?.input_config?.source && !field?.output_config?.source) {
          newErrors = {
            ...newErrors,
            output_config: "Required field",
          };
          valid = false;
        }

        if (
          field?.input_config?.source === "GOOGLE_SHEETS" &&
          !field?.input_config?.input_sheets_config?.sheet_id
        ) {
          newErrors = {
            ...newErrors,
            sheet_id: "Please select a sheet",
          };
          valid = false;
        }
        console.log("field?.input_config?", field?.input_config);
        if (
          field?.input_config?.source === "GOOGLE_SHEETS" &&
          field?.input_config?.input_sheets_config?.sheet_id &&
          (field?.input_config?.input_sheets_config?.worksheet_id ===
            undefined ||
            field?.input_config?.input_sheets_config?.worksheet_id === null)
        ) {
          newErrors = {
            ...newErrors,
            worksheet_id: "Please select a worksheet",
          };
          valid = false;
        }

        if (
          field?.input_config?.source === "CSV" &&
          !field?.input_config?.file_tracker_id
        ) {
          newErrors = {
            ...newErrors,
            csv_file: "Please upload a csv file",
          };
          valid = false;
        }
        if (
          field?.input_config?.source &&
          field?.output_config?.source &&
          field?.output_config?.source === "GOOGLE_SHEETS" &&
          !formValues?.googleSheetConnected
        ) {
          newErrors = {
            ...newErrors,
            output_config_connection:
              "Please connect your google sheets account",
          };
          valid = false;
        }
        // if (
        //   field?.input_config?.source &&
        //   field?.output_config?.source &&
        //   step > 2
        // ) {
        //   if (
        //     !field?.mappings ||
        //     field?.mappings?.length === 0 ||
        //     field?.mappings?.every((mapping) => !mapping?.input_header)
        //   ) {
        //     newErrors = {
        //       ...newErrors,
        //       mappings: "Please select at least one mapping",
        //     };
        //     valid = false;
        //   }
        // }
      }

      // if (!value || (Array.isArray(value) && value.length === 0)) {
      //   newErrors = {
      //     ...newErrors,
      //     [key]: "Required field",
      //   };
      //   valid = false;
      // }
    }
    // if (Object.entries(formValues).length === 0 || !formValues) {
    //   inputs?.forEach((input) => {
    //     newErrors = {
    //       ...newErrors,
    //       [input?.input_id]: "Required field",
    //     };
    //   });
    //   valid = false;
    // }
    // if (!taskSummary?.trim()) {
    //   newErrors = {
    //     ...newErrors,
    //     taskSummary: "Required field",
    //   };
    //   valid = false;
    // }
    console.log("newErrors", newErrors);
    setStepErrors(newErrors);
    return valid;
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const allowedMimeTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const file = e?.target?.files?.[0];
    if (file && allowedMimeTypes.includes(file?.type)) {
      setFormValues({ ...formValues, csvFile: file });
      const fileNameGenerated = generateRandomString(6);
      const extension = getFileExtension(file?.name);
      getFileUploadUrl(file?.name || fileNameGenerated + "." + extension);
    }
  };

  const handleFileRemove = () => {
    setFormValues({ ...formValues, csvFile: null });
    setFileUploaded(false);
    setStepErrors({ ...stepErrors, input_config: undefined });
  };

  const handleGoogleSheetConnect = () => {
    setGoogleSheetConnecting(true);
    checkGoogleSheetConnection();
    // Simulate async connection to Google Sheets
    // setTimeout(() => {
    //   setFormValues({ ...formValues, googleSheetConnected: true });
    //   setGoogleSheetConnecting(false);
    // }, 2000);
  };

  const handleGoogleSheetDisconnect = () => {
    setShowGoogleSheetSection(false);
    sendGoogleDisconnectionRequest();
    setFormValues({ ...formValues });
  };

  const handleGoogleSheetsSelectChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    const sheet_id = e?.target?.value;
    setSelectedGoogleSheet(sheet_id);
    setSelectedWorkSheet(undefined);
    setFileRowHeaders([]);
    setOutputFileType("CSV");
    const existingFormValues = { ...formValues };
    const uploadFileField: FileUploadPayload | undefined = Object.values(
      existingFormValues
    ).find((field) =>
      (field as FileUploadPayload)?.isFileUploadField ? true : false
    ) as FileUploadPayload | undefined;
    if (uploadFileField && uploadFileField.input_id) {
      setFormValues({
        ...existingFormValues,
        [uploadFileField.input_id]: {
          ...uploadFileField,
          input_config: {
            ...uploadFileField?.input_config,
            source: "GOOGLE_SHEETS",
            file_tracker_id: undefined,
            input_sheets_config: {
              sheet_id: sheet_id,
              worksheet_id: undefined,
            },
          },
          output_config: {
            ...uploadFileField?.output_config,
            source: "CSV",
          },
        },
      });
    }
    if (sheet_id) {
      getGoogleWorksheetsList(sheet_id);
    }
  };

  const handleGoogleWorksheetsSelectChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    const worksheetId = e?.target?.value ? Number(e?.target?.value) : undefined;
    setSelectedWorkSheet(worksheetId);
    setFileRowHeaders([]);
    const existingFormValues = { ...formValues };
    const uploadFileField: FileUploadPayload | undefined = Object.values(
      existingFormValues
    ).find((field) =>
      (field as FileUploadPayload)?.isFileUploadField ? true : false
    ) as FileUploadPayload | undefined;
    if (uploadFileField && uploadFileField.input_id) {
      setFormValues({
        ...existingFormValues,
        [uploadFileField.input_id]: {
          ...uploadFileField,
          input_config: {
            ...uploadFileField?.input_config,
            source: "GOOGLE_SHEETS",
            file_tracker_id: undefined,
            input_sheets_config: {
              ...uploadFileField?.input_config?.input_sheets_config,
              worksheet_id: worksheetId,
            },
          },
        },
      });
    }
    if (selectedGoogleSheet && worksheetId !== undefined) {
      getGoogleSheetsRowHeaders(selectedGoogleSheet, worksheetId);
    }
  };

  const handleRowheaderSelectChange = (header: string, value: string) => {
    setSelectedOptions((prevSelectedOptions) => {
      console.log("prevSelectedOptions", prevSelectedOptions, header, value);
      return {
        ...prevSelectedOptions,
        [header]: value,
      };
    });
    const existingFormValues = { ...formValues };
    const uploadFileField: FileUploadPayload | undefined = Object.values(
      existingFormValues
    ).find((field) =>
      (field as FileUploadPayload)?.isFileUploadField ? true : false
    ) as FileUploadPayload | undefined;
    if (uploadFileField && uploadFileField.input_id) {
      setFormValues({
        ...existingFormValues,
        [uploadFileField.input_id]: {
          ...uploadFileField,
          mappings: addOrUpdateMapping(uploadFileField.mappings, {
            template_header: header,
            input_header: value,
          }),
        },
      });
    }
  };

  const getAvailableOptions = (currentHeader: string) => {
    const selectedValues = Object.values(selectedOptions);
    return fileRowHeaders.filter(
      (option) =>
        option === selectedOptions[currentHeader] ||
        !selectedValues.includes(option)
    );
  };

  const checkHasInputs = (inputFields: any) => {
    return inputFields?.some(
      (field: any) => field.input_type !== "FILE_UPLOAD"
    );
  };

  const hasInputs = useMemo(() => {
    return inputs ? checkHasInputs(inputs) : true;
  }, [inputs]);

  useEffect(() => {
    if (inputs && !hasInputs) {
      setStep(2);
    }
  }, [hasInputs, inputs]);

  console.log("stepErrors", stepErrors);
  const renderStepContent = () => {
    if (step === 1) {
      return (
        <div className="relative">
          {/* <h4 className="font-medium mb-2">Inputs</h4> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {inputs
              .filter((input) => input.input_type !== "FILE_UPLOAD")
              .map((InputField, index) => {
                return InputField?.ai_generated ? (
                  <AutomationTextArea
                    key={index}
                    rows={6}
                    value={formValues?.[InputField?.input_id] as string}
                    onChange={handleChange}
                    name={InputField?.input_id}
                    error={errors?.[InputField?.input_id]}
                    label={
                      <div className="w-full flex flex-row items-center justify-between">
                        <div>{InputField?.label || ""}</div>
                        <div
                          className={`flex flex-row items-center gap-0.5 cursor-pointer ${
                            genInProgress ? "pointer-events-none" : ""
                          } ${
                            !formValues?.[InputField?.input_id]
                              ? "pointer-events-none text-['#8D9196']"
                              : ""
                          }`}
                          onClick={() =>
                            handleGenAIChange(InputField?.input_id)
                          }
                        >
                          <div
                            className={`text-semibold text-xs font-semibold ${
                              !formValues?.[InputField?.input_id]
                                ? "text-[#8D9196]"
                                : "text-robylon-pink"
                            }`}
                          >
                            {genInProgress
                              ? "Rephrasing...."
                              : "Rephrase with AI"}
                          </div>
                          {!genInProgress && (
                            <div className="w-4 h-4">
                              {!!formValues?.[InputField?.input_id] && (
                                <AiSparkle />
                              )}
                              {!formValues?.[InputField?.input_id] && (
                                <AiSparkleDisabled />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  />
                ) : (
                  <div key={index}>
                    {(InputField?.input_type === "text" ||
                      InputField?.input_type === "password" ||
                      InputField?.input_type === "email") && (
                      <AutomationInput
                        placeholder="Type here..."
                        label={InputField?.label || ""}
                        type="text"
                        name={InputField?.input_id}
                        value={formValues?.[InputField?.input_id] as string}
                        error={errors?.[InputField?.input_id]}
                        onChange={handleChange}
                      />
                    )}
                    {InputField?.input_type === "checkbox" && (
                      <AutomationInputCheckbox
                        possbileValues={InputField?.possible_values}
                        label={InputField?.label || ""}
                        name={InputField?.input_id}
                        value={formValues?.[InputField?.input_id] as string[]}
                        error={errors?.[InputField?.input_id]}
                        onChange={handleChange}
                      />
                    )}
                    {InputField?.input_type === "radio" && (
                      <AutomationInputRadio
                        possbileValues={InputField?.possible_values}
                        label={InputField?.label || ""}
                        name={InputField?.input_id}
                        value={formValues?.[InputField?.input_id] as string}
                        error={errors?.[InputField?.input_id]}
                        onChange={handleChange}
                      />
                    )}
                    {InputField?.input_type === "textarea" && (
                      <AutomationTextArea
                        key={index}
                        rows={6}
                        value={formValues?.[InputField?.input_id] as string}
                        onChange={handleChange}
                        name={InputField?.input_id}
                        error={errors?.[InputField?.input_id]}
                        label={InputField?.label || ""}
                      />
                    )}
                  </div>
                );
              })}
          </div>
          <FormFooter
            nextStep={nextStep}
            onCancel={onCancel}
            cancelBtnTitle="Cancel"
          />
        </div>
      );
    }
    if (step === 2 && !formValues.csvFile && !showGoogleSheetSection) {
      return inputs
        .filter((input) => input.input_type === "FILE_UPLOAD")
        .map((InputField, index) => {
          return (
            <div>
              <div className="text-sm mb-4 text-gray-900">
                {InputField?.label}
              </div>
              <div
                className={`p-4 rounded-lg   border-dashed border justify-start items-center gap-3 flex flex-row ${
                  !!stepErrors?.input_config
                    ? "border-red-500"
                    : "border-gray-600"
                }`}
              >
                <label
                  className={`flex items-center justify-center px-3 py-1.5  rounded-lg cursor-pointer grow font-semibold text-sm ${
                    !config?.colors?.brand_color ? "bg-gray-600" : ""
                  }`}
                  style={{
                    backgroundColor: config?.colors?.brand_color
                      ? config?.colors?.brand_color
                      : "",
                    color: config?.colors?.brand_color ? "#ffffff" : "#ffffff",
                  }}
                >
                  <input
                    type="file"
                    className="hidden"
                    name="csvFile"
                    onChange={handleFileUpload}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <span>Upload CSV</span>
                </label>
                <div className="text-gray-800 text-xs">OR</div>
                <button
                  style={{
                    borderColor: config?.colors?.brand_color
                      ? config?.colors?.brand_color
                      : "",
                    color: config?.colors?.brand_color
                      ? config?.colors?.brand_color
                      : "#ffffff",
                  }}
                  type="button"
                  className={`flex items-center  border gap-1 justify-center px-3 py-1.5  rounded-lg cursor-pointer grow font-semibold text-sm ${
                    !config?.colors?.brand_color ? "border-gray-600" : ""
                  }`}
                  onClick={() => {
                    if (!formValues?.googleSheetConnected) {
                      // handleGoogleSheetConnect();
                      getGoogleAuthUrl();
                    }
                    setShowGoogleSheetSection(true);
                  }}
                  // disabled={googleSheetConnecting}
                >
                  <div className="w-[13px] h-[17px]">
                    <GoogleSheetsIcon />
                  </div>
                  <span>Google Sheets</span>
                </button>
              </div>
              {!!stepErrors?.input_config && (
                <div className="text-robylon-error text-xs px-1 py-1">
                  {stepErrors?.input_config}
                </div>
              )}
              <FormFooter
                nextStep={nextStep}
                onCancel={hasInputs ? prevStep : onCancel}
                cancelBtnTitle={hasInputs ? undefined : "Cancel"}
              />
            </div>
          );
        });
    }
    if (step === 2 && showGoogleSheetSection && googleSheetConnecting) {
      return inputs
        .filter((input) => input.input_type === "FILE_UPLOAD")
        .map((InputField, index) => {
          return (
            <div>
              <div className="text-sm mb-4 text-gray-900">
                {InputField?.label}
              </div>
              <div className="p-4 rounded-lg  border-gray-600 border-dashed border justify-between items-center gap-3 flex flex-row ">
                <div className="flex items-center gap-2">
                  <div className="w-[21px] h-[29px]">
                    <GoogleSheetsIcon />
                  </div>
                  <span className="text-gray-800 text-xs">
                    Connecting to Google Sheets...
                  </span>
                </div>
                <button
                  type="button"
                  className={`ml-1 text-sm font-semibold`}
                  style={{ color: config?.colors?.brand_color || "" }}
                  onClick={() => setShowGoogleSheetSection(false)}
                >
                  Cancel
                </button>
              </div>
              <FormFooter
                nextStep={nextStep}
                onCancel={hasInputs ? prevStep : onCancel}
              />
            </div>
          );
        });
    }
    if (
      step === 2 &&
      showGoogleSheetSection &&
      formValues.googleSheetConnected
    ) {
      return inputs
        .filter((input) => input.input_type === "FILE_UPLOAD")
        .map((InputField, index) => {
          return (
            <div>
              <div className="text-sm mb-4 text-gray-900">
                {InputField?.label}
              </div>
              <div className="border border-gray-600 rounded-lg pb-1">
                <div className="flex items-center border-b border-dashed border-gray-600 bg-gray-100 p-4 gap-3 justify-between rounded-t-lg">
                  <div className="flex items-center gap-3">
                    <div className="w-[21px] h-[29px]">
                      <GoogleSheetsIcon />
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-1">
                        <span className="text-gray-900 text-sm font-semibold">
                          Sheets Connected
                        </span>
                        <div className="w-5 h-5 text-green-600">
                          <CheckGreen />
                        </div>
                      </div>
                      {!!googleUserInfo?.email && (
                        <span className="text-xs text-gray-900">
                          {googleUserInfo?.email}
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="text-gray-500"
                    onClick={handleGoogleSheetDisconnect}
                  >
                    <div className="w-5 h-5 text-gray-900">
                      <DeleteIcon />
                    </div>
                  </button>
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 pt-4 pb-5">
                  <div className="px-4">
                    <SelectBox
                      label="Spreadsheet"
                      name="spreadsheetName"
                      selectedValue={selectedGoogleSheet}
                      onChange={handleGoogleSheetsSelectChange}
                      options={googleSheetsList}
                      placeholder="Select Spreadsheet..."
                      error={stepErrors?.sheet_id}
                    />
                  </div>
                  <div className="px-4">
                    <SelectBox
                      disabled={!selectedGoogleSheet}
                      label="Worksheet"
                      name="worksheetName"
                      selectedValue={selectedWorkSheet ?? ""}
                      onChange={handleGoogleWorksheetsSelectChange}
                      options={googleWorksheetsList}
                      placeholder="Select Worksheet..."
                      keyMapping={{ idKey: "id", nameKey: "title" }}
                      error={stepErrors?.worksheet_id}
                    />
                  </div>
                </div>
              </div>
              <div>
                {selectedWorkSheet !== undefined && (
                  <OutputSelectConfig
                    InputField={InputField}
                    OutputOptionsDisplayMap={OutputOptionsDisplayMap}
                    formValues={formValues}
                    stepErrors={stepErrors}
                    handleOutputConfigChange={handleOutputConfigChange}
                    googleUserInfo={googleUserInfo}
                    handleGoogleSheetConnect={getGoogleAuthUrl}
                    outputFileType={outputFileType}
                    handleGoogleSheetDisconnect={handleGoogleSheetDisconnect}
                  />
                )}
              </div>
              <FormFooter
                nextStep={nextStep}
                onCancel={hasInputs ? prevStep : onCancel}
              />
            </div>
          );
        });
    }
    if (step === 2 && formValues.csvFile) {
      return inputs
        .filter((input) => input.input_type === "FILE_UPLOAD")
        .map((InputField) => {
          return (
            <div>
              <h4 className="font-medium mb-2">Upload</h4>
              <div>
                <div className="flex items-center  border-gray-600 border bg-gray-100 p-4 gap-3 justify-between rounded-lg">
                  <div className="flex items-center gap-3">
                    <div className="w-[21px] h-[29px]">
                      <GoogleSheetsIcon />
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-2">
                        <span className="text-gray-900 text-sm font-semibold ">
                          {truncateWords(
                            (formValues.csvFile as File)?.name,
                            19
                          )}
                        </span>
                        <div className="w-5 h-5 text-green-600">
                          <CheckGreen />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="text-gray-900"
                    onClick={handleFileRemove}
                  >
                    <div className="w-5 h-5">
                      <DeleteIcon />
                    </div>
                  </button>
                </div>
                <div>
                  <OutputSelectConfig
                    InputField={InputField}
                    OutputOptionsDisplayMap={OutputOptionsDisplayMap}
                    formValues={formValues}
                    stepErrors={stepErrors}
                    handleOutputConfigChange={handleOutputConfigChange}
                    googleUserInfo={googleUserInfo}
                    handleGoogleSheetConnect={getGoogleAuthUrl}
                    outputFileType={outputFileType}
                    handleGoogleSheetDisconnect={handleGoogleSheetDisconnect}
                  />
                </div>
              </div>
              <FormFooter
                nextStep={nextStep}
                onCancel={hasInputs ? prevStep : onCancel}
              />
            </div>
          );
        });
    }
    if (step === 3) {
      return (
        <div>
          <label className="block text-ink text-sm font-semibold">
            Map Columns Headers
          </label>
          <p className="text-sm text-gray-800 mt-1 mb-4">
            Map the columns of uploaded file to appropriate formatter variables
          </p>
          {inputs
            .filter((input) => input.input_type === "FILE_UPLOAD")
            .map((InputField) => {
              return (
                <div>
                  <div>
                    <div>
                      {!!stepErrors?.mappings && (
                        <div className="text-robylon-error text-xs px-1 pl-2 bg-red-50 rounded-md py-1">
                          {stepErrors?.mappings}
                        </div>
                      )}
                      <div className="flex flex-col gap-6 pt-6">
                        {InputField.file_upload_config.headers.map(
                          (header: string) => (
                            <div key={header}>
                              <SelectBox
                                name={header}
                                label={
                                  <span>
                                    Column for{" "}
                                    <span
                                      className="font-semibold text-sm text-ink px-2 ml-1 py-0.5 rounded"
                                      style={{
                                        backgroundColor: config?.colors
                                          ?.brand_color
                                          ? hexToRgbA(
                                              config?.colors?.brand_color,
                                              0.08
                                            )
                                          : "",
                                      }}
                                    >
                                      {header}
                                    </span>
                                  </span>
                                }
                                options={getAvailableOptions(header).map(
                                  (option) => ({
                                    id: option,
                                    name: option,
                                  })
                                )}
                                selectedValue={selectedOptions[header]}
                                onChange={(e) =>
                                  handleRowheaderSelectChange(
                                    header,
                                    e.target.value
                                  )
                                }
                                placeholder="Select Column"
                                keyMapping={{ idKey: "id", nameKey: "name" }}
                              />
                              {/* <label>Column for {header}</label>
                              <select
                                className="w-full px-4 py-2 border rounded-lg"
                                name={header}
                                value={selectedOptions[header] || ""}
                                onChange={(e) =>
                                  handleRowheaderSelectChange(
                                    header,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select Column</option>
                                {getAvailableOptions(header).map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select> */}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="fixed bottom-0 left-0 right-0 bg-white border-t p-4 flex justify-between gap-4"
                    style={{
                      boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.16)",
                    }}
                  >
                    <button
                      style={{
                        borderColor: config?.colors?.brand_color || "",
                        color: config?.colors?.brand_color || "",
                      }}
                      type="button"
                      className="basis-1/2 py-2 px-4 border rounded-md font-semibold text-base"
                      onClick={prevStep}
                    >
                      Go Back
                    </button>
                    <button
                      style={{
                        background: config?.colors?.brand_color || "",
                      }}
                      type="submit"
                      className={`py-2 px-4 basis-1/2 text-white ${
                        !config?.colors?.brand_color ? "" : "bg-robylon-blue"
                      } rounded-md font-semibold  text-base ${
                        isLoading &&
                        "opacity-50 cursor-not-allowed disabled:bg-inkAlpha-100"
                      }`}
                      disabled={submitInProgress || genInProgress}
                    >
                      {!submitInProgress ? (
                        "Execute"
                      ) : (
                        <Player
                          autoplay
                          src={Loader}
                          loop={true}
                          style={{ height: "20px", width: "20px" }}
                        ></Player>
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      );
    }
    return null;
  };

  const handleFileUploadUrlComplete = async (message: WebSocketMessage) => {
    if (message?.input_info) {
      const { tracker_id, presigned_url } = message?.input_info;
      setCurrentTrackerId(tracker_id);
      if (presigned_url && formValues?.csvFile) {
        try {
          await uploadFileOnPresignedUrl(
            presigned_url,
            formValues?.csvFile as File
          );
          validateFileUpload(tracker_id);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  const handleFileUploadValidateComplete = (message: WebSocketMessage) => {
    setFileUploaded(true);
    if (message?.validation_info?.headers) {
      setFileRowHeaders(message?.validation_info?.headers);
    }
    const existingFormValues = { ...formValues };
    const uploadFileField: FileUploadPayload | undefined = Object.values(
      existingFormValues
    ).find((field) =>
      (field as FileUploadPayload)?.isFileUploadField ? true : false
    ) as FileUploadPayload | undefined;
    if (uploadFileField && uploadFileField.input_id) {
      setFormValues({
        ...existingFormValues,
        [uploadFileField.input_id]: {
          ...uploadFileField,
          input_config: {
            ...uploadFileField?.input_config,
            source: "CSV",
            file_tracker_id: currentTrackerId,
            input_sheets_config: {},
          },
          output_config: {
            source: "CSV",
            output_sheets_config: {
              action: "NEW_SPREADSHEET",
            },
          },
        },
      });
    }
  };

  const handleOutputConfigChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const existingFormValues = { ...formValues };
    setOutputFileType(e?.target?.value);
    setStepErrors(undefined);
    const uploadFileField: FileUploadPayload | undefined = Object.values(
      existingFormValues
    ).find((field) =>
      (field as FileUploadPayload)?.isFileUploadField ? true : false
    ) as FileUploadPayload | undefined;
    if (uploadFileField && uploadFileField.input_id) {
      setFormValues({
        ...existingFormValues,
        [uploadFileField.input_id]: {
          ...uploadFileField,
          output_config: {
            ...uploadFileField?.output_config,
            source: e?.target?.value,
          },
        },
      });
    }
  };

  const handleGoogleSheetsAuthConnectedComplete = (
    message: WebSocketMessage
  ) => {
    if (message?.connected) {
      setFormValues({ ...formValues, googleSheetConnected: true });
      if (message?.user_info) {
        setGoogleUserInfo(message?.user_info);
      }
      getGoogleSheetsList();
      setGoogleSheetConnecting(false);
      setShowAuthModal(false);
      setAuthWindowId(null);
    }
  };

  const handleGoogleSheetsAuthUrlComplete = (message: WebSocketMessage) => {
    console.log("message handleGoogleSheetsAuthUrlComplete", message);
    if (message?.authorization_url) {
      // window.open(message?.authorization_url, "_blank");
      const id = generateRandomString(10);
      setAuthWindowId(id);
      setAuthUrl(message?.authorization_url);
      setShowAuthModal(true);
    }
  };
  const handleGoogleSheetsListComplete = (message: WebSocketMessage) => {
    console.log("message handleGoogleSheetsAuthUrlComplete", message);
    if (message?.spreadsheets) {
      setGoogleSheetsList(message?.spreadsheets);
    }
  };

  const handleGoogleSheetsListWorksheetsComplete = (
    message: WebSocketMessage
  ) => {
    console.log("message handleGoogleSheetsAuthUrlComplete", message);
    if (message?.worksheets) {
      setGoogleWorksheetsList(message?.worksheets);
    }
  };

  const handleGoogleSheetsHeadersComplete = (message: WebSocketMessage) => {
    setFileUploaded(true);
    if (message?.worksheet_headers) {
      setFileRowHeaders(message?.worksheet_headers);
    }
  };

  const handleGoogleSheetsDisconnectComplete = (message: WebSocketMessage) => {
    if (message && message?.status) {
      setFormValues({ ...formValues, googleSheetConnected: false });
    }
  };

  const handleSocketMessage = (event: MessageEvent) => {
    const message: WebSocketMessage = JSON.parse(event.data);
    switch (message.event_type) {
      case "file_upload_url_complete":
        handleFileUploadUrlComplete(message);
        break;
      case "file_upload_validate_complete":
        handleFileUploadValidateComplete(message);
        break;
      case "google_sheets_auth_connected_complete":
        handleGoogleSheetsAuthConnectedComplete(message);
        break;
      case "google_sheets_auth_url_complete":
        handleGoogleSheetsAuthUrlComplete(message);
        break;
      case "google_sheets_list_sheets_complete":
        handleGoogleSheetsListComplete(message);
        break;
      case "google_sheets_list_worksheets_complete":
        handleGoogleSheetsListWorksheetsComplete(message);
        break;
      case "google_sheets_headers_complete":
        handleGoogleSheetsHeadersComplete(message);
        break;
      case "google_sheets_disconnect_complete":
        handleGoogleSheetsDisconnectComplete(message);
        break;
      default:
        return;
    }
  };

  const getGoogleSheetsRowHeaders = (
    sheet_id: string,
    worksheet_id: number
  ) => {
    if (sendMessageToWs && getBasePayload) {
      let payload = getBasePayload("google_sheets_headers");
      if (currentWorkflow) {
        payload = {
          ...payload,
          workflow_id: currentWorkflow?.workflow_id,
          sheet_id: sheet_id,
          worksheet_id: worksheet_id,
        };
      }
      sendMessageToWs(payload);
    }
  };

  const getFileUploadUrl = (fileName: string) => {
    if (sendMessageToWs && getBasePayload) {
      let payload = getBasePayload("file_upload_url");
      if (fileName) {
        payload = {
          ...payload,
          file_name: fileName,
          workflow_id: currentWorkflow?.workflow_id,
        };
      }
      sendMessageToWs(payload);
    }
  };
  const sendGoogleDisconnectionRequest = () => {
    if (sendMessageToWs && getBasePayload) {
      let payload = getBasePayload("google_sheets_disconnect");

      sendMessageToWs(payload);
    }
  };
  console.log("currentWorkflow outside", currentWorkflow);
  const validateFileUpload = (trackerId?: string) => {
    if (sendMessageToWs && getBasePayload) {
      let payload = getBasePayload("file_upload_validate");
      if (currentTrackerId || trackerId) {
        payload = {
          ...payload,
          tracker_id: currentTrackerId || trackerId,
          workflow_id: currentWorkflow?.workflow_id,
        };
        sendMessageToWs(payload);
      }
    }
    setOutputFileType("CSV");
  };

  const getGoogleAuthUrl = () => {
    setStepErrors({ ...stepErrors, output_config_connection: undefined });
    if (sendMessageToWs && getBasePayload) {
      let payload = getBasePayload("google_sheets_auth_url");
      if (currentWorkflow)
        payload = {
          ...payload,
          workflow_id: currentWorkflow?.workflow_id,
        };
      sendMessageToWs(payload);
    }
  };

  const getGoogleSheetsList = () => {
    if (sendMessageToWs && getBasePayload) {
      let payload = getBasePayload("google_sheets_list_sheets");
      if (currentWorkflow)
        payload = {
          ...payload,
          workflow_id: currentWorkflow?.workflow_id,
        };
      sendMessageToWs(payload);
    }
  };

  const getGoogleWorksheetsList = (sheet_id: string) => {
    if (sendMessageToWs && getBasePayload) {
      let payload = getBasePayload("google_sheets_list_worksheets");
      if (currentWorkflow && sheet_id)
        payload = {
          ...payload,
          workflow_id: currentWorkflow?.workflow_id,
          sheet_id: sheet_id,
        };
      sendMessageToWs(payload);
    }
  };

  const checkGoogleSheetConnection = () => {
    if (sendMessageToWs && getBasePayload) {
      let payload = getBasePayload("google_sheets_auth_connected");
      if (currentWorkflow) {
        payload = {
          ...payload,
          workflow_id: currentWorkflow?.workflow_id,
        };
      }
      sendMessageToWs(payload);
    }
  };

  useEffect(() => {
    console.log("websocket in multiStep form", websocket);
    if (websocket) {
      websocket.addEventListener("message", handleSocketMessage);
      return () => {
        websocket.removeEventListener("message", handleSocketMessage);
      };
    }
  }, [websocket, formValues, currentWorkflow, currentTrackerId]);

  useEffect(() => {
    if (step === 2) {
      handleGoogleSheetConnect();
    }
  }, [step]);

  useEffect(() => {
    const handleAuthMessage = (event: MessageEvent) => {
      // if (event.origin !== window.location.origin) return;
      if (
        event.data.authWindowId === authWindowId &&
        event.data.message === "authSuccess"
      ) {
        setAuthSuccess(true);
        setShowAuthModal(false);
      }
    };

    window.addEventListener("message", handleAuthMessage);

    return () => {
      window.removeEventListener("message", handleAuthMessage);
    };
  }, [authWindowId]);

  return (
    <>
      <div className="mb-4 flex items-center justify-between bg-gray-50 border border-gray-200 w-full  z-[10000] px-4 fixed top-[155px]">
        {!!hasInputs && (
          <>
            <div
              className={`flex items-center space-x-4 p-1.5 ${
                step >= 1 ? "text-ink" : "text-gray-800"
              } text-xs cursor-pointer`}
              onClick={() => setStep(1)}
            >
              Inputs
            </div>
            {step === 1 && (
              <div className="h-1.5 w-1.5 mr-1">
                <div className="h-1.5 w-1.5 rounded-full bg-green-500"></div>
              </div>
            )}
            <hr
              className={`${
                step > 1 ? "bg-green-500" : "bg-gray-500"
              } h-[2px] w-full`}
            />
          </>
        )}

        <>
          <div
            className={`flex items-center space-x-4 p-1.5 ${
              step >= 2 ? "text-ink" : "text-gray-800"
            } text-xs cursor-pointer`}
            onClick={() => {
              if (step >= 2) setStep(2);
            }}
          >
            Upload
          </div>
          {step === 2 && (
            <div className="h-1.5 w-1.5 mr-1">
              <div className="h-1.5 w-1.5 rounded-full bg-green-500"></div>
            </div>
          )}
          <hr
            className={`${
              step > 2 ? "bg-green-500" : "bg-gray-500"
            } h-[2px] w-full`}
          />
        </>
        <>
          <div
            className={`flex items-center space-x-4 p-1.5 ${
              step >= 3 ? "text-ink" : "text-gray-800"
            } text-xs cursor-pointer`}
            onClick={() => {
              if (step >= 3) setStep(3);
            }}
          >
            Map
          </div>
          {step === 3 && (
            <div className="h-1.5 w-1.5 mr-1">
              <div className="h-1.5 w-1.5 rounded-full bg-green-500"></div>
            </div>
          )}
        </>
      </div>

      <form
        className="pt-[156px] px-5 pb-12"
        onSubmit={(e) => {
          e.preventDefault();
          console.log("FormValues in onSubmit====> validateForm", formValues);
          if (validateFileSteps()) {
            handleSubmit();
          }
        }}
      >
        {renderStepContent()}
      </form>
      <AuthModal
        authWindowId={authWindowId}
        modalIsOpen={showAuthModal}
        closeModal={() => {
          setShowAuthModal(false);
        }}
        googleAuthUrl={authUrl}
      />
    </>
  );
};

export default MultiStepForm;
