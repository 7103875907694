import React, { useEffect, useState } from "react";
import AutomationInput from "./AutomationInput";
import AutomationTextArea from "./AutomationTextArea";
import { ReactComponent as AiSparkle } from "assets/images/ai-sparkle.svg";
import { ReactComponent as AiSparkleDisabled } from "assets/images/ai-sparkle-disabled.svg";
import AutomationInputCheckbox from "./AutomationInputCheckbox";
import AutomationInputRadio from "components/AutomationInputRadio";
import AskSession, { AskSessionProps } from "components/AskSession";

export interface EventTypeMap {
  click: "CLICK";
  scrollend: "SCROLL";
  clickHold: "HOLD_CLICK";
  dblclick: "DOUBLE_CLICK";
  keyPress: "KEY_PRESS";
  typing: "TYPE";
  submit: "SUBMIT";
  resize: "RESIZE";
  textSelection: "TEXT_SELECT";
  contextmenu: "CLICK";
  keydown: "KEY_DOWN";
  tabChange: "TAB_CHANGE";
  input: "INPUT";
}

type InputField = {
  label: string;
  step_id: string;
  tag_name: string;
  event_type: EventTypeMap[keyof EventTypeMap];
  input_type: string | null;
};

interface InputValue {
  value_type: string;
  value: any;
}

type Props = {
  askSessionProps?: AskSessionProps;
};

const AskSessionStandalone = ({ askSessionProps }: Props) => {
  return (
    <>
      <div className="my-1 relative">
        <AskSession {...askSessionProps} />
      </div>
    </>
  );
};

export default AskSessionStandalone;
