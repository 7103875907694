import React from "react";
import { ReactComponent as CheckGreen } from "assets/images/check-green.svg";
import { ReactComponent as Error } from "assets/images/error.svg";
import Loader from "assets/lotties/loader-light-animation.json";
import { Player } from "@lottiefiles/react-lottie-player";

export enum WorkflowStatusEnum {
  NOT_RUNNING = "NOT_RUNNING",
  PREPARING = "PREPARING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

type Props = {
  status: WorkflowStatusEnum;
};

const ExecutionStateStatus = ({ status }: Props) => {
  return (
    <>
      {status === WorkflowStatusEnum.SUCCESS ? (
        <div className="flex flex-row items-center gap-1">
          <div className="w-[18px] h-[18px]">
            <CheckGreen />
          </div>
          <div className="text-green-700 font-semibold text-sm">Success</div>
        </div>
      ) : null}

      {status === WorkflowStatusEnum.FAILED ? (
        <div className="flex flex-row items-center gap-1">
          <div className="w-[18px] h-[18px]">
            <Error />
          </div>
          <div className="text-red-700 font-semibold text-sm">Failed</div>
        </div>
      ) : null}

      {status === WorkflowStatusEnum.PREPARING ||
      status === WorkflowStatusEnum.RUNNING ? (
        <div className="flex flex-row items-center gap-1">
          {/* <img src={CheckGreen} alt="Cancelled" /> */}
          <Player
            autoplay
            src={Loader}
            loop={true}
            style={{ height: "20px", width: "20px" }}
          ></Player>
          <div className="text-gray-700 font-semibold text-sm">In-Progress</div>
        </div>
      ) : null}
    </>
  );
};

export default ExecutionStateStatus;
